import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import * as S from './TableWithFixedSidebar.styles';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import TagMenu from '@components/modules/common/TagMenu/TagMenu';
import TagListModal from '@components/modules/common/TagListModal/TagListModal';
import { generateMonthlyDateArray } from '@lib/pc/stock_status/functions';
import { isSameIndex, addComma } from '@lib/pc/production_schedule/functions';
import { tagSearch } from '@lib/common/functions';
import { CircularProgress } from '@material-ui/core';
import {
  Todo,
  Tag,
  PRODUCT_KIND,
  productStockAllocation,
  stockAllocationsAll,
  productStockAllocationDetail,
} from '@lib/common/type';

type Props = {
  selectedDate: moment.Moment;
  data: stockAllocationsAll[] | null;
  selected: boolean;
  selectIndex: number;
  setSelectIndex: (index: number) => void;
  handleSelectedProduct: (
    d: {
      id: number;
      name: string;
      type: '完' | '半';
      date: Date;
      data: productStockAllocationDetail[];
      productStockAllocations: productStockAllocation[];
      labelOfAmount: string;
      storeroomsIds: number[];
    },
    selectedCell: string
  ) => void;
  setTagListModalActive?: () => void;
  rightSlideSidebarActive: boolean;
  selectedCell: string;
  handleOpenProductDetail: (item: Todo) => void;
};

const TableWithFixedSidebar: FC<Props> = ({
  selectedDate,
  data,
  selected,
  handleSelectedProduct,
  rightSlideSidebarActive,
  selectedCell,
  selectIndex,
  setSelectIndex,
  handleOpenProductDetail,
}: Props) => {
  const [searchWord, setSearchWord] = useState('');
  const [tagMenuActive, setTagMenuActive] = useState(false);
  const [tagListModalActive, setTagListModalActive] = useState(false);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const selectedTagIds = selectedTags.map((item: Tag) => item['id']);

  const ProductNameArr = data
    ? data.map((p: stockAllocationsAll) => ({
        id: p.id,
        name: p.name,
        nameAbbreviation: p.nameAbbreviation,
        nameKana: p.nameKana,
        productCode: p.productCode,
        productKind: p.productKind === 1 ? '完' : '半',
        tagIds: p.tagIds,
        storeroomsIds: p.storeroomsIds,
        allocations: p.productStockAllocations,
        numberOfDaysParamsMonth: p.numberOfDaysParamsMonth,
      }))
    : [];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(e.target.value);
  };

  useEffect(() => {
    setTagMenuActive(false);
  }, [selectedTagIds.length === 0]);

  return (
    <>
      <S.ListWrapper>
        <S.ListSidebar>
          <S.ListLeftHeadCell
            invalid={rightSlideSidebarActive}
            onClick={() => setTagMenuActive(!tagMenuActive)}
            isBlue={searchWord !== '' || selectedTagIds.length > 0}
          >
            <span className="filter-product">
              {searchWord !== '' || selectedTagIds.length > 0 ? (
                <>
                  <FilteredIcon />
                  <div className="filter_text">商品の絞込み中</div>
                </>
              ) : (
                <>
                  <Filter
                    isBlue={searchWord !== '' || selectedTagIds.length > 0}
                  />
                  <div className="filter_text">商品の絞込</div>
                </>
              )}
            </span>
          </S.ListLeftHeadCell>

          {ProductNameArr.filter(
            (p: Todo) =>
              p.name.includes(searchWord) ||
              p.nameAbbreviation?.includes(searchWord) ||
              p.nameKana?.includes(searchWord) ||
              p.productCode?.toString().includes(searchWord)
          )
            .filter((p: Todo) => tagSearch(p, selectedTagIds))
            .map(
              (
                s: {
                  id: number;
                  name: string;
                  nameAbbreviation: string;
                  nameKana: string;
                  productCode: string | number;
                  productKind: string;
                  numberOfDaysParamsMonth: number;
                },
                i: number
              ) => {
                return (
                  <S.ListLeftHeadCell
                    key={i}
                    onClick={() => handleOpenProductDetail(s)}
                  >
                    <S.ListLeftHeadNameWrapper>
                      <S.ListLeftHeadName>
                        {s.nameAbbreviation ? s.nameAbbreviation : s.name}
                      </S.ListLeftHeadName>
                      <S.ProductKindDiv productKind={s.productKind}>
                        {s.productKind}
                      </S.ProductKindDiv>
                    </S.ListLeftHeadNameWrapper>
                    <S.ListLeftHeadMeta>
                      <span>{s.productCode}</span>
                    </S.ListLeftHeadMeta>
                  </S.ListLeftHeadCell>
                );
              }
            )}
        </S.ListSidebar>
        <S.ListBody className={selected ? 'move-to-left' : ''}>
          {data && data[0]?.numberOfDaysParamsMonth && (
            <S.ListDateRow>
              {generateMonthlyDateArray(
                data[0].numberOfDaysParamsMonth,
                selectedDate,
                selectIndex,
                2
              ).map((p, i) => {
                const isToday = isSameIndex(
                  i,
                  selectIndex,
                  data[0].numberOfDaysParamsMonth
                );
                return (
                  <S.ListHeadCell
                    key={i}
                    index={i}
                    numOfIndex={data[0].numberOfDaysParamsMonth}
                    highlighten={isToday}
                    dayOfWeek={moment(p.productionDate).format('ddd')}
                    onClick={() => setSelectIndex(i)}
                  >
                    {i === 0
                      ? moment(p.productionDate).format('M/D(ddd)')
                      : i === data[0].numberOfDaysParamsMonth + 1
                      ? '合計'
                      : moment(p.productionDate).format('DD(ddd)')}
                  </S.ListHeadCell>
                );
              })}
            </S.ListDateRow>
          )}
          {data ? (
            data
              .filter(
                (p: Todo) =>
                  p.name.includes(searchWord) ||
                  p.nameAbbreviation?.includes(searchWord) ||
                  p.nameKana?.includes(searchWord) ||
                  p.productCode?.toString().includes(searchWord)
              )
              .filter((p: Todo) => tagSearch(p, selectedTagIds))
              .map((p: Todo, i: number) => {
                return (
                  <S.ListRow key={i}>
                    {generateMonthlyDateArray(
                      data[0]?.numberOfDaysParamsMonth,
                      selectedDate,
                      selectIndex,
                      2
                    ).map((d: Todo, idx: number) => {
                      const isToday = isSameIndex(
                        idx,
                        selectIndex,
                        data[0]?.numberOfDaysParamsMonth
                      );
                      return (
                        <S.ListCellContainer
                          invalid={rightSlideSidebarActive}
                          key={idx}
                          index={i}
                          numOfIndex={data[0]?.numberOfDaysParamsMonth}
                          highlighten={isToday}
                          noData={
                            p.productStockAllocations[idx]
                              .productStockAllocationDetails.length === 0
                          }
                          withComment={
                            p.productStockAllocations[idx].withComment
                          }
                          onClick={() => {
                            handleSelectedProduct(
                              {
                                id: p.id,
                                name: p.name,
                                type: p.productKind === 1 ? '完' : '半',
                                date: p.productStockAllocations[idx].date,
                                productStockAllocations:
                                  p.productStockAllocations[idx],
                                data: p.productStockAllocations[idx]
                                  .productStockAllocationDetails,
                                labelOfAmount: p.labelOfAmount,
                                storeroomsIds: p.storeroomIds,
                              },
                              `${p.id}-${idx}-${p.productKind}`
                            );
                          }}
                          className={
                            `${p.id}-${idx}-${p.productKind}` === selectedCell
                              ? 'select-cell'
                              : ''
                          }
                        >
                          {addComma(p.productStockAllocations[idx].totalAmount)}
                        </S.ListCellContainer>
                      );
                    })}
                  </S.ListRow>
                );
              })
          ) : (
            <S.CircularIconWrapperOuter>
              <S.CircularIconWrapper>
                <CircularProgress style={{ color: '#64b2f9' }} />
              </S.CircularIconWrapper>
            </S.CircularIconWrapperOuter>
          )}
        </S.ListBody>
      </S.ListWrapper>
      {tagMenuActive && (
        <TagMenu
          searchParam={searchWord}
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          setOpenTagModal={() => setTagListModalActive(true)}
          handleChange={handleSearch}
          deleteSearchParam={() => setSearchWord('')}
        />
      )}
      {tagListModalActive && (
        <TagListModal
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          tagListModalActive={tagListModalActive}
          handleClose={() => {
            setTagListModalActive(false);
            setTagMenuActive(false);
          }}
          productKind={PRODUCT_KIND.PRODUCT_AND_SEMI_PRODUCT}
        />
      )}
      {tagMenuActive && (
        <Overlay
          zIndex={9997}
          handleClick={() => {
            setTagMenuActive(false);
          }}
        />
      )}
    </>
  );
};

export default TableWithFixedSidebar;
