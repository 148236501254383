import styled from 'styled-components';

export const Wrapper = styled.div`
  height: calc(100vh - 164px);
  overflow: auto;
  background-color: ${(props) => props.theme.palette.beige};
`;

export const HeadContainer = styled.div`
  padding: 1.3rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.palette.white};
`;

export const FlexBaseline = styled.div`
  display: flex;
  align-items: baseline;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #f3ede6;
  width: 100%;
  height: 83px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & > button {
    width: 136px;
    height: 51px;
  }
  & > button:first-child {
    margin-right: 1rem;
  }
`;

export const InputDate = styled.input<{
  error?: boolean;
  modifiable?: boolean;
}>`
  width: 10.5rem;
  height: 40px;
  padding: 0 10px;
  border-radius: 0.5rem;
  outline: solid 1px rgba(0, 0, 0, 0.08);
  background-color: ${(props) => props.theme.palette.white};
  border: ${(props) =>
    props.error ? `1px solid ${props.theme.palette.error}` : null};
  &.entry-date {
    background-color: ${(props) =>
      props.modifiable ? '' : props.theme.palette.beige};
  }
`;

export const InputTitle = styled.input<{
  error?: boolean;
}>`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  outline: solid 1px rgba(0, 0, 0, 0.08);
  padding: 0.5rem 0 0.5rem 1rem;
  border: ${(props) =>
    props.error ? `1px solid ${props.theme.palette.error}` : null};
`;

export const MainContainer = styled.div`
  background-color: ${(props) => props.theme.palette.beige};
`;

export const MandatoryItems = styled.div`
  padding: 40px 72px 32px 72px;
`;

export const InformationInput = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Label = styled.div``;

export const LabelText = styled.div`
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
`;

export const Contents = styled.div`
  padding-top: 0.5rem;
`;

export const InputContainer = styled.div<{
  width?: string;
}>`
  & > div,
  input {
    width: ${(props) => props.width};
    height: 40px;
  }
`;

export const SelectIndicator = styled.div`
  position: relative;

  &::after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${(props) => props.theme.palette.blue};
    content: '';
    position: absolute;
    right: 12px;
    top: 0px;
    width: 0;
  }
`;

export const InputWithUnitContainer = styled(InputContainer)`
  position: relative;
`;

export const Unit = styled.span`
  position: absolute;
  bottom: 8px;
  right: 4px;
  font-size: 14px;
`;

export const Remarks = styled.textarea`
  width: 100%;
  height: 80px;
  padding: 10px;
  border-radius: 0.5rem;
  outline: solid 1px rgba(0, 0, 0, 0.08);
`;

export const RemarksWrapper = styled.div`
  padding: 0 72px 24px 72px;
  background-color: ${(props) => props.theme.palette.beige};
`;

export const ProductAddition = styled.div`
  :last-child {
    border-bottom: 1px solid ${(props) => props.theme.palette.blue};
  }
`;
export const AdditionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 0 72px 0 77px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 0.875rem;
  border-top: solid 1px #cdcdcd;
  border-bottom: solid 1px ${(props) => props.theme.palette.sand};
`;

export const AdditionRowWrapper = styled.div<{
  deleted: boolean;
}>`
  display: ${(props) => (props.deleted ? 'none' : 'flex')};
  align-items: center;
  height: 48px;
  padding: 0 72px 0 0;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 0.875rem;
  border-bottom: solid 1px ${(props) => props.theme.palette.sand};
`;
export const AdditionRowFormWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CloseButtonWrapper = styled.div`
  flex-basis: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CloseButton = styled.div`
  cursor: pointer;
`;

export const ProductItems = styled.div<{
  width?: string;
}>`
  width: ${(props) => props.width};
`;

export const ProductAdditionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > button {
    border: 0;
    background-color: ${(props) => props.theme.palette.beige};
    color: ${(props) => props.theme.palette.primary.main};
    gap: 0.5625rem;
    font-size: 0.875rem;
    min-width: 7.5rem;
  }
  & path {
    fill: ${(props) => props.theme.palette.primary.main};
  }
`;

export const RequiredError = styled.div`
  height: 56px;
  background-color: #fff7f7;
  border: 1px solid #ffa4a4;
  margin-bottom: 1.25rem;
  display: flex;
  align-items: center;
`;

export const ErrorStatement = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: ${(props) => props.theme.palette.error};
  display: flex;
  align-items: center;
  gap: 0.625rem;
  margin-left: 1.625rem;
`;
