import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { LoginUserInfo } from './type';

const useLoginUsers = () => {
  const { data, refetch } = useQuery('login_users_index', async () => {
    const data = await axiosInstance().get(`/api/v1/settings/users`);
    return data;
  });

  const usersInfo = humps.camelizeKeys(
    data?.data.users
  ) as unknown as LoginUserInfo;
  return { usersInfo, refetch };
};

export default useLoginUsers;
