import { useState } from 'react';
import * as S from './LoginUserCreateModal.styles';
import { IconButton } from '@material-ui/core';
import { ReactComponent as VisibilityIcon } from '@assets/icons/visibility.svg';
import { ReactComponent as VisibilityOffIcon } from '@assets/icons/visibility-off.svg';

import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { Heading } from '@components/elements/typographies/Heading/Heading';
import { Button } from '@components/elements/buttons/Button';
import useCreateUser from '@lib/pc/settings/manufacture_setting/useCreateUser';

type Props = {
  setLoginUserCreateModalActive: (loginUserCreateModalActive: boolean) => void;
  setUpdatePopUp: (updatePopUp: boolean) => void;
  setMsgKind: (msgKind: string) => void;
  refetch: () => void;
};

const LoginUserEditModal = ({
  setLoginUserCreateModalActive,
  setUpdatePopUp,
  setMsgKind,
  refetch,
}: Props) => {
  const [enableToSave, setEnableToSave] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordSecond, setShowPasswordSecond] = useState(false);

  const handleCreateSuccess = () => {
    refetch();
    setMsgKind('create');
    setUpdatePopUp(true);
    setLoginUserCreateModalActive(false);
  };

  const createUser = useCreateUser(handleCreateSuccess, setErrMsg);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleTogglePasswordSecondVisibility = () => {
    setShowPasswordSecond(!showPasswordSecond);
  };

  const handleClickCancel = () => {
    setLoginUserCreateModalActive(false);
  };

  const handleSubmitCreate = () => {
    setUpdating(true);
    createUser.mutate({
      email: email,
      password: password,
      passwordConfirmation: passwordConfirmation,
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e) {
      setEmail(e.target.value);
    }
  };
  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e) {
      setPassword(e.target.value);
    }
  };

  return (
    <>
      <S.Wrapper>
        <S.HeadContainer>
          <Heading tag="span" text="ログインユーザの新規登録" fs="32" fw="lg" />
        </S.HeadContainer>
        <S.Container>
          <S.InputContainer method="post" action="/form" autoComplete="off">
            <S.InputArea>
              <dt>メールアドレス</dt>
              <dd>
                <S.Input
                  type="text"
                  name={`email-${Math.random()}`}
                  id="nameAbbreviation"
                  value={email}
                  onChange={handleChange}
                  autoComplete="new-password"
                />
              </dd>
            </S.InputArea>

            <S.PasswordInputArea>
              <dt>パスワード</dt>
              <dd>
                <S.Input
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={handleChangePassword}
                />
                <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </dd>
            </S.PasswordInputArea>

            <S.PasswordInputArea>
              <dt>パスワードの再入力</dt>
              <dd>
                <S.Input
                  type={showPasswordSecond ? 'text' : 'password'}
                  pattern="^[a-zA-Z0-9]+$"
                  name="productCode"
                  id="productCode"
                  autoComplete="new-password"
                  value={passwordConfirmation}
                  onChange={(event) => {
                    setEnableToSave(true);
                    setPasswordConfirmation(event.target.value);
                  }}
                />
                <IconButton
                  onClick={handleTogglePasswordSecondVisibility}
                  edge="end"
                >
                  {showPasswordSecond ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </dd>
            </S.PasswordInputArea>
          </S.InputContainer>
        </S.Container>
        <S.ButtonWrapper>
          <S.ButtonContainer>
            <Button
              children={'キャンセル'}
              borderWidth={1}
              outlined
              onClick={() => {
                handleClickCancel();
              }}
              disabled={updating}
            />
            <Button
              children={updating ? '登録中...' : '登録'}
              disabled={updating || !enableToSave}
              onClick={handleSubmitCreate}
            />
          </S.ButtonContainer>
        </S.ButtonWrapper>
      </S.Wrapper>
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
    </>
  );
};

export default LoginUserEditModal;
