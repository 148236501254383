import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import * as S from './RightSlideSidebar.styles';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import CancelButton from '@components/modules/pc/stock_status/CancelButton/CancelButton';
import DuplicateStockButton from '@components/modules/pc/stock_status/DuplicateStockButton/DuplicateStockButton';
import SubmitButton from '@components/modules/pc/stock_status/SubmitButton/SubmitButton';
import ErrorMsgPopUpForStock from '@components/modules/pc/stock_status/ErrorMsgPopUpForStock/ErrorMsgPopUpForStock';
import FloatingAddButton from '@components/modules/common/mobile/FloatingAddButton/FloatingAddButton';
import ScrollToTop from '@components/modules/common/ScrollToTop/ScrollToTop';
import StockForm from '@components/modules/pc/stock_status/StockForm/StockForm';
import UpdatePopUp from '@components/modules/common/UpdatePopUp/UpdatePopUp';
import {
  useDuplicateEstimatedStock,
  useUpdateStockData,
} from '@lib/pc/stock_status/hooks';
import { useDeleteStockMutation } from '@lib/pc/stock_status/hooks';
import { useDuplicateLatestStock } from '@lib/pc/stock_status/hooks';
import { selectedItem } from '@lib/pc/stock_status/type';
import type { Case, Todo } from '@lib/common/type';
import type { DetailItem, InputParams } from '@lib/stock_status/type';
import { calcTotalFunc } from '@lib/stock_status/functions';
import useSWR, { useSWRConfig } from 'swr';

type Props = {
  rightSlideSidebarActive: boolean;
  setRightSlideSidebarActive: (rightSlideSidebarActive: boolean) => void;
  selectedProduct: selectedItem;
  selectedStoreroomId: string;
  initializeAmount: DetailItem;
  filteredProductCases: Case[];
  popUp: boolean;
  setPopUp: (popUp: boolean) => void;
  setIsDelete: (isDelete: boolean) => void;
  setMessageKind: (messageKind: string) => void;
  needToSetInitCase: boolean;
  setNeedToSetInitCase: (needToSetInitCase: false) => void;
  inputAmountParams: Todo;
  setInputAmountParams: (inputAmountParams: Todo) => void;
  comment: string;
  setComment: (comment: string) => void;
  isChanged: boolean;
  setIsChanged: (isChanged: boolean) => void;
  confirmMsg: string;
  setConfirmMsg: (confirmMsg: string) => void;
  selectedCasePiecesPerCase: number;
  setSelectedCasePiecesPerCase: (selectedCasePiecesPerCase: number) => void;
  onClickCancel: () => void;
  selectedDateString: string;
};

const RightSlideSidebar: FC<Props> = ({
  rightSlideSidebarActive,
  setRightSlideSidebarActive,
  selectedProduct,
  selectedStoreroomId,
  initializeAmount,
  filteredProductCases,
  popUp,
  setPopUp,
  setIsDelete,
  setMessageKind,
  needToSetInitCase,
  setNeedToSetInitCase,
  inputAmountParams,
  setInputAmountParams,
  comment,
  setComment,
  isChanged,
  setIsChanged,
  confirmMsg,
  setConfirmMsg,
  selectedCasePiecesPerCase,
  setSelectedCasePiecesPerCase,
  onClickCancel,
  selectedDateString,
}: Props) => {
  const day =
    selectedProduct && selectedProduct.date !== ''
      ? moment(selectedProduct.date)
      : moment();

  const formattedDate = moment(day).format('YYYY/MM/DD (ddd)');

  const selectedProductKind = selectedProduct?.type === '完' ? 1 : 2;
  const { data: stocksInProducts } = useSWR('filteredProducts');
  const product = stocksInProducts?.find(
    (product) =>
      product?.id === Number(selectedProduct?.productId) &&
      product?.productKind === selectedProductKind
  );
  const stocks = product?.stocks;
  const stock = stocks?.find((stock) => moment(day).isSame(stock.date, 'day'));
  const [initializeAmountDetail, setInitializeAmountDetail] = useState<Todo>(
    []
  );

  const [inputParams, setInputParams] = useState<InputParams>({
    amountDetail: stock?.amountDetail || initializeAmountDetail,
    comment: stock?.comment || '',
  });
  const [errMsg, setErrMsg] = useState('');
  // 削除ボタン押したidをセット
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [updating, setUpdating] = useState(false);
  // 前回の在庫情報ボタン押下
  const [clickedDuplicateButton, setClickedDuplicateButton] = useState(false);

  // 下位のコンポーネントに渡しているのがネストの深いオブジェクトなので、強制再レンダリングする必要がある
  const [, setToggleValue] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const reRender = () => setToggleValue((prev) => !prev);

  const { mutate } = useSWRConfig();

  const handleClickFloatingAddButton = () => {
    // iPadでコメント入力後、＋ボタン押下でコメントが消えてしまう対策
    if (comment) {
      setComment(comment);
    }
    const infoWithCase = {
      ...initializeAmount,
      caseId:
        filteredProductCases && filteredProductCases[0]?.caseId
          ? filteredProductCases[0]?.caseId
          : '',
      piecesPerBox:
        filteredProductCases && filteredProductCases[0]?.piecesPerBox
          ? filteredProductCases[0]?.piecesPerBox
          : 1,
    };
    setInputAmountParams([...inputAmountParams, infoWithCase]);
    setPopUp(false);
  };

  // inputコンポーネントの台車とケースとバラの計算
  const newCalcTotal = (
    index: number,
    value: string,
    valueKind: 'bara' | 'case'
  ) => {
    setInputAmountParams((newParams: Todo) => {
      const oldDetail = newParams[index];
      const newDetail = { ...oldDetail, [valueKind]: value };
      calcTotalFunc(product, newDetail, newDetail.piecesPerBox);
      newParams[index] = newDetail;
      return newParams;
    });
    reRender();
  };

  // inputコンポーネントの日付の選択と更新
  const changeNewDate = (index: number, value: Todo) => {
    setInputAmountParams((newParams: Todo) => {
      const oldDate = newParams[index];
      const newDate = { ...oldDate, productionOrShippingDate: value };
      newParams[index] = newDate;
      return newParams;
    });
    reRender();
  };

  // inputコンポーネントのケースの選択と更新
  const changeNewCase = (index: number, value: number) => {
    setInputAmountParams((newParams: Todo) => {
      const oldDate = newParams[index];
      const newDate = {
        ...oldDate,
        caseId: value,
        piecesPerBox: selectedCasePiecesPerCase,
      };
      newParams[index] = newDate;
      return newParams;
    });
    reRender();
  };

  // 予定在庫の取得;
  const { duplicateEstimatedStock: estimatedStock } =
    useDuplicateEstimatedStock(
      selectedProduct.productId,
      selectedStoreroomId,
      day.toDate(),
      selectedProduct?.type === '完' ? '1' : '2'
    );

  // 前回情報の取得;
  const { duplicateLatestStock: latestStock } = useDuplicateLatestStock(
    selectedProduct.productId,
    selectedStoreroomId,
    day.toDate(),
    selectedProduct?.type === '完' ? '1' : '2'
  );

  // 変更ボタン押下時処理--------
  const handleSuccess = () => {
    setIsDelete(false);
    setIsChanged(false);
    setMessageKind('update');
    setPopUp(!popUp);
    setInputAmountParams([]);
    setLatestData();
    setSelectedCasePiecesPerCase(1);
    setRightSlideSidebarActive(false);
    setNeedToSetInitCase(false);
    setComment('');
    setUpdating(false);
  };

  // 更新のmutation
  const updateStockMutation = useUpdateStockData(
    selectedProduct?.productId || 0,
    selectedProduct?.type === '完' ? '1' : '2',
    day.toDate(),
    selectedDateString,
    selectedStoreroomId,
    handleSuccess,
    setErrMsg,
    mutate,
    stocksInProducts
  );

  const onClickSubmit = () => {
    // ケースの種類が１種類の場合、日付のみ重複チェック
    if (inputAmountParams.some((ip: DetailItem) => ip.total === '')) {
      setErrMsg('在庫数は必ず入力してください。');
    } else if (!(inputAmountParams.length > 0 || isChanged)) {
      setErrMsg('変更するデータはありません。');
    } else {
      setUpdating(true);
      updateStockMutation.mutate({
        amountDetail: inputAmountParams || [],
        comment: comment ?? '',
      });
    }
  };

  const setLatestData = () => {
    setInputParams({
      amountDetail: stock?.amountDetail || initializeAmountDetail,
      comment: stock?.comment || '',
    });
    reRender();
  };

  const handleDeleteSuccess = () => {
    // Call other functions to update the UI state
    setLatestData();
    setDeleteId(null);
    setSelectedCasePiecesPerCase(1);
    setDeletePopUp(!deletePopUp);
    setNeedToSetInitCase(false);
    setIsChanged(false);
  };

  const deleteStockMutation = useDeleteStockMutation(
    stock?.actualProductStocksId || stock?.actualSemiProductStocksId,
    selectedProduct?.type === '完' ? '1' : '2',
    deleteId,
    selectedDateString,
    selectedStoreroomId,
    handleDeleteSuccess,
    mutate,
    stocksInProducts,
    setErrMsg
  );

  // 削除ボタンでレコードを削除する
  const handleDelete = () => {
    deleteStockMutation.mutate();
  };

  // 確認メッセージのOKボタン
  const handleOk = () => {
    setIsChanged(false);
    setConfirmMsg('');
    setInputAmountParams([]);
    setSelectedCasePiecesPerCase(1);
    setRightSlideSidebarActive(false);
    setComment(stock?.comment ? stock?.comment : '');
  };

  const handleCancel = () => {
    setConfirmMsg('');
  };

  // 予定の在庫情報を使用するボタン
  const handleClickEstimatedDuplicateButton = () => {
    setInputAmountParams(
      estimatedStock.map((item) => ({
        ...item,
        bara: item.bara === 0 ? '0' : item.bara,
        total: item.total === 0 ? '0' : item.total,
      }))
    );
    setClickedDuplicateButton(!clickedDuplicateButton);
    setIsChanged(true);
  };

  // 前回の在庫情報を使用するボタン
  const handleClickDuplicateButton = () => {
    setInputAmountParams(
      latestStock.map((item) => ({
        ...item,
        bara: item.bara === 0 ? '0' : item.bara,
        total: item.total === 0 ? '0' : item.total,
      }))
    );
    setClickedDuplicateButton(!clickedDuplicateButton);
    setIsChanged(true);
  };

  useEffect(() => {
    // 既存データの設定
    if (
      stock &&
      (stock?.amountDetail[0]?.total === 0 ||
        Number(stock?.amountDetail[0]?.total) > 0) &&
      rightSlideSidebarActive
    ) {
      setInputAmountParams(
        stock.amountDetail.map((item) => ({
          ...item,
          bara: item.bara === 0 ? '0' : item.bara,
          total: item.total === 0 ? '0' : item.total,
        }))
      );
    } else {
      setInitializeAmountDetail([]);
      // 初期値の場合はcaseとpiecesPerCaseの設定
      const init = {
        amountDetailId: '',
        bara: '',
        case: '',
        total: '',
        caseId:
          filteredProductCases && filteredProductCases[0]?.caseId
            ? filteredProductCases[0]?.caseId
            : '',
        piecesPerBox:
          filteredProductCases && filteredProductCases[0]?.piecesPerBox
            ? filteredProductCases[0]?.piecesPerBox
            : 1,
        productionOrShippingDate: '',
      };
      // initializeAmountDetail[0]へinitを設定
      initializeAmountDetail.push(init);
      setInputAmountParams(initializeAmountDetail);
    }
    // 備考の設定
    if (rightSlideSidebarActive) {
      stock && setComment(comment ? comment : stock?.comment);
    }
  }, [rightSlideSidebarActive]);

  if (!selectedProduct) return null;

  return (
    <>
      <S.Wrapper open={rightSlideSidebarActive}>
        <ScrollToTop />
        <S.ScrollableContainer>
          <S.HeadContainer>
            <S.HeadDate>{formattedDate}</S.HeadDate>
            <S.HeadProductName>
              <S.HeadProductType productKind={selectedProduct?.type}>
                {selectedProduct?.type}
              </S.HeadProductType>
              {selectedProduct?.productName}
            </S.HeadProductName>
          </S.HeadContainer>

          <StockForm
            filteredProductCases={filteredProductCases}
            product={selectedProduct}
            inputParams={inputParams}
            setInputParams={setInputParams}
            inputAmountParams={inputAmountParams}
            setInputAmountParams={setInputAmountParams}
            newCalcTotal={newCalcTotal}
            changeNewDate={changeNewDate}
            changeNewCase={changeNewCase}
            handleDelete={handleDelete}
            deleteId={deleteId}
            setDeleteId={setDeleteId}
            setIsChanged={setIsChanged}
            caseSelectWidth={'85%'}
            caseLabelWidth={'91px'}
            needToSetInitCase={needToSetInitCase}
            comment={comment}
            setComment={setComment}
          />
          {stock &&
          stock?.amountDetail[0].total === '' &&
          inputAmountParams.length === 1 &&
          inputAmountParams[0].total === '' &&
          !isChanged &&
          !clickedDuplicateButton ? (
            <S.DuplicateButtonContainer
              invalid={errMsg || confirmMsg ? true : false}
              className="duplicate-button"
            >
              <DuplicateStockButton
                estimated
                onClick={() => {
                  handleClickEstimatedDuplicateButton();
                }}
                disabled={updating || estimatedStock?.[0]?.total === ''}
              >
                予定在庫情報
              </DuplicateStockButton>
              <DuplicateStockButton
                onClick={() => {
                  handleClickDuplicateButton();
                }}
                disabled={updating}
              >
                前回在庫情報
              </DuplicateStockButton>
            </S.DuplicateButtonContainer>
          ) : (
            <S.ButtonContainer invalid={errMsg || confirmMsg ? true : false}>
              <CancelButton onClick={onClickCancel} disabled={updating}>
                キャンセル
              </CancelButton>
              <SubmitButton onClick={onClickSubmit} disabled={updating}>
                変更
              </SubmitButton>
            </S.ButtonContainer>
          )}
        </S.ScrollableContainer>
        <FloatingAddButton handleClick={handleClickFloatingAddButton} />
        <ErrorMsgPopUpForStock
          errMsg={errMsg}
          handleClose={() => setErrMsg('')}
          fromPc={true}
        />
        <ConfirmPopUp
          fromPc={true}
          confirmMsg={confirmMsg}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
        {deletePopUp && <Overlay handleClick={() => setDeletePopUp(false)} />}
        <UpdatePopUp
          popUp={deletePopUp}
          handleClose={() => setDeletePopUp(false)}
          fromPc={true}
          taskKind={'stock'}
          messageKind={'delete'}
          isDelete={true}
        />
      </S.Wrapper>
    </>
  );
};

export default RightSlideSidebar;
