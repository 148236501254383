import { useState } from 'react';
import * as S from './TagSettingsModal.styles';
import { WithHeader } from '@components/templates/WithHeader';
import { Heading } from '@components/elements/typographies/Heading';
import { Button } from '@components/elements/buttons/Button';
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { ReactComponent as SearchIcon } from '@assets/icons/search.svg';
import { Tag, Todo } from '@lib/common/type';
import UpdatePopUp from '@components/modules/pc/settings/common/UpdatePopUp/UpdatePopUp';
import TagDeleteConfirmModal from '@components/modules/pc/settings/common/TagDeleteConfirmModal/TagDeleteConfirmModal';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import TagEditMenuModal from '../TagEditMenuModal/TagEditMenuModal';
import useUpdateTagMutation from '@lib/pc/settings/product_master_edit/useUpdateTag';
import useCreateTagMutation from '@lib/pc/settings/product_master_edit/useCreateTag';
import useCheckAndDeleteTagMutation from '@lib/pc/settings/product_master_edit/useCheckAndDeleteTagMutation';
import type { TagNameInputParam } from '@lib/pc/settings/product_master/type';
import { tagDuplicateCheck } from '@lib/pc/settings/product_master/functions';
import { TagForSetting } from '@lib/pc/settings/product_master_setting/type';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';

type Props = {
  data: TagForSetting[];
  tagSettingsOpen: boolean;
  handleClose: () => void;
  handleSuccessTagSettings: () => void;
  tagPopUp: boolean;
  setTagPopUp: (popUp: boolean) => void;
  newTag: TagNameInputParam;
  setNewTag: (newTag: TagNameInputParam) => void;
  editTag: TagNameInputParam;
  setEditTag: (newTag: TagNameInputParam) => void;
  isTagChanged: boolean;
  setIsTagChanged: (isTagChanged: boolean) => void;
  editInputOpen: boolean;
  setEditInputOpen: (editInputOpen: boolean) => void;
  tagDelConfirmOpen: boolean;
  setTagDelConfirmOpen: (tagDelConfirmOpen: boolean) => void;
  deleteTag: Tag;
  setDeleteTag: (deleteTag: Tag) => void;
  tagDelConfirmAgainOpen: boolean;
  setTagDelConfirmAgainOpen: (tagDelConfirmAgainOpen: boolean) => void;
  msgKind: string;
  setMsgKind: (msgKind: string) => void;
};

const TagSettingsModal = ({
  data,
  tagSettingsOpen,
  handleClose,
  handleSuccessTagSettings,
  tagPopUp,
  setTagPopUp,
  newTag,
  setNewTag,
  editTag,
  setEditTag,
  isTagChanged,
  setIsTagChanged,
  editInputOpen,
  setEditInputOpen,
  tagDelConfirmOpen,
  setTagDelConfirmOpen,
  deleteTag,
  setDeleteTag,
  tagDelConfirmAgainOpen,
  setTagDelConfirmAgainOpen,
  msgKind,
  setMsgKind,
}: Props) => {
  // 編集、削除メニューの表示
  const [editMenuOpen, setEditMenuOpen] = useState(false);
  const [editData, setEditData] = useState<Todo>(null);
  const [searchWord, setSearchWord] = useState('');
  const [errMsg, setErrMsg] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(e.target.value);
  };

  const handleMasterEdit = (t: TagForSetting) => {
    setEditData(t);
    setEditInputOpen(!editInputOpen);
    setEditMenuOpen(!editMenuOpen);
  };

  // 新規タグ追加
  const handleChangeAddTag = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTag({ ...newTag, [e.target.name]: e.target.value });
  };

  const handleCheckAndSubmitNewTag = (
    newTag: TagNameInputParam,
    data: TagForSetting[]
  ) => {
    const result = tagDuplicateCheck(newTag, data);
    if (result) {
      handleSubmitNewTag(newTag);
      setMsgKind('create');
    } else {
      setErrMsg('同じタグ名が登録済みです。');
    }
  };

  const handleSubmitNewTag = (newTag: TagNameInputParam) => {
    createTagMutation.mutate(newTag);
  };

  const createTagMutation = useCreateTagMutation(
    handleSuccessTagSettings,
    setErrMsg
  );

  // 既存タグ編集
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsTagChanged(true);
    setEditTag({ ...editTag, [e.target.name]: e.target.value });
  };

  const handleCheckAndSubmitEditTag = (
    editTag: TagNameInputParam,
    data: Tag[],
    beforeEditTag: string
  ) => {
    const result = tagDuplicateCheck(editTag, data, beforeEditTag);
    if (result) {
      handleSubmit(editTag);
      setMsgKind('update');
    } else {
      setErrMsg('同じタグ名が登録済みです。');
    }
  };

  const handleSubmit = (editTag: TagNameInputParam) => {
    updateTagMutation.mutate(editTag);
  };

  const updateTagMutation = useUpdateTagMutation(
    editData?.id,
    handleSuccessTagSettings,
    setErrMsg
  );

  // タグの削除
  const handleDelete = (t: Tag) => {
    setDeleteTag(t);
    // 削除しますか？のmodal画面表示
    setTagDelConfirmOpen(!tagDelConfirmOpen);
    setEditMenuOpen(false);
  };

  const handleCheckAndDeleteTag = () => {
    setMsgKind('delete');
    checkAndDeleteTagMutation.mutate();
    setTagDelConfirmOpen(false);
  };

  const setTagDeleteConfirmAgain = () => {
    setTagDelConfirmOpen(false);
    setTagDelConfirmAgainOpen(!tagDelConfirmAgainOpen);
  };

  const checkAndDeleteTagMutation = useCheckAndDeleteTagMutation(
    deleteTag.id,
    handleSuccessTagSettings,
    setTagDeleteConfirmAgain,
    setErrMsg
  );

  return (
    <>
      <WithHeader>
        <S.Wrapper open={tagSettingsOpen}>
          <S.HeadContainer>
            <div>
              <Heading tag="span" text="タグの管理" fs="32" fw="lg" />
            </div>
            <S.ModalHeadCloseIcon>
              <CloseIcon
                onClick={() => {
                  handleClose();
                  setTagPopUp(false);
                }}
              />
            </S.ModalHeadCloseIcon>
          </S.HeadContainer>

          <S.TagAddAndSearchArea>
            <S.InputWrapper>
              <S.Icon>
                <SearchIcon />
              </S.Icon>
              <S.Input
                className="search-input"
                type="text"
                value={searchWord}
                onChange={handleSearch}
              />
            </S.InputWrapper>

            <S.TagAddWrapper>
              <div className="add-tag-name">
                <dt>追加するタグ名</dt>
                <dd>
                  <S.Input
                    type="text"
                    name="name"
                    id="name"
                    align="left"
                    padding="0 20px"
                    value={newTag?.name}
                    onChange={handleChangeAddTag}
                    autoComplete="new-password"
                  />
                  <S.ButtonContainer>
                    {newTag?.name !== '' ? (
                      <Button
                        children={'追加'}
                        onClick={() => {
                          handleCheckAndSubmitNewTag(newTag, data);
                        }}
                      />
                    ) : (
                      <Button disabled children={'追加'} />
                    )}
                  </S.ButtonContainer>
                </dd>
              </div>
            </S.TagAddWrapper>
          </S.TagAddAndSearchArea>
          {/* コンテンツ */}
          <S.TagSettingsContents>
            <S.TagContentsTitle>タグ名</S.TagContentsTitle>
            <S.TagContentsWrapper>
              {data &&
                data
                  .filter((d: TagForSetting) => d.name.includes(searchWord))
                  .map((t: TagForSetting) => (
                    <S.TagRow key={t.id}>
                      <div
                        className="edit"
                        style={{ userSelect: 'none' }}
                        onClick={() => {
                          setEditMenuOpen(!editMenuOpen);
                          setEditData(t);
                        }}
                      >
                        …
                      </div>
                      {editData?.id === t.id && editMenuOpen && (
                        <TagEditMenuModal
                          open={editMenuOpen}
                          handleMasterEdit={() => handleMasterEdit(t)}
                          handleDelete={() => handleDelete(t)}
                          relation={t.relation}
                        />
                      )}
                      {editMenuOpen && (
                        <Overlay handleClick={() => setEditMenuOpen(false)} />
                      )}

                      {editData?.id === t.id && editInputOpen ? (
                        <>
                          <S.Input
                            type="text"
                            name="name"
                            id="name"
                            autoComplete="off"
                            align="left"
                            padding="0 20px"
                            value={isTagChanged ? editTag.name : t?.name}
                            onChange={handleChange}
                          />
                          <S.ButtonContainer>
                            {isTagChanged && editTag.name ? (
                              <Button
                                // padding="sm"
                                children={'確定'}
                                onClick={() =>
                                  handleCheckAndSubmitEditTag(
                                    editTag,
                                    data,
                                    t.name
                                  )
                                }
                              />
                            ) : (
                              <S.DisableButton>確定</S.DisableButton>
                            )}
                          </S.ButtonContainer>
                        </>
                      ) : (
                        <div className="code">{t.name}</div>
                      )}
                    </S.TagRow>
                  ))}
            </S.TagContentsWrapper>
          </S.TagSettingsContents>
        </S.Wrapper>
      </WithHeader>
      <UpdatePopUp
        popUp={tagPopUp}
        taskKind={'messageOnly'}
        messageKind={msgKind}
        name={editData?.product?.name}
        handleClose={() => {
          setTagPopUp(false);
          setMsgKind('');
        }}
      />
      {tagDelConfirmOpen && (
        <TagDeleteConfirmModal
          tagName={deleteTag.name}
          onClickCancel={() => {
            setTagDelConfirmOpen(false);
            setDeleteTag({ id: 0, name: '' });
          }}
          onClickDelete={handleCheckAndDeleteTag}
        />
      )}
      <ErrorMsgPopUp
        errMsg={errMsg}
        fromPc={true}
        handleClose={() => setErrMsg('')}
      />
    </>
  );
};

export default TagSettingsModal;
