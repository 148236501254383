import { useMutation } from 'react-query';
import { axiosInstance } from '@lib/mobile/common/api/axiosConfig';
import { Todo } from '@lib/common/type';

const useDeleteProductsCaptureMutation = (
  productId: string | number,
  cameraId: string | number,
  handleSuccessDelete: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async () => {
      return await axiosInstance().delete(
        `/api/v1/cameras?product_id=${productId}&camera_id=${cameraId}`
      );
    },
    {
      onSuccess: () => {
        handleSuccessDelete();
      },
      onError: (error: Todo) => {
        setErrMsg(error.response.data.message || 'エラーが発生しました');
      },
    }
  );
};

export default useDeleteProductsCaptureMutation;
