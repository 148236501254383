import { useEffect, useState } from 'react';
import * as S from './LoginUserEditModal.styles';
import Checkbox from '@material-ui/core/Checkbox';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { cyan } from '@material-ui/core/colors';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { Heading } from '@components/elements/typographies/Heading/Heading';
import { Button } from '@components/elements/buttons/Button';
import { sidebarLinkData } from '@lib/pc/constants';
import useLoginUser from '@lib/pc/settings/manufacture_setting/useLoginUser';
import useUpdateUsersMenuMutation from '@lib/pc/settings/manufacture_setting/useUpdateUsersMenu';
import { Menu } from '@lib/pc/settings/manufacture_setting/type';
import { Todo } from '@lib/common/type';

type Props = {
  selectedUserId: number;
  selectedEmail: string;
  refetch: () => void;
  setLoginUserEditModalActive: (loginUserEditModalActive: boolean) => void;
  setSelectedUserId: (selectedUserId: number) => void;
  setUpdatePopUp: (updatePopUp: boolean) => void;
  setMsgKind: (msgKind: string) => void;
};

const LoginUserEditModal = ({
  selectedUserId,
  selectedEmail,
  setLoginUserEditModalActive,
  setUpdatePopUp,
  setMsgKind,
}: Props) => {
  const [enableToSave, setEnableToSave] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // 下位のコンポーネントに渡しているのがネストの深いオブジェクトなので、強制再レンダリングする必要がある
  const [, setToggleValue] = useState(false);
  const reRender = () => setToggleValue((prev) => !prev);

  const { user, refetch } = useLoginUser(selectedUserId);

  // ユーザーに紐づけるメニュー
  const [newData, setNewData] = useState(
    user?.allowedMenus ? user?.allowedMenus : []
  );

  const theme = createTheme({
    palette: {
      primary: cyan,
    },
  });

  const handleUpdateSuccess = () => {
    refetch();
    setMsgKind('update');
    setUpdatePopUp(true);
    setLoginUserEditModalActive(false);
  };

  const updateUsersMenuMutation = useUpdateUsersMenuMutation(
    selectedUserId,
    handleUpdateSuccess,
    setErrMsg
  );

  const handleClickCancel = () => {
    setLoginUserEditModalActive(false);
  };

  const handleSubmitUpdate = () => {
    setUpdating(true);
    const menuIds = newData.map((m: Menu) => m['id']);
    updateUsersMenuMutation.mutate({ menuIds: menuIds });
  };

  useEffect(() => {
    setNewData(user?.allowedMenus);
  }, [user?.allowedMenus?.length]);

  const handleCheckbox = (selected: boolean, dbMenuName: string) => {
    setEnableToSave(true);
    // dMenuNameはDBのMenuテーブルに設定されているname。
    if (selected) {
      // チェック済みメニューをはずす
      const filteredMenu = newData.filter(
        (item: Menu) => item.menu !== dbMenuName
      );
      setNewData(filteredMenu);
    } else {
      const addMenu = user?.allMenuList?.find(
        (item: Menu) => item?.menu === dbMenuName
      );
      addMenu && newData.push(addMenu);
    }
    reRender();
  };

  const firstColumn = [
    'productionSchedule',
    'productProductionResult',
    'stockStatus',
  ];

  const secondColumn = ['picking', 'materialInventory', 'salesInformation'];

  const thirdColumn = [
    'manufactureInformation',
    'settings',
    'materialSettings',
    'orderShipmentSettings',
  ];

  const SidebarList = ({ d }: Todo) => {
    return (
      <S.SidebarList key={d.title}>
        <S.SidebarListHeader>
          {d.icon ? <d.icon /> : ''}
          <S.SidebarListTitle>{d.title}</S.SidebarListTitle>
        </S.SidebarListHeader>
        {d.links.map((link: Todo, i: number) => {
          const menuList =
            newData && newData.length > 0
              ? newData.map((item: Menu) => item?.['menu'])
              : [];
          const selected = menuList?.includes(link.dbMenuName) ? true : false;
          return (
            user &&
            user?.allLinkFunctions?.includes(link.function) && (
              <S.SidebarListLink key={i + link.label}>
                <S.MenuTitle key={i + link.label}>{link.label}</S.MenuTitle>
                <S.RolesCheck key={i + link.function}>
                  <ThemeProvider theme={theme}>
                    <Checkbox
                      id="checkboxId"
                      color="primary"
                      checked={selected}
                      onChange={() => handleCheckbox(selected, link.dbMenuName)}
                    />
                  </ThemeProvider>
                </S.RolesCheck>
              </S.SidebarListLink>
            )
          );
        })}
      </S.SidebarList>
    );
  };

  return (
    <>
      <S.Wrapper>
        <S.HeadContainer>
          <Heading tag="span" text="ログインユーザの編集" fs="32" fw="lg" />
        </S.HeadContainer>
        <S.Container>
          <S.LoginUserInfo>
            <span>メールアドレス</span>
            {selectedEmail}
          </S.LoginUserInfo>

          <S.MenuTitleItem>メニュー表示項目</S.MenuTitleItem>
          <S.MenusArea>
            <S.MenusWrapper>
              <ul>
                {sidebarLinkData.map((d) => {
                  return (
                    user?.allAllowedRoles?.includes(d.function) &&
                    firstColumn.includes(d.function) && (
                      <SidebarList key={d.function} d={d} />
                    )
                  );
                })}
              </ul>
              <ul>
                {sidebarLinkData.map((d) => {
                  return (
                    user?.allAllowedRoles?.includes(d.function) &&
                    secondColumn.includes(d.function) && (
                      <SidebarList key={d.function} d={d} />
                    )
                  );
                })}
              </ul>
              <ul>
                {sidebarLinkData.map((d) => {
                  return (
                    user?.allAllowedRoles?.includes(d.function) &&
                    thirdColumn.includes(d.function) && (
                      <SidebarList key={d.function} d={d} />
                    )
                  );
                })}
              </ul>
            </S.MenusWrapper>
          </S.MenusArea>
        </S.Container>
        <S.ButtonWrapper>
          <S.ButtonContainer>
            <Button
              children={'キャンセル'}
              borderWidth={1}
              outlined
              onClick={() => {
                handleClickCancel();
              }}
              disabled={updating}
            />
            <Button
              children={updating ? '保存中...' : '保存'}
              disabled={updating || !enableToSave}
              onClick={handleSubmitUpdate}
            />
          </S.ButtonContainer>
        </S.ButtonWrapper>
      </S.Wrapper>
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
    </>
  );
};

export default LoginUserEditModal;
