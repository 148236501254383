import { useEffect, useState } from 'react';
import { WithHeader } from '@components/templates/WithHeader';
import UpdatePopUp from '@components/modules/common/UpdatePopUp/UpdatePopUp';
import StockAllocationHeaderControl from '@components/modules/pc/stock_allocation_status/StockAllocationHeaderControl/StockAllocationHeaderControl';
import StockAllocationContents from '@components/modules/pc/stock_allocation_status/StockAllocationContents/StockAllocationContents';
import useCases from '@lib/pc/common/hooks/useCases';
import useStockAllocations from '@lib/pc/stock_allocation/useStockAllocations';
import useStorerooms from '@lib/pc/common/hooks/useStorerooms';
import useProducts from '@lib/pc/wrapping/useProducts';
import { PcScrollStyle } from '@lib/styled-components/globalStyle';
import { getTodayForInput } from '@lib/common/functions';
import moment from 'moment';
import { PRODUCT_KIND, Product } from '@lib/common/type';
import { PCPath } from '@config/path';

export const StockAllocationStatusRoot = () => {
  const today = new Date();
  const [date, setDate] = useState(new Date());
  const [popUp, setPopUp] = useState(false);
  const [messageKind, setMessageKind] = useState('');
  const [editProductName, setEditProductName] = useState('');
  // 選択された払出元に存在する製品のみ
  const [selectedStoreroomsProducts, setSelectedStoreroomsProducts] = useState<
    Product[]
  >([]);

  const { data: storerooms } = useStorerooms(PRODUCT_KIND.PRODUCT);
  const { data: products } = useProducts();

  const { data: cases } = useCases();
  const [selectedStoreroomId, setSelectedStoreroomId] = useState(
    storerooms?.[0]?.id
  );
  const { stockAllocations: stockAllocations, refetch } = useStockAllocations(
    date,
    selectedStoreroomId ? selectedStoreroomId : storerooms?.[0]?.id
  );

  // 商品選択時の1台車あたりのケース数
  const [casesPerCart, setCasesPerCart] = useState('');

  // 1日戻る
  const getBeforeDate = (date: Date) => {
    setDate(moment(date).subtract(1, 'days').toDate());
    setPopUp(false);
  };

  // 1日進む
  const getNextDate = (date: Date) => {
    // 現在日付けが過去日のときのみ、日付を進められる。
    if (
      moment(date).format('YYYY/MM/DD') !== moment(today).format('YYYY/MM/DD')
    ) {
      setDate(moment(date).add(1, 'days').toDate());
      setPopUp(false);
    }
  };

  const handleClick = () => {
    getTodayForInput(date, today, setDate, refetch);
  };

  useEffect(() => {
    setSelectedStoreroomId(storerooms?.[0]?.id);
  }, [storerooms]);

  // 払出元に存在する製品だけを設定する
  useEffect(() => {
    refetch();
    const filteredProducts = products?.products?.filter(
      (p: Product) =>
        selectedStoreroomId && p.storeroomIds.includes(selectedStoreroomId)
    );
    filteredProducts && setSelectedStoreroomsProducts(filteredProducts);
  }, [selectedStoreroomId, products]);

  useEffect(() => {
    localStorage.setItem('last_view_page', `${PCPath.stockAllocationStatus}`);
  }, []);

  if (!cases || !storerooms) return null;

  return (
    <WithHeader>
      <PcScrollStyle />
      <StockAllocationHeaderControl
        storerooms={storerooms}
        selectedStoreroomId={selectedStoreroomId}
        setSelectedStoreroomId={setSelectedStoreroomId}
        selectedDate={date}
        getBeforeDate={getBeforeDate}
        getNextDate={getNextDate}
        setPopUp={setPopUp}
        handleClick={handleClick}
        setDate={setDate}
      />
      <StockAllocationContents
        stockAllocations={stockAllocations}
        selectedStoreroomId={selectedStoreroomId}
        date={date}
        selectedStoreroomsProducts={selectedStoreroomsProducts}
        storerooms={storerooms}
        cases={cases}
        setPopUp={setPopUp}
        setEditProductName={setEditProductName}
        refetch={refetch}
        setMessageKind={setMessageKind}
        casesPerCart={casesPerCart}
        setCasesPerCart={setCasesPerCart}
      />
      <UpdatePopUp
        popUp={popUp}
        handleClose={() => setPopUp(false)}
        fromPc={true}
        taskKind={'stockAllocation'}
        messageKind={messageKind}
        productName={editProductName}
      />
    </WithHeader>
  );
};
