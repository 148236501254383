import { useMutation } from 'react-query';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo } from '@lib/common/type';

const useCheckAndDeleteTagMutation = (
  id: number,
  handleDeleteSuccess: () => void,
  setTagDeleteConfirmAgain: (tagDelConfirmAgainOpen: boolean) => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async () => {
      return await axiosInstance().delete(
        `/api/v1/settings/tags/check_and_destroy/${id}`
      );
    },
    {
      onSuccess: () => {
        handleDeleteSuccess();
      },
      onError: (error: Todo) => {
        if (
          error.response.data.message ===
          'このタグは別の商品に紐付けられています。'
        ) {
          setTagDeleteConfirmAgain(true);
        } else {
          const message =
            error.response?.data.message || 'エラーが発生しました';
          setErrMsg(message);
        }
      },
    }
  );
};

export default useCheckAndDeleteTagMutation;
