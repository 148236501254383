import styled from 'styled-components';

export const Wrapper = styled.div<{
  bgColor: string;
}>`
  width: 100vw;
  /* height: calc(100vh - 221px); */
  //ライン別背景
  background-color: ${(props) => props.bgColor};
  min-height: calc(100vh - 203px);
`;

export const Header = styled.div<{
  bgColor: string;
}>`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #cdcdcd;
  border-bottom: 1px solid ${(props) => props.theme.palette.sandGray};
  background-color: ${(props) => props.bgColor};
`;

export const Content = styled.div`
  width: 100vw;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 2rem;
  line-height: 2rem;
  padding: 20px 40px;
`;

export const TabContentArea = styled.div`
  font-size: 30px;
  text-align: center;
`;

export const TabContent = styled.div<{
  open: boolean;
}>`
  color: black;
  display: ${(props) => (props.open ? 'block' : 'none')};
`;

export const Table = styled.table`
  width: 100vw;
  border-collapse: collapse;
  border-spacing: 0 1em;
  font-size: 0.875rem;
`;

export const TRow = styled.tr`
  /* border-bottom: 2px solid #cdcdcd; */
  min-height: 4.5rem;
`;

export const DraggableTBody = styled.tbody<{
  bgColor: string;
  isDragging: boolean;
}>`
  /* border-bottom: 1px solid #e6e1dd; */
  border-right: 0;
  border-left: 0;
  height: 46px;
  background-color: ${(props) => props.bgColor};
  opacity: ${(props) => (props.isDragging ? 0.5 : 1)};
  border-bottom: 2px solid #cdcdcd;

  > tr:first-child {
    border-bottom: 1px solid ${(props) => props.theme.palette.sandGray};
  }
`;

export const THead = styled.tr<{
  bgColor: string;
}>`
  background-color: ${(props) => props.bgColor};
  height: 2.6rem;
  position: sticky;
  top: 0;
  border-bottom: 2px solid #cdcdcd;
`;

export const TCellOrder = styled.td<{
  marginRight?: string;
}>`
  text-align: left;
  padding: 0 0 0 0.625rem;
  width: 3px;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '')};
  vertical-align: middle;
`;

export const THeadCell = styled.td<{
  align?: string;
  width?: string;
  wordBreak?: string;
  colSpan?: number;
}>`
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  vertical-align: middle;
  word-break: ${(props) => (props.wordBreak ? props.wordBreak : '')};
  padding: 1rem 0;
  /* width: ${(props) => (props.width ? props.width : '0rem')}; */
  min-width: ${(props) => (props.width ? props.width : '0rem')};
  max-width: ${(props) => (props.width ? props.width : '0rem')};
  padding: ${(props) =>
    props.align === 'right' ? '1rem 0.5rem 1rem 0' : '0 0 0 0.625rem'};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  word-spacing: 100vw;
  > svg {
    display: inline;
    width: 18px;
    height: 18px;
  }
  > span {
    font-size: 10px;
    margin-left: 4px;
  }
`;

export const TCell = styled.td<{
  align?: 'left' | 'right' | 'center';
  width?: string;
  marginRight?: string;
  background?: string;
  wordBreak?: string;
  fontSize?: string;
  colSpan?: number;
}>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  vertical-align: middle;
  word-break: ${(props) => (props.wordBreak ? props.wordBreak : '')};
  padding: 1rem 0;
  min-width: ${(props) => (props.width ? props.width : '1rem')};
  padding: ${(props) =>
    props.align === 'right'
      ? '1rem 0.5rem 1rem 0'
      : '0.4rem 0.5rem 0.4rem 0.5rem'};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '')};
  background: ${(props) => (props.background ? props.background : '')};
  > svg {
    display: inline;
    width: 18px;
    height: 18px;
  }
  > span {
    font-size: 10px;
    margin-left: 4px;
  }
  &.label {
    vertical-align: top;
    width: 1.625rem;
    /* width: 26px; */
    padding: 10px 0.25rem;
    border-right: solid 1px #e6e1dd;
    border-left: solid 1px #e6e1dd;
  }
  &.time-start {
    /* width: 6.75rem; */
    width: 84px;
  }
  &.time-end {
    vertical-align: baseline;
    .cell-content {
      padding-top: 0.6rem;
    }
  }
  &.display-quantity {
    padding: 0.4rem 0 0.25rem;
    vertical-align: top;
    &__count {
      /* 台車 */
      &--bogie {
        width: 7rem;
      }
      /* ケース */
      &--case {
        /* width: 10.5rem; */
        width: 6.5rem;
        /* min-width: 6.5rem; */
      }
      /* 個 */
      &--quantity {
        width: 6.5rem;
        /* min-width: 6.5rem; */
      }
      /* 合計 */
      &--total {
        width: 9rem;
        /* min-width: 9rem; */
        .with-label__inner-wrap {
          width: 100%;
        }
        .quantity {
          width: 100%;
          padding-right: 1rem;
          display: flex;
          justify-content: flex-end;
          .title {
            margin-right: auto;
            font-size: 14px;
          }
          .unit {
            font-size: 14px;
            margin-left: 2px;
          }
        }
      }
    }
    .display-quantity__inner-wrap {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .cell-content {
        justify-content: center;
        align-items: flex-end;
        .quantity {
          display: flex;
          align-items: flex-end;
          .num {
            font-size: 20px;
          }
          .unit {
            font-size: 14px;
          }
        }
        .explanation {
          margin-top: 4px;
          font-size: 12px;
          font-size: 0.75rem;
          color: #6b675c;
        }
      }
    }
    .symbol {
      padding: 0 8px;
    }
    .cell-content {
      display: flex;
      flex-direction: column;
    }
  }
  &.order {
    cursor: pointer;
  }
  &.person {
    padding: 0.2rem 0.5rem;
    border-left: solid 1px #e6e1dd;
    font-size: 15px;
  }
  /* 備考 */
  &.packaging-comment {
    .cell-content {
      white-space: pre-wrap;
      word-break: break-all;
    }
  }
`;

export const ArrowDropDown = styled.td`
  vertical-align: middle;
`;

export const PencilIcon = styled.td`
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  padding-left: 14px;
  background: white;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 24px;
  }
`;

export const FlexTableDivRow = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2ba9d1;
  font-size: 1rem;
  font-weight: bold;
  height: 4rem;
  border-bottom: 1px solid #cdcdcd;
  gap: 0.5rem;
  svg path {
    fill: #2ba9d1;
  }
`;

export const EndTimeTitleTCell = styled.div`
  color: black;
  width: 27%;
`;

export const EndTimeTCell = styled.div`
  color: black;
  width: 25%;
  margin-right: 1rem;

  > svg {
    display: inline;
    width: 18px;
    height: 18px;
  }
`;

export const EndTimePencilIcon = styled.div`
  width: 30%;
  text-align: right;
  > svg {
    display: inline;
  }
`;

export const CircularIconWrapperOuter = styled.div`
  width: 100vw;
  height: 50vh;
  position: relative;
`;

export const CircularIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40%;
  left: 48%;
`;
