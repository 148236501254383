import { FactoryLine, Todo } from '@lib/common/type';

export type Employee = {
  code: string;
  id: number;
  name: string;
  email: string;
  roleIds: number[];
  lineAssignments: LineAssignments[];
  factoryLines: FactoryLine[];
};

export type LineAssignments = {
  factoryName: string;
  lineName: string;
};

export type EmployeesAndRoles = {
  code: string;
  id: number;
  name: string;
  email: string;
  roleIds: number[];

  employee: Employee;
  lineAssignments: LineAssignments[];
  rolesInfo: Todo;
};

// 更新箇所のtype
export type EmployeeUpdateParams = {
  code: string;
  id: number;
  name: string;
  email: string;
  roleIds: number[];
  lineAssignments: LineAssignments[];
};

// バックエンドからmodal画面(show)で取得するデータ
export type NewEmployeesAndRoles = {
  employee: Employee;
  rolesInfo: Todo;
};

export type RolesInfo = {
  roleId: number;
  role: string;
  customSupport: boolean;
};

// 一覧画面で使用
export type EmployeesInfo = {
  employeesAndRoles: EmployeesAndRoles[];
  rolesInfo: RolesInfo[];
  manufactureName: string;
};

// codeがnullとstring
export const initEmployeeData = {
  id: 0,
  name: '',
  code: null,
  email: '',
  roleIds: [0],
};

// 新規作成箇所のtype
export type EmployeeInputParams = {
  name: string | null;
  code: string | null;
  email: string | null;
};

export type ManufactureInfoType = {
  id: number;
  manufactureAddress1: string | null;
  manufactureAddress2: string | null;
  manufactureSealImage: string | null;
  manufactureName: string;
  manufacturePhoneNumber: string | null;
  manufactureZipCode: string | null;
};

// 更新箇所のtype
export type StoreroomUpdateParams = {
  id: number | null;
  code: string;
  name: string;
  storeroomType: number;
};

export type Storeroom = {
  id: number;
  name: string;
  code: string | null;
  storeroomType: number;
};

export const initStoreroomData = {
  id: 0,
  name: '',
  code: null,
  storeroomType: 0,
};

export type Menu = {
  id: number;
  menu: string;
};

export type Link = {
  dbMenuName: string;
  function: string;
  label: string;
  url: string;
};

export type SidebarProps = {
  function: string;
  icon: string;
  links: Link[];
  title: string;
};

export type UsersInfo = {
  email: string;
  id: number;
};

export type LoginUserInfo = {
  users: UsersInfo[];
  isDeveloper: boolean;
};
