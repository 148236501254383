import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
  background-color: ${(props) => props.theme.palette.beige};
  overflow-x: hidden;
  z-index: 4;
`;

export const HeadContainer = styled.div`
  width: 100%;
  height: 88px;
  padding: 1.15rem 1.15rem 1.15rem 80px;
  background-color: ${(props) => props.theme.palette.white};
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  padding: 16px 80px;
  margin-bottom: 100px;
`;

export const LoginUserInfo = styled.div`
  display: flex;
  padding: 1rem 0;
  margin-bottom: 30px;
  > span {
    font-weight: 700;
    width: 150px;
  }
`;

export const MenuTitleItem = styled.div`
  padding: 1rem 0 2rem 0;
  font-weight: 700;
`;

export const MenusArea = styled.div``;

export const MenusWrapper = styled.div`
  display: flex;
  justify-content: center;
  > ul {
    margin-right: 60px;
  }
  > ul:last-child {
    margin-right: 0px;
  }
`;

export const SidebarList = styled.li`
  color: ${(props) => props.theme.palette.primary.main};
  width: 26%;
  /**
   * 一番上のSidebarListHeaderにはmarginをつけない
   */
  &:nth-child(1) {
    div:nth-child(1) {
      margin-top: 0;
    }
  }
`;

export const SidebarListLink = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.palette.baseBlack};
  display: flex;
  align-items: center;
  font-feature-settings: 'palt';
  width: 200px;
  height: 40px;
  .arrow-right {
    position: relative;
    top: 1px;
  }
`;

export const MenuTitle = styled.div`
  min-width: 200px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  background-color: ${(props) => props.theme.palette.white};
`;

export const SidebarListHeader = styled.div`
  color: ${(props) => props.theme.palette.baseBlack};
  padding: 0.5rem 0 0.5rem 0.8rem;
  font-weight: 700;
  font-size: 12px;
  background-color: #e9e9e9;
  display: flex;
  min-width: 280px;
`;

export const SidebarListTitle = styled.div`
  padding: 5px;
`;

export const RolesCheck = styled.div`
  display: flex;
  justify-content: center;
  min-width: 80px;
  height: 40px;
  background-color: ${(props) => props.theme.palette.white};
`;

export const ButtonWrapper = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.beige};
  width: 100vw;
  height: 83px;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  display: flex;
  bottom: 0;
  padding: 22px 0;
  align-items: center;
  justify-content: space-evenly;
  & > button {
    width: 136px;
  }
  & > button:first-child {
    margin-right: 1rem;
  }
`;
