import { useState, useMemo, useEffect } from 'react';
import * as S from './SemiProductProductionResultPc.styles';
import { Heading } from '@components/elements/typographies/Heading';
import { WithHeader } from '@components/templates/WithHeader';
import ArrowRight from '@assets/icons/arrow_right_24px';
import { ReactComponent as ArrowLeft } from '@assets/icons/arrow_left_24px.svg';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import RightSlideSidebar from '@components/modules/pc/semi_product_production_result/RightSlideSidebar/RightSlideSidebar';
import TableWithFixedSidebar from '@components/modules/pc/semi_product_production_result/TableWithFixedSidebar/TableWithFixedSidebar';
import useSemiProductProductionResultsForPc from '@lib/pc/semi_product_production_result/useSemiProductProductionResultsForPc';
import moment from 'moment';
import useFactoryLines from '@lib/pc/common/hooks/useFactoryLines';
import FactoryLineSelect from '@components/modules/pc/production_schedule/FactoryLineSelect/FactoryLineSelect';
import SearchMenu from '@components/modules/common/SearchMenu/SearchMenu';
import type { selectedItem } from '@lib/pc/stock_status/type';
import type { Todo, DateType } from '@lib/common/type';
import { PRODUCT_KIND } from '@lib/common/type';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { Button } from '@components/elements/buttons/Button';
import { getTodayForReference } from '@lib/common/functions';
import UpdatePopUp from '@components/modules/common/UpdatePopUp/UpdatePopUp';
import { PCPath } from '@config/path';

export const SemiProductProductionResultPcRoot = () => {
  const initializeSelectedItem = {
    data: [],
    date: '',
    productId: '',
    productName: '',
    productProductionResults: [],
    type: '',
    stockDateLabel: '',
  };

  const dateTypes: DateType[] = [
    {
      id: 0,
      name: '絞込み条件の選択',
    },
    {
      id: 1,
      name: '保管期限日',
    },
  ];

  const today = moment();
  const [date, setDate] = useState(moment());
  const [initialize] = useState<Todo>(initializeSelectedItem);
  const [selectedItem, setSelectedItem] = useState<selectedItem>(initialize);
  useState<selectedItem>(initialize);
  const [rightSlideSidebarActive, setRightSlideSidebarActive] = useState(false);
  const [searchMenuActive, setSearchMenuActive] = useState(false);
  const [selectedFactoryLineId, setSelectedFactoryLineId] = useState<
    string | number
  >(0);
  const [selectedFactoryLineName, setSelectedFactoryLineName] =
    useState('全てのライン（総数）');
  const [selectedDateTypeId, setSelectedDateTypeId] = useState('1');
  const [selectedCell, setSelectedCell] = useState('');
  const [dateRange, setDateRange] = useState<{
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }>({
    startDate: null,
    endDate: null,
  });
  const [selectIndex, setSelectIndex] = useState<number>(
    new Date().getDate() - 1
  );
  const [confirmMsg, setConfirmMsg] = useState('');
  const [edited, setEdited] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [editedStates, setEditedStates] = useState({});

  const editedCount = Object.values(editedStates).filter((e) => e).length;
  const targetType = dateTypes.find(
    (dateType) => dateType.id === Number(selectedDateTypeId)
  );
  const targetName = targetType?.name;

  const { semiProductProductionResultsForPc, refetch } =
    useSemiProductProductionResultsForPc(
      moment(date).toDate(),
      selectedFactoryLineId,
      selectedDateTypeId,
      dateRange?.startDate ? moment(dateRange.startDate).toDate() : null,
      dateRange?.endDate ? moment(dateRange.endDate).toDate() : null
    );
  const { data: factoryLines } = useFactoryLines(PRODUCT_KIND.SEMI_PRODUCT);

  const filtered = useMemo(() => {
    if (!semiProductProductionResultsForPc) return;
    if (selectedFactoryLineId === 0) {
      return semiProductProductionResultsForPc;
    } else if (selectedFactoryLineId !== '') {
      return semiProductProductionResultsForPc.filter((p: Todo) =>
        p.factoryLineIds?.includes(Number(selectedFactoryLineId))
      );
    }
  }, [selectedFactoryLineId, semiProductProductionResultsForPc]);

  const handleSelectedProduct = (item: Todo, indexId: string) => {
    setSelectedItem(item);
    setRightSlideSidebarActive(!rightSlideSidebarActive);
    setSelectedCell(indexId);
  };

  const getMonth = (num: number) => {
    setDate(moment(date).add(num, 'months').startOf('month'));
  };

  const handleClick = () => {
    getTodayForReference(
      date.format('YYYY-MM'),
      today.format('YYYY-MM'),
      setDate,
      setSelectIndex,
      refetch
    );
  };

  const handleClosePopUp = () => {
    setPopUp(false);
  };

  useEffect(() => {
    localStorage.setItem(
      'last_view_page',
      `${PCPath.semiProductProductionResultPc}`
    );
  }, []);

  useEffect(() => {
    refetch();
  }, [selectedDateTypeId, dateRange]);

  useEffect(() => {
    if (!rightSlideSidebarActive) {
      setEditedStates({});
    }
  }, [rightSlideSidebarActive]);

  return (
    <WithHeader>
      <S.Wrapper>
        <S.HeadContainer>
          <S.PageTitle>
            <S.ProductKind>半製品</S.ProductKind>
            <S.PageTitleText>
              <Heading tag="span" text="生産実績" fs="32" fw="lg" />
              <Heading tag="span" text="を確認" fs="24" fw="lg" />
            </S.PageTitleText>
          </S.PageTitle>

          <S.DateWrapper onClick={() => setPopUp(false)}>
            <S.SelectWrapper>
              <FactoryLineSelect
                data={factoryLines}
                value={selectedFactoryLineId}
                setSelectedId={setSelectedFactoryLineId}
                height="42px"
                width="230px"
                searchable={true}
                selectedFactoryLineName={selectedFactoryLineName}
                setSelectedFactoryLineName={setSelectedFactoryLineName}
                isProductionResult={true}
              />
            </S.SelectWrapper>
            <S.ListLeftHeadCell
              invalid={rightSlideSidebarActive}
              onClick={() => setSearchMenuActive(!searchMenuActive)}
              isBlue={false}
            >
              <span className="filter-product">
                {dateRange.startDate &&
                dateRange.endDate &&
                selectedDateTypeId !== '0' &&
                dateRange.startDate === dateRange.endDate ? (
                  <>
                    <FilteredIcon />
                    <div className="filter_text">
                      {targetName}
                      <br />
                      {`${dateRange.startDate.format('YYYY/MM/DD')}のみ表示`}
                    </div>
                  </>
                ) : dateRange.startDate &&
                  dateRange.endDate &&
                  selectedDateTypeId !== '0' ? (
                  <>
                    <FilteredIcon />
                    <div className="filter_text">
                      {targetName}
                      <br />
                      {`${dateRange.startDate.format(
                        'YYYY/MM/DD'
                      )} → ${dateRange.endDate.format('YYYY/MM/DD')}`}
                    </div>
                  </>
                ) : (
                  <>
                    <Filter isBlue={false} />
                    <div className="filter_text_false-for-semi">
                      保管期限日の絞込み
                    </div>
                  </>
                )}
              </span>
            </S.ListLeftHeadCell>
            <Button
              width="90px"
              margin={'0 8px 0 0'}
              outlined={true}
              borderWidth={1}
              onClick={handleClick}
            >
              今日
            </Button>
            <S.ArrowLeft selected={false} onClick={() => getMonth(-1)}>
              <ArrowLeft />
              <span className="circle"></span>
            </S.ArrowLeft>
            <S.Date>
              {moment(date).format('YYYY')}
              <span>年</span>
              {moment(date).format('MM')}
              <span>月</span>
            </S.Date>
            <S.ArrowRight selected={false} onClick={() => getMonth(1)}>
              <ArrowRight isActive={true} />
            </S.ArrowRight>
          </S.DateWrapper>
          {searchMenuActive && (
            <SearchMenu
              headerTitleText={'保管期限日の絞込み'}
              selectedDateTypeId={selectedDateTypeId}
              setSearchMenuActive={setSearchMenuActive}
              setSelectedDateTypeId={setSelectedDateTypeId}
              setDateRange={setDateRange}
              dateRange={dateRange}
              dateTypes={dateTypes}
            />
          )}
        </S.HeadContainer>

        <div>
          <TableWithFixedSidebar
            selectedDate={date}
            data={filtered}
            selected={false}
            handleSelectedProduct={handleSelectedProduct}
            selectedCell={selectedCell}
            rightSlideSidebarActive={rightSlideSidebarActive}
            selectIndex={selectIndex}
            setSelectIndex={setSelectIndex}
            popUp={popUp}
            setPopUp={setPopUp}
          />
          <RightSlideSidebar
            productionResultsInProducts={semiProductProductionResultsForPc}
            rightSlideSidebarActive={rightSlideSidebarActive}
            setRightSlideSidebarActive={setRightSlideSidebarActive}
            selectedProduct={selectedItem}
            confirmMsg={confirmMsg}
            edited={edited}
            refetch={refetch}
            setConfirmMsg={setConfirmMsg}
            setEdited={setEdited}
            popUp={popUp}
            setPopUp={setPopUp}
            editedStates={editedStates}
            setEditedStates={setEditedStates}
          />
          <UpdatePopUp
            popUp={popUp}
            formattedDate={moment(selectedItem.date).format('YYYY/MM/DD (ddd)')}
            productName={selectedItem.productName}
            handleClose={handleClosePopUp}
            fromPc={true}
            taskKind="productionResult"
            messageKind="update"
          />
        </div>
      </S.Wrapper>
      {(searchMenuActive || rightSlideSidebarActive) && (
        <Overlay
          handleClick={() => {
            setSearchMenuActive(false);
            !edited && !editedCount && setRightSlideSidebarActive(false);
            editedCount &&
              setConfirmMsg('保存せずに編集を終了します。よろしいですか。');
          }}
        />
      )}
    </WithHeader>
  );
};
