import { Redirect, Route, RouteProps } from 'react-router-dom';
import useCurrentUser from '@lib/pc/common/hooks/useCurrentUser';
import { sidebarLinkData } from '@lib/pc/constants';
import { PCPath } from '@config/path';

type Props = RouteProps & {
  component: React.FunctionComponent;
  menuFunction: string;
};
const PCAuthRoute = ({
  component: Component,
  menuFunction,
  ...restOfProps
}: Props) => {
  const currentUser = useCurrentUser();
  // ログインユーザーが使用できるRole
  const menuFunctions =
    (currentUser && localStorage.getItem('menu_functions')?.split(',')) || '';
  // 最終参照ページ
  const lastViewPage =
    (currentUser && localStorage.getItem('last_view_page')) || '';
  // ログインユーザーが使用できるMenu
  const menuDetailLinks =
    (currentUser && localStorage.getItem('menu_detail_links')?.split(',')) ||
    '';

  // バックエンドから取得した、ログインユーザーが使用できるmenuDetailLinksの１つ目の名称から、
  // sidebarLinkDataを使用してmenuのURLを取得する。
  const searchedUrl = sidebarLinkData
    .flatMap((item) => item.links || [])
    .find((link) => link.function === menuDetailLinks[0])?.url;

  // searchedUrlの1文字目の/を削除
  const functionPath = lastViewPage
    ? lastViewPage
    : searchedUrl
    ? searchedUrl
    : PCPath.login;

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        currentUser &&
        menuFunctions?.length &&
        menuFunctions?.includes(menuFunction) ? (
          <Component {...props} />
        ) : currentUser ? (
          <Redirect to={`/pc${functionPath}`} />
        ) : (
          <Redirect
            to={{
              pathname: `/pc${PCPath.login}`,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PCAuthRoute;
