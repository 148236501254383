import * as S from './ShippingStatus.styles';
import ShippingStatusHeaderControl from '@components/modules/pc/shipping_status/ShippingStatusHeaderControl/ShippingStatusHeaderControl';
import TableWithFixedSidebar from '@components/modules/pc/shipping_status/TableWithFixedSidebar/TableWithFixedSidebar';
import { WithHeader } from '@components/templates/WithHeader';
import { Todo } from '@lib/common/type';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import RightSlideSidebar from '@components/modules/pc/shipping_status/RightSlideSidebar/RightSlideSidebar';
import { getTodayForReference } from '@lib/common/functions';
import useShippings from '@lib/pc/shipping_status/useShippings';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { PCPath } from '@config/path';

export const ShippingStatusRoot = () => {
  const today = moment();
  const [date, setDate] = useState<Todo>(moment());
  const [rightSlideBarOpen, setRightSlideBarOpen] = useState(false);
  const [selectedCell, setSelectedCell] = useState('');
  const [selectIndex, setSelectIndex] = useState<number>(
    new Date().getDate() - 1
  );

  const [selectedCellDate, setSelectedCellDate] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');

  const [selectedShippingDestinationId, setSelectedShippingDestinationId] =
    useState(0);
  const [edited, setEdited] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState('');
  const [popUp, setPopUp] = useState(false);

  // バックエンドからのデータ取得
  const { shippings, refetch } = useShippings(
    moment(date),
    selectedShippingDestinationId
  );

  const handleCloseSidebar = useCallback(() => {
    setRightSlideBarOpen(false);
  }, []);

  // セルクリック時
  const handleSelectCell = async (p: Todo, shipping: Todo, idx: number) => {
    if (shipping.amount) {
      setSelectedCellDate(shipping.date);
      setSelectedProduct(p);
      setSelectedCell(`${p.id}-${idx}`);
      setRightSlideBarOpen(!rightSlideBarOpen);
    }
  };

  const handleClickToday = () => {
    getTodayForReference(
      date.format('YYYY-MM'),
      today.format('YYYY-MM'),
      setDate,
      setSelectIndex,
      refetch
    );
  };

  useEffect(() => {
    if (
      selectedShippingDestinationId === 0 ||
      selectedShippingDestinationId ||
      popUp
    ) {
      refetch();
    }
  }, [selectedShippingDestinationId, popUp]);

  useEffect(() => {
    localStorage.setItem('last_view_page', `${PCPath.shippingStatus}`);
  }, []);

  return (
    <WithHeader>
      <S.Wrapper>
        <ShippingStatusHeaderControl
          date={date}
          setDate={setDate}
          handleClickToday={handleClickToday}
          setSelectedShippingDestinationId={setSelectedShippingDestinationId}
          refetch={refetch}
        />
        <TableWithFixedSidebar
          selectedDate={date}
          data={shippings}
          selectedCell={selectedCell}
          setRightSlideBarOpen={setRightSlideBarOpen}
          handleSelectCell={handleSelectCell}
          selectIndex={selectIndex}
          setSelectIndex={setSelectIndex}
          rightSlideBarOpen={rightSlideBarOpen}
          handleCloseSidebar={handleCloseSidebar}
          setSelectedCellDate={setSelectedCellDate}
          setSelectedProduct={setSelectedProduct}
        />
        {selectedProduct && (
          <RightSlideSidebar
            rightSlideBarOpen={rightSlideBarOpen}
            handleCloseSidebar={handleCloseSidebar}
            selectedCellDate={selectedCellDate}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            selectedShippingDestinationId={selectedShippingDestinationId}
            edited={edited}
            setEdited={setEdited}
            confirmMsg={confirmMsg}
            setConfirmMsg={setConfirmMsg}
            popUp={popUp}
            setPopUp={setPopUp}
          />
        )}
        {rightSlideBarOpen && (
          <Overlay
            handleClick={() => {
              !edited && setRightSlideBarOpen(false);
              !edited && setSelectedProduct('');
              edited &&
                setConfirmMsg('保存せずに編集を終了します。よろしいですか。');
              setPopUp(false);
            }}
          />
        )}
      </S.Wrapper>
    </WithHeader>
  );
};
