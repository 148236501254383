import { useMutation } from 'react-query';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo } from '@lib/common/type';

const useDeleteSemiProductStockAllocationMutation = (
  id: number,
  handleDeleteSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async () => {
      return await axiosInstance().delete(
        `/api/v1/semi_product_stock_allocations/${id}`
      );
    },
    {
      onSuccess: () => {
        handleDeleteSuccess();
      },
      onError: (error: Todo) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
      },
    }
  );
};

export default useDeleteSemiProductStockAllocationMutation;
