import React from 'react';
import { useParams } from 'react-router-dom';
import { WithHeader } from '@components/templates/WithHeader';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './SemiProductMasterEdit.styles';
import SemiProductMasterEditWrapper from '@components/modules/pc/settings/semi_product_master_setting/SemiProductMasterEdit/SemiProductMasterEditWrapper/SemiProductMasterEditWrapper';
import useSemiProduct from '@lib/pc/settings/product_master_setting/useSemiProduct';

export const SemiProductMasterEditRoot: React.FC = () => {
  const { id, timestamp } = useParams<{ id: string; timestamp: string }>();
  const { semiProduct, refetch } = useSemiProduct(id);

  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <div>
              <S.ProductKind>半製品</S.ProductKind>
              <Heading tag="span" text="製品マスタ管理" fs="32" fw="lg" />
            </div>
          </S.HeadContainer>
          <SemiProductMasterEditWrapper
            semiProduct={semiProduct}
            refetch={refetch}
            timestamp={timestamp}
          />
        </div>
      </S.Wrapper>
    </WithHeader>
  );
};
