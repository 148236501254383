import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.white};
  position: relative;
  height: calc(100vh - 80px);
`;

export const HeadContainer = styled.div`
  padding: 1.05rem 2.25rem 1.05rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FlexBaseline = styled.div`
  display: flex;
  align-items: baseline;
  > div {
    > span {
      font-feature-settings: 'palt';
    }
  }
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

export const BackButton = styled.div`
  padding: 0.5rem;
  display: flex;
  cursor: pointer;
`;

export const StatusBar = styled.div<{
  bgColor: string;
  color: string;
  borderColor: string;
}>`
  border: ${(props) => `${props.borderColor} solid 2px`};
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};
  padding: 6px 10px;
  width: 8.25rem;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
`;
