import React, { useEffect, useState, useRef } from 'react';
import * as S from './StockAllocationEditModal.styles';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import { Button } from '@components/elements/buttons/Button';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import {
  Product,
  Case,
  Todo,
  ALLOCATION_TYPE,
  Storeroom,
  PRODUCT_KIND,
  scannedProduct,
} from '@lib/common/type';
import { calcTotalFunc } from '@lib/pc/common/functions';
import {
  TABLE_HEADER_WITH_CASE_KIND,
  TABLE_HEADER,
  TABLE_HEADER_WITH_CASE_KIND_UNDER,
  TABLE_HEADER_UNDER,
} from './header';
import StockAllocationTransferSelect from '@components/modules/pc/stock_allocation_status/StockAllocationTransferSelect/StockAllocationTransferSelect';
import CaseSelect from '@components/modules/common/CaseSelect/CaseSelect';
import StockAllocationProductSelect from '@components/modules/pc/stock_allocation_status/StockAllocationProductSelect/StockAllocationProductSelect';
import { StockAllocationEditData } from '@lib/pc/stock_allocation/type';
import LABEL_OF_AMOUNT_MAP from '@components/modules/common/LabelOfAmountMap/LabelOfAmountMap';

type Props = {
  editModalOpen: boolean;
  data: StockAllocationEditData;
  selectedStoreroomsProducts: Product[] | undefined;
  storerooms: Storeroom[];
  cases: Case[];
  selectedStoreroomId: number | undefined;
  handleClose: () => void;
  handleDelete: (id: number) => void;
  handleSubmit: (data: Todo) => void;
  setEditProductName: (editProductName: string) => void;
  casesPerCart: string;
  setCasesPerCart: (casesPerCart: string) => void;
  hasCase: boolean;
  setEditData: (editData: StockAllocationEditData) => void;
  deleteId: number | '';
  setDeleteId: (deleteId: number | '') => void;
  scannedProduct: scannedProduct;
};

const StockAllocationEditModal = ({
  editModalOpen,
  data,
  selectedStoreroomsProducts,
  storerooms,
  cases,
  selectedStoreroomId,
  handleClose,
  handleDelete,
  handleSubmit,
  setEditProductName,
  hasCase,
  setEditData,
  deleteId,
  setDeleteId,
  scannedProduct,
}: Props) => {
  const [newData, setNewData] = useState(data);
  const [errMsg, setErrMsg] = useState('');
  const [piecesPerCase, setPiecesPerCase] = useState(0);
  // ケース種類
  const [selectedCaseId, setSelectedCaseId] = useState(0);
  // 選択商品のケース種類
  const [filteredCases, setFilteredCases] = useState<Case[]>([]);
  // const [allProductId, setAllProductId] = useState(0);
  const [filteredStorerooms, setFilteredStorerooms] = useState<Storeroom[]>([]);

  const bestBeforeDateRef = useRef<HTMLInputElement>(null);
  const initStockTransferId =
    newData.allocationType === ALLOCATION_TYPE.SALES
      ? -1
      : newData.allocationType === ALLOCATION_TYPE.DISCARD
      ? -2
      : newData.allocationType === ALLOCATION_TYPE.CONSUMPTION
      ? -3
      : newData.transferStoreroomId;
  const [selectedStockTransferId, setSelectedStockTransferId] =
    useState(initStockTransferId);
  const processing = useRef(false);

  // 払出元のstoreroomは除いたものを払出先selectへ渡す
  const exceptOwnStorerooms = storerooms.filter(
    (s: Storeroom) => s.id !== selectedStoreroomId
  );

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNewData({ ...newData, [e.target.name]: e.target.value });

    // iPadOSでクリアボタンを効かせるためのコード
    setTimeout(() => {
      if (bestBeforeDateRef.current)
        bestBeforeDateRef.current.defaultValue = '';
    }, 100);
  };

  const calcTotal = (
    e: React.ChangeEvent<HTMLInputElement>,
    piecesPerCase: number
  ) => {
    setNewData((prevData: Todo) => {
      const oldData = prevData;
      const newData = { ...oldData, [e.target.name]: e.target.value };
      const calcValue = calcTotalFunc(e, newData, piecesPerCase);
      prevData = { ...newData, amount: calcValue };
      return prevData;
    });
  };

  const onClickDelete = () => {
    deleteId && handleDelete(deleteId);
    setDeleteId('');
  };

  useEffect(() => {
    if (scannedProduct && scannedProduct.productId !== null) {
      handleChangeProduct(scannedProduct.productId);
    }
  }, [scannedProduct]);

  const handleChangeProduct = (id: number) => {
    if (id && selectedStoreroomsProducts) {
      const getProduct = selectedStoreroomsProducts.find(
        (p: { id: number }) => p.id === Number(id)
      );
      if (hasCase) {
        setNewData({
          ...newData,
          productId: getProduct && getProduct.id ? getProduct.id : 0,
          labelOfAmount:
            getProduct && getProduct.labelOfAmount
              ? getProduct.labelOfAmount
              : '',
        });
        setEditData({
          ...data,
          productId: getProduct && getProduct.id ? getProduct.id : 0,
          labelOfAmount:
            getProduct && getProduct.labelOfAmount
              ? getProduct.labelOfAmount
              : '',
        });
      } else {
        setNewData({
          ...newData,
          productId: getProduct && getProduct.id ? getProduct.id : 0,
          caseId: cases[0].caseId,
          labelOfAmount:
            getProduct && getProduct.labelOfAmount
              ? getProduct.labelOfAmount
              : '',
        });
        setEditData({
          ...data,
          productId: getProduct && getProduct.id ? getProduct.id : 0,
          caseId: cases[0].caseId,
          labelOfAmount:
            getProduct && getProduct.labelOfAmount
              ? getProduct.labelOfAmount
              : '',
        });
      }
      getProduct && setEditProductName(getProduct.name);
    }
  };

  // 払出先の選択
  const handleChangeTransfer = (id: number) => {
    setSelectedStockTransferId(id);
    const allocationType =
      id === -1
        ? 'sales'
        : id === -2
        ? 'discard'
        : id === -3
        ? 'consumption'
        : 'transfer';
    setNewData({
      ...newData,
      allocationType: allocationType,
      storeroomId: id === -1 || id === -2 || id === -3 ? null : id,
    });
  };

  // 保存ボタン押下
  const productRequiredCheck = (newData: Todo) => {
    if (processing?.current) return;

    processing.current = true;

    setTimeout(() => {
      processing.current = false;
    }, 3000);

    newData.productName && setEditProductName(newData.productName);

    if (!newData.productId) {
      setErrMsg('製品名を選択してください。');
    } else if (selectedStockTransferId === null) {
      setErrMsg('払出先を選択してください。');
    } else if (newData.amount === null || newData.amount === 0) {
      setErrMsg('払出数を入力してください。');
    } else if (newData.amount && newData.amount.toString().length > 10) {
      setErrMsg('数量の合計は、10桁以内で入力してください。');
    } else {
      handleSubmit(newData);
    }
  };

  // iPadOS、初期表示時クリアボタンを効かせるためのコード
  useEffect(() => {
    setTimeout(() => {
      if (bestBeforeDateRef.current)
        bestBeforeDateRef.current.defaultValue = '';
    }, 100);
  }, []);

  // ケース種類の選択
  const setSelectedCase = (e: number) => {
    setNewData({
      ...newData,
      caseId: e,
    });
  };

  // 複数のケース種類がある場合
  useEffect(() => {
    if (!hasCase) {
      const piecesPerCase =
        cases.find(
          (c) =>
            c.productKind === PRODUCT_KIND.PRODUCT &&
            c.productId === newData?.productId &&
            c.caseId === cases[0].caseId
        )?.piecesPerBox || 1;
      setPiecesPerCase(piecesPerCase);
    }
  }, [newData?.productId]);

  useEffect(() => {
    const piecesPerCase =
      cases.find(
        (c) =>
          c.productKind === PRODUCT_KIND.PRODUCT &&
          c.productId === newData?.productId &&
          c.caseId === newData?.caseId
      )?.piecesPerBox || 1;
    setPiecesPerCase(piecesPerCase);
    setNewData({
      ...newData,
      piecesPerBox: piecesPerCase,
    });
  }, [selectedCaseId]);

  useEffect(() => {
    const filteredCases = cases?.filter(
      (c) =>
        c.productId === newData.productId &&
        c.productKind === PRODUCT_KIND.PRODUCT
    );
    filteredCases && setFilteredCases(filteredCases);
    const selectedProductInfo = selectedStoreroomsProducts?.find(
      (s: Product) => s.id === newData.productId
    );
    const filteredStorerooms = exceptOwnStorerooms.filter((s: Storeroom) =>
      selectedProductInfo?.storeroomIds.includes(s.id)
    );
    setFilteredStorerooms(filteredStorerooms);
    setNewData({
      ...newData,
      caseId: filteredCases ? filteredCases[0]?.caseId : 0,
      piecesPerBox: filteredCases ? filteredCases[0]?.piecesPerBox : 1,
    });
    const piecesPerCase = filteredCases ? filteredCases[0]?.piecesPerBox : 1;
    setPiecesPerCase(piecesPerCase);
  }, [newData.productId]);

  return (
    <>
      <S.Wrapper open={editModalOpen}>
        <S.Header className="header">
          <div className="title">{newData.detailId ? '編集' : '新規作成'}</div>
        </S.Header>
        <S.Table>
          <tbody>
            {/***** 上段header *****/}
            <S.THead>
              {hasCase === true
                ? TABLE_HEADER_WITH_CASE_KIND.map((t, i: number) => (
                    <S.THeadCell
                      key={t.key + i}
                      wordBreak={t.wordBreak}
                      colSpan={t.colSpan}
                    >
                      {t.key}
                    </S.THeadCell>
                  ))
                : TABLE_HEADER.map((t, i: number) => (
                    <S.THeadCell
                      key={t.key + i}
                      wordBreak={t.wordBreak}
                      colSpan={t.colSpan}
                    >
                      {t.key}
                    </S.THeadCell>
                  ))}

              {/* 削除 */}
              <S.TCell
                rowSpan={4}
                width="2.6rem"
                className="delete-btn"
                background={newData.detailId ? 'white' : ''}
                editMode={newData.detailId ? true : false}
              >
                {newData.detailId ? (
                  <S.DeleteButton onClick={() => setDeleteId(newData.detailId)}>
                    <DeleteIcon />
                  </S.DeleteButton>
                ) : (
                  ''
                )}
              </S.TCell>
            </S.THead>
            {/***** 上段 *****/}
            <S.TRow>
              {/* 製品名 */}
              <S.TCell
                rowSpan={1}
                colSpan={hasCase === true ? 1 : 3}
                width="12.75rem"
                align="center"
              >
                {selectedStoreroomsProducts && (
                  <StockAllocationProductSelect
                    products={selectedStoreroomsProducts}
                    handleChangeProduct={handleChangeProduct}
                    value={newData.productId}
                    disabled={newData.detailId > 0}
                    width="23.5rem"
                    maxHeight="180px"
                    notDisplayCode={true}
                    scannedProduct={scannedProduct}
                    setEditProductName={setEditProductName}
                  />
                )}
              </S.TCell>
              {/* 払出先 */}
              <S.TCell colSpan={hasCase === true ? 3 : 1} fontSize={'16px'}>
                <StockAllocationTransferSelect
                  data={filteredStorerooms}
                  value={selectedStockTransferId ? selectedStockTransferId : 0}
                  productKind={PRODUCT_KIND.PRODUCT}
                  fromEditModal={true}
                  width="376px"
                  height="64px"
                  marginTop="0.3rem"
                  handleChangeTransfer={handleChangeTransfer}
                  disabled={newData?.productId === 0 || newData.detailId > 0}
                  menuMarginTop="32px"
                />
              </S.TCell>
              {/* 賞味期限日 */}
              <S.TCell colSpan={3} fontSize={'16px'}>
                <S.Input
                  className="bestBeforeDate"
                  ref={bestBeforeDateRef}
                  type="date"
                  name="bestBeforeDate"
                  id="bestBeforeDate"
                  value={newData?.bestBeforeDate || ''}
                  onChange={handleChange}
                  pattern="\d{4}-\d{2}-\d{2}"
                  autoComplete="off"
                  height={'4rem'}
                  max="9999-12-31"
                />
              </S.TCell>
            </S.TRow>
            {/***** 下段header *****/}
            <S.THead>
              {hasCase === true
                ? TABLE_HEADER_WITH_CASE_KIND_UNDER.map((t, i: number) => (
                    <S.THeadCell
                      key={t.key + i}
                      wordBreak={t.wordBreak}
                      colSpan={t.colSpan}
                    >
                      {t.key}
                    </S.THeadCell>
                  ))
                : TABLE_HEADER_UNDER.map((t, i: number) => (
                    <S.THeadCell
                      key={t.key + i}
                      wordBreak={t.wordBreak}
                      colSpan={t.colSpan}
                    >
                      {t.key}
                    </S.THeadCell>
                  ))}
            </S.THead>
            <S.TRow>
              {/* ケースの種類 */}
              {hasCase === true && (
                <S.TCell colSpan={1} fontSize={'16px'} className="case-select">
                  <CaseSelect
                    cases={filteredCases}
                    value={newData.caseId ? newData.caseId : selectedCaseId}
                    setSelectedCaseId={setSelectedCaseId}
                    onChange={setSelectedCase}
                    noLabel={true}
                    height="64px"
                    width="376px"
                    marginLeft="0.25rem"
                    marginTop="0"
                    menuListMaxHeight="80px"
                    disabled={
                      newData.productId === 0 ||
                      (newData.amount !== null && newData.amount > 0)
                    }
                    menuMarginTop="28px"
                  />
                </S.TCell>
              )}
              {/* 完成品 包装予定数量 */}
              {/* ケース */}
              <S.TCell width="6rem" className="display-quantity">
                <div className="display-quantity__inner-wrap">
                  <div className="cell-content">
                    <span className="input-label">ケース</span>
                    <S.Input
                      type="text"
                      pattern="^[1-9][0-9]*$"
                      inputMode="numeric"
                      name="case"
                      id="case"
                      value={newData?.case ?? ''}
                      onChange={(e) => {
                        if (e.target.value.match(/^[0-9]*$/)) {
                          calcTotal(e, piecesPerCase);
                        }
                      }}
                      autoComplete="off"
                      align="right"
                      padding="0 20px 0 0"
                      disabled={
                        hasCase
                          ? newData.productId === 0 || newData.caseId == null
                          : newData.productId === 0
                      }
                    />
                    <span className="unit">
                      x{piecesPerCase}
                      {newData.labelOfAmount
                        ? LABEL_OF_AMOUNT_MAP[newData.labelOfAmount]
                        : '個'}
                    </span>
                  </div>
                  <div className="symbol">+</div>
                </div>
              </S.TCell>
              {/* 個 */}
              <S.TCell width="6rem" className="display-quantity">
                <div className="display-quantity__inner-wrap">
                  <div className="cell-content">
                    <span className="input-label">
                      {newData.labelOfAmount
                        ? LABEL_OF_AMOUNT_MAP[newData.labelOfAmount]
                        : '個'}
                    </span>
                    <S.Input
                      type="text"
                      pattern="^[1-9][0-9]*$"
                      inputMode="numeric"
                      name="piece"
                      id="piece"
                      value={newData?.piece ?? ''}
                      onChange={(e) => {
                        if (e.target.value.match(/^[0-9]*$/)) {
                          calcTotal(e, piecesPerCase);
                        }
                      }}
                      autoComplete="off"
                      align="right"
                      padding="0 20px 0 0"
                      disabled={newData.productId === 0}
                    />
                  </div>
                  <div className="symbol">=</div>
                </div>
              </S.TCell>
              {/* 合計 */}
              <S.TCell
                className="display-quantity display-quantity--total"
                width="6rem"
              >
                <div className="display-quantity__inner-wrap">
                  <span className="input-label input-label__sum">合計</span>
                  <div className="inner inner__sum">
                    {newData?.amount}
                    <span className="unit">
                      {newData.labelOfAmount
                        ? LABEL_OF_AMOUNT_MAP[newData.labelOfAmount]
                        : '個'}
                    </span>
                  </div>
                </div>
              </S.TCell>

              {/* 備考 */}
              <S.TCell colSpan={hasCase === true ? 3 : 4} width="8rem">
                <S.TextareaPackagingComment
                  name="comment"
                  id="comment"
                  value={newData?.comment ?? ''}
                  onChange={handleChange}
                  autoComplete="off"
                  className="packaging-comment"
                />
              </S.TCell>
            </S.TRow>
          </tbody>
        </S.Table>
        <S.ButtonWrapper>
          <S.ButtonContainer>
            <Button borderWidth={1} outlined onClick={handleClose}>
              キャンセル
            </Button>
            <Button
              onClick={() => {
                productRequiredCheck(newData);
              }}
            >
              保存
            </Button>
          </S.ButtonContainer>
        </S.ButtonWrapper>
      </S.Wrapper>
      {deleteId && (
        <S.DeleteModalWrapper>
          <S.DeleteModalTextContainer>
            <span>削除後は元に戻すことが出来ません。</span>
            <span>本当に削除しますか？</span>
          </S.DeleteModalTextContainer>
          <S.DeleteModalButtonContainer>
            <Button padding="md" outlined onClick={() => setDeleteId('')}>
              キャンセル
            </Button>
            <Button
              padding="md"
              icon={<DeleteIcon />}
              outlined
              onClick={onClickDelete}
            >
              削除
            </Button>
          </S.DeleteModalButtonContainer>
        </S.DeleteModalWrapper>
      )}
      {errMsg && (
        <S.DeleteModalWrapper width="30%">
          <S.DeleteModalTextContainer>
            <span>{errMsg}</span>
          </S.DeleteModalTextContainer>
          <S.DeleteModalButtonContainer>
            <Button padding="md" outlined onClick={() => setErrMsg('')}>
              OK
            </Button>
          </S.DeleteModalButtonContainer>
        </S.DeleteModalWrapper>
      )}
      {(deleteId || errMsg) && <Overlay zIndex={10000} dark />}
    </>
  );
};

export default StockAllocationEditModal;
