import { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Button } from '@components/elements/buttons/Button';
import { InputWithLabel } from '@components/elements/inputs/InputWithLabel';
import { Heading } from '@components/elements/typographies/Heading';
import * as S from './PCLoginPage.styles';
import useLogin from '@lib/pc/common/hooks/useLogin';
import { PCPath } from '@config/path';
import { sidebarLinkData } from '@lib/pc/constants';

const PCLoginPage = () => {
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const state = location.state as { from: string } | null;
  const [handleLogin] = useLogin(email, password, () =>
    setRedirectToReferrer(true)
  );

  const menuDetailLinks =
    localStorage.getItem('menu_detail_links')?.split(',') || '';

  // バックエンドから取得した、ログインユーザーが使用できるmenuDetailLinksの１つ目の名称から、
  // sidebarLinkDataを使用してmenuのURLを取得する。
  const searchedUrl = sidebarLinkData
    .flatMap((item) => item.links || [])
    .find((link) => link.function === menuDetailLinks[0])?.url;

  // PCでログイン後最初に表示させるページの設定箇所（ここは全Userがdashboardになる）
  if (redirectToReferrer) {
    // ログイン時、バックエンドから取得してきたメニューの1つ目、次に在庫室状況の確認を表示
    return (
      <Redirect
        to={state?.from || `/pc${searchedUrl}` || `/pc${PCPath.stockStatus}`}
      />
    );
  }

  return (
    <S.Wrapper>
      <S.Container>
        <Heading text="Regnio Factory" align="center" fw="lg" fs={'32'} />
        <S.InputContainer>
          <InputWithLabel
            label="メールアドレス"
            placeholder="メールアドレス"
            type="text"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <InputWithLabel
            label="パスワード"
            placeholder="パスワード"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </S.InputContainer>

        <div>
          <Button onClick={handleLogin} padding="lg">
            ログイン
          </Button>
        </div>
      </S.Container>
    </S.Wrapper>
  );
};

export default PCLoginPage;
