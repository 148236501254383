import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { LoginUserMenuInfo } from './type';

const useLoginUser = (id: string | number) => {
  const { data, refetch } = useQuery('login-user', async () => {
    const data = await axiosInstance().get(`/api/v1/settings/users/${id}`);
    return data;
  });
  const user = humps.camelizeKeys(
    data?.data?.user
  ) as unknown as LoginUserMenuInfo;
  return { user, refetch };
};

export default useLoginUser;
