import React, { useEffect, useState, useRef } from 'react';
import * as S from './MaterialAllocationEditModal.styles';
import { TABLE_HEADER, TABLE_HEADER_UNDER } from './header';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import { Button } from '@components/elements/buttons/Button';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import MaterialAllocationTransferSelect from '@components/modules/pc/material_allocation/MaterialAllocationTransferSelect/MaterialAllocationTransferSelect';
import {
  Todo,
  Storeroom,
  Material,
  initializeMaterial,
  MATERIAL_ALLOCATION_TYPE,
  scannedMaterial,
} from '@lib/common/type';
import { calcTotalFunc } from '@lib/pc/materials_inventory/functions';
import MaterialSelect from '@components/modules/pc/material_allocation/MaterialSelect/MaterialSelect';
import { MaterialAllocationEditData } from '@lib/pc/material_allocation/type';

type Props = {
  editModalOpen: boolean;
  data: MaterialAllocationEditData;
  selectedStoreroomsMaterials: Material[] | undefined;
  storerooms: Storeroom[];
  selectedStoreroomId: number | undefined;
  handleClose: () => void;
  handleDelete: (id: number) => void;
  handleSubmit: (data: Todo) => void;
  setEditMaterialName: (editMaterialName: string) => void;
  setEditData: (editData: MaterialAllocationEditData) => void;
  deleteId: number | '';
  setDeleteId: (deleteId: number | '') => void;
  scannedMaterial: scannedMaterial;
};

const MaterialAllocationEditModal = ({
  editModalOpen,
  data,
  selectedStoreroomsMaterials,
  storerooms,
  selectedStoreroomId,
  handleClose,
  handleDelete,
  handleSubmit,
  setEditMaterialName,
  setEditData,
  deleteId,
  setDeleteId,
  scannedMaterial,
}: Props) => {
  const [newData, setNewData] = useState(data);
  const [errMsg, setErrMsg] = useState('');
  const [filteredStorerooms, setFilteredStorerooms] = useState<Storeroom[]>([]);
  // TODO: 既存データの編集時を考慮した修正が必要
  const [selectedMaterialInfo, setSelectedMaterialInfo] =
    useState<Material>(initializeMaterial);

  const expirationDateRef = useRef<HTMLInputElement>(null);
  const initStockTransferId =
    newData.allocationType === MATERIAL_ALLOCATION_TYPE.DISCARD
      ? 0
      : newData.allocationType === MATERIAL_ALLOCATION_TYPE.CONSUMPTION
      ? 2
      : newData.transferStoreroomId;
  const [selectedStockTransferId, setSelectedStockTransferId] =
    useState(initStockTransferId);
  const processing = useRef(false);

  // 払出元のstoreroomは除いたものを払出先selectへ渡す
  const exceptOwnStorerooms = storerooms.filter(
    (s: Storeroom) => s.id !== selectedStoreroomId
  );

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNewData({ ...newData, [e.target.name]: e.target.value });

    // iPadOSでクリアボタンを効かせるためのコード
    setTimeout(() => {
      if (expirationDateRef.current)
        expirationDateRef.current.defaultValue = '';
    }, 100);
  };

  const calcTotal = (
    e: React.ChangeEvent<HTMLInputElement>,
    piecesPerCase: number,
    piecesPerUnit: number
  ) => {
    setNewData((prevData: Todo) => {
      const oldData = prevData;
      const newData = { ...oldData, [e.target.name]: e.target.value };
      const calcValue = calcTotalFunc(newData, piecesPerCase, piecesPerUnit);
      prevData = { ...newData, amount: calcValue };
      return prevData;
    });
  };

  const onClickDelete = () => {
    deleteId && handleDelete(deleteId);
  };

  useEffect(() => {
    if (scannedMaterial && scannedMaterial.materialId !== null) {
      handleChangeMaterial(scannedMaterial.materialId);
    }
  }, [scannedMaterial]);

  const handleChangeMaterial = (id: number) => {
    if (id && selectedStoreroomsMaterials) {
      const getMaterial = selectedStoreroomsMaterials.find(
        (m: { id: number }) => m.id === Number(id)
      );
      setNewData({
        ...newData,
        materialId: getMaterial && getMaterial.id ? getMaterial.id : 0,
      });
      setEditData({
        ...data,
        materialId: getMaterial && getMaterial.id ? getMaterial.id : 0,
      });
      getMaterial && setEditMaterialName(getMaterial.name);
    }
  };

  // 払出先の選択
  const handleChangeTransfer = (id: number) => {
    setSelectedStockTransferId(id);
    const allocationType =
      id === MATERIAL_ALLOCATION_TYPE.DISCARD
        ? 'discard'
        : id === MATERIAL_ALLOCATION_TYPE.CONSUMPTION
        ? 'consumption'
        : 'transfer';
    setNewData({
      ...newData,
      allocationType: allocationType,
      storeroomId: id,
    });
  };

  // 保存ボタン押下
  const productRequiredCheck = (newData: MaterialAllocationEditData) => {
    if (processing?.current) return;

    processing.current = true;

    setTimeout(() => {
      processing.current = false;
    }, 3000);

    setEditMaterialName(selectedMaterialInfo.name);

    if (!newData.materialId) {
      setErrMsg('資材名を選択してください。');
    } else if (selectedStockTransferId === null) {
      setErrMsg('払出先を選択してください。');
    } else if (newData.amount === null || newData.amount === 0) {
      setErrMsg('払出数を入力してください。');
    } else if (newData.amount && newData.amount.toString().length > 7) {
      setErrMsg('数量の合計は、7桁以内で入力してください。');
    } else {
      handleSubmit(newData);
    }
  };

  // iPadOS、初期表示時クリアボタンを効かせるためのコード
  useEffect(() => {
    setTimeout(() => {
      if (expirationDateRef.current)
        expirationDateRef.current.defaultValue = '';
    }, 100);
  }, []);

  // selectedStoreroomsMaterials：選択された払出元の資材
  useEffect(() => {
    const selectedMaterialInfo = selectedStoreroomsMaterials?.find(
      (m: Material) => m.id === newData.materialId
    );
    // 払出元以外の払出先、かつその資材を置く在庫室への絞り込み
    const filteredStorerooms = exceptOwnStorerooms.filter((s: Storeroom) =>
      selectedMaterialInfo?.storeroomIds.includes(s.id)
    );
    setFilteredStorerooms(filteredStorerooms);
    setNewData(newData);
    selectedMaterialInfo && setSelectedMaterialInfo(selectedMaterialInfo);
  }, [newData.materialId]);

  return (
    <>
      <S.Wrapper open={editModalOpen}>
        <S.Header className="header">
          <div className="title">{newData.detailId ? '編集' : '新規作成'}</div>
        </S.Header>
        <S.Table>
          <tbody>
            {/***** 上段header *****/}
            <S.THead>
              {TABLE_HEADER.map((t, i: number) => (
                <S.THeadCell
                  key={t.key + i}
                  wordBreak={t.wordBreak}
                  colSpan={t.colSpan}
                >
                  {t.key}
                </S.THeadCell>
              ))}

              {/* 削除 */}
              <S.TCell
                rowSpan={4}
                width="2.6rem"
                className="delete-btn"
                background={newData.detailId ? 'white' : ''}
                editMode={newData.detailId ? true : false}
              >
                {newData.detailId ? (
                  <S.DeleteButton onClick={() => setDeleteId(newData.detailId)}>
                    <DeleteIcon />
                  </S.DeleteButton>
                ) : (
                  ''
                )}
              </S.TCell>
            </S.THead>
            {/***** 上段 *****/}
            <S.TRow>
              {/* 資材名 */}
              <S.TCell colSpan={4} width="12.75rem" align="center">
                {selectedStoreroomsMaterials && (
                  <MaterialSelect
                    materials={selectedStoreroomsMaterials}
                    handleChangeMaterial={handleChangeMaterial}
                    value={newData.materialId}
                    disabled={newData.detailId > 0}
                    width="23.5rem"
                    maxHeight="180px"
                    notDisplayCode={true}
                    scannedMaterial={scannedMaterial}
                    setEditMaterialName={setEditMaterialName}
                  />
                )}
              </S.TCell>
              {/* 払出先 */}
              <S.TCell colSpan={1} fontSize={'16px'}>
                <MaterialAllocationTransferSelect
                  data={filteredStorerooms}
                  value={selectedStockTransferId}
                  width="376px"
                  height="64px"
                  marginTop="0.3rem"
                  handleChangeTransfer={handleChangeTransfer}
                  disabled={newData?.materialId === 0 || newData.detailId > 0}
                  menuMarginTop="32px"
                />
              </S.TCell>
              {/* 使用期限日 */}
              <S.TCell colSpan={3} fontSize={'16px'}>
                <S.DateWrapper>
                  <S.Input
                    className="expirationDate"
                    ref={expirationDateRef}
                    type="date"
                    name="expirationDate"
                    id="expirationDate"
                    value={newData?.expirationDate || ''}
                    onChange={handleChange}
                    pattern="\d{4}-\d{2}-\d{2}"
                    autoComplete="off"
                    max="9999-12-31"
                  />
                </S.DateWrapper>
              </S.TCell>
            </S.TRow>
            {/***** 下段header *****/}
            <S.THead>
              {TABLE_HEADER_UNDER.map((t, i: number) => (
                <S.THeadCell
                  key={t.key + i}
                  wordBreak={t.wordBreak}
                  colSpan={t.colSpan}
                >
                  {t.key}
                </S.THeadCell>
              ))}
            </S.THead>
            <S.TRow>
              {/* ケース */}
              <S.TCell width="5.5rem" className="display-quantity">
                <div className="display-quantity__inner-wrap">
                  <div className="cell-content">
                    <span className="input-label">ケース</span>
                    <S.Input
                      type="text"
                      pattern="^[1-9][0-9]*$"
                      inputMode="numeric"
                      name="case"
                      id="case"
                      value={newData?.case ?? ''}
                      onChange={(e) => {
                        if (e.target.value.match(/^[0-9]*$/)) {
                          calcTotal(
                            e,
                            selectedMaterialInfo?.piecesPerBox,
                            selectedMaterialInfo?.piecesPerUnit
                          );
                        }
                      }}
                      autoComplete="off"
                      align="right"
                      padding="0 20px 0 0"
                      disabled={newData.materialId === 0}
                    />
                    <span className="unit">
                      {newData.materialId !== 0 &&
                        `x${selectedMaterialInfo?.piecesPerBox || 1}`}
                      {selectedMaterialInfo.labelOfUnit !== 'なし'
                        ? selectedMaterialInfo.labelOfUnit
                        : selectedMaterialInfo.labelOfAmount
                        ? selectedMaterialInfo.labelOfAmount
                        : '個'}
                    </span>
                  </div>
                  <div className="symbol">+</div>
                </div>
              </S.TCell>
              {/* バラまとめ単位：本、袋等 */}
              {selectedMaterialInfo.labelOfUnit !== 'なし' && (
                <S.TCell width="5.5rem" className="display-quantity">
                  <div className="display-quantity__inner-wrap">
                    <div className="cell-content">
                      <span className="input-label">
                        {selectedMaterialInfo.labelOfUnit}
                      </span>
                      <S.Input
                        type="text"
                        pattern="^[1-9][0-9]*$"
                        inputMode="numeric"
                        name="unit"
                        id="unit"
                        value={newData?.unit ?? ''}
                        onChange={(e) => {
                          if (e.target.value.match(/^[0-9]*$/)) {
                            calcTotal(
                              e,
                              selectedMaterialInfo?.piecesPerBox,
                              selectedMaterialInfo?.piecesPerUnit
                            );
                          }
                        }}
                        autoComplete="off"
                        align="right"
                        padding="0 20px 0 0"
                        disabled={newData.materialId === 0}
                      />
                      <span className="unit">
                        {newData.materialId !== 0 &&
                          `x${
                            selectedMaterialInfo?.piecesPerUnit
                              ? selectedMaterialInfo?.piecesPerUnit
                              : 1
                          }`}
                        {selectedMaterialInfo.labelOfAmount}
                      </span>
                    </div>
                    <div className="symbol">+</div>
                  </div>
                </S.TCell>
              )}
              {/* 個 */}
              <S.TCell width="5.5rem" className="display-quantity">
                <div className="display-quantity__inner-wrap">
                  <div className="cell-content">
                    <span className="input-label">
                      {selectedMaterialInfo.labelOfAmount}
                    </span>
                    <S.Input
                      type="text"
                      pattern="^\d*\.?\d{0,2}$"
                      inputMode="decimal"
                      name="piece"
                      id="piece"
                      value={newData?.piece ?? ''}
                      onChange={(e) => {
                        if (e.target.value.match(/^\d*\.?\d{0,2}$/)) {
                          calcTotal(
                            e,
                            selectedMaterialInfo?.piecesPerBox,
                            selectedMaterialInfo?.piecesPerUnit
                          );
                        }
                      }}
                      autoComplete="off"
                      align="right"
                      padding="0 20px 0 0"
                      disabled={newData.materialId === 0}
                    />
                  </div>
                  <div className="symbol">=</div>
                </div>
              </S.TCell>
              {/* 合計 */}
              <S.TCell
                className="display-quantity display-quantity--total"
                width="5.5rem"
              >
                <div className="display-quantity__inner-wrap">
                  <span className="input-label input-label__sum">合計</span>
                  <div className="inner inner__sum">
                    {newData?.amount}
                    <span className="unit">
                      {selectedMaterialInfo?.labelOfAmount
                        ? selectedMaterialInfo?.labelOfAmount
                        : '個'}
                    </span>
                  </div>
                </div>
              </S.TCell>
              {selectedMaterialInfo.labelOfUnit === 'なし' && (
                <S.TCell width="5.5rem" className="display-quantity"></S.TCell>
              )}

              {/* 備考 */}
              <S.TCell colSpan={4} width="8rem">
                <S.TextareaPackagingComment
                  name="comment"
                  id="comment"
                  value={newData?.comment ?? ''}
                  onChange={handleChange}
                  autoComplete="off"
                  className="packaging-comment"
                />
              </S.TCell>
            </S.TRow>
          </tbody>
        </S.Table>
        <S.ButtonWrapper>
          <S.ButtonContainer>
            <Button borderWidth={1} outlined onClick={handleClose}>
              キャンセル
            </Button>
            <Button
              onClick={() => {
                productRequiredCheck(newData);
              }}
            >
              保存
            </Button>
          </S.ButtonContainer>
        </S.ButtonWrapper>
      </S.Wrapper>
      {deleteId && (
        <S.DeleteModalWrapper>
          <S.DeleteModalTextContainer>
            <span>削除後は元に戻すことが出来ません。</span>
            <span>本当に削除しますか？</span>
          </S.DeleteModalTextContainer>
          <S.DeleteModalButtonContainer>
            <Button padding="md" outlined onClick={() => setDeleteId('')}>
              キャンセル
            </Button>
            <Button
              padding="md"
              icon={<DeleteIcon />}
              outlined
              onClick={onClickDelete}
            >
              削除
            </Button>
          </S.DeleteModalButtonContainer>
        </S.DeleteModalWrapper>
      )}
      {errMsg && (
        <S.DeleteModalWrapper width="30%">
          <S.DeleteModalTextContainer>
            <span>{errMsg}</span>
          </S.DeleteModalTextContainer>
          <S.DeleteModalButtonContainer>
            <Button padding="md" outlined onClick={() => setErrMsg('')}>
              OK
            </Button>
          </S.DeleteModalButtonContainer>
        </S.DeleteModalWrapper>
      )}
      {(deleteId || errMsg) && <Overlay zIndex={10000} dark />}
    </>
  );
};

export default MaterialAllocationEditModal;
