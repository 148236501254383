import { axiosInstance } from '@lib/mobile/common/api/axiosConfig';
import { useMutation } from 'react-query';
import moment from 'moment';
import { ErrorResponse } from '@lib/common/type';
import { AxiosError } from 'axios';
// StockAmountDetailテーブルのレコード削除;
export const useDeleteMaterialStocksMutation = (
  amountDetailId: number | null,
  handleDeleteSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async () => {
      return await axiosInstance().delete(
        `/api/v1/material_stocks/${amountDetailId}?selected_date=${moment().format(
          'YYYY-MM-DD'
        )}`
      );
    },
    {
      onSuccess: () => {
        handleDeleteSuccess();
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
      },
    }
  );
};

export default useDeleteMaterialStocksMutation;
