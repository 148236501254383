import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import type { TagNameInputParam } from '@lib/pc/settings/product_master/type';
import { Todo } from '@lib/common/type';

// タグ名更新
const useUpdateTagMutation = (
  id: number,
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: TagNameInputParam) => {
      return await axiosInstance().patch(
        `/api/v1/settings/tags/${id}`,
        humps.decamelizeKeys(params)
      );
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: (error: Todo) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
      },
    }
  );
};

export default useUpdateTagMutation;
