import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import { Todo } from '@lib/common/type';

export const useMaterialStockAllocations = (
  date: Date,
  materialType: number,
  storeroomId: number | undefined
) => {
  const { data, refetch } = useQuery(
    ['materialStockAllocations', date, materialType, storeroomId],
    async () => {
      if (storeroomId != 0) {
        const data = await axiosInstance().get(
          `/api/v1/material_stock_allocations?&date=${moment(date).format(
            'YYYY-MM-DD'
          )}&storeroom_id=${storeroomId}&material_type=${materialType}`
        );
        return data;
      }
    }
  );
  const materialStockAllocations =
    data && (humps.camelizeKeys(data.data) as Todo);
  // ) as unknown as AllStockAllocations);

  return { materialStockAllocations, refetch };
};

export default useMaterialStockAllocations;
