import React, { FC, memo, useEffect, useState } from 'react';
import moment from 'moment';
import * as S from './TableWithFixedSidebar.styles';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import TagMenu from '@components/modules/common/TagMenu/TagMenu';
import TagListModal from '@components/modules/common/TagListModal/TagListModal';
import { addComma } from '@lib/pc/production_schedule/functions';
import {
  generateMonthlyDateArray,
  isSameIndex,
} from '@lib/pc/production_schedule/functions';
import { CircularProgress } from '@material-ui/core';
import { tagSearch } from '@lib/common/functions';
import { PRODUCT_KIND, Todo } from '@lib/common/type';
import { ShippingAmount } from '@lib/pc/shipping_status/type';

type Props = {
  selectedDate: Todo;
  data: Todo[];
  // setSelectedData: Todo | null;
  selectedCell: string;
  setRightSlideBarOpen: (rightSlideBarOpen: boolean) => void;
  handleSelectCell: (p: Todo, amount: Todo, idx: number) => void;
  selectIndex: number;
  setSelectIndex: (selectIndex: number) => void;
  rightSlideBarOpen: boolean;
  handleCloseSidebar: () => void;
  setSelectedCellDate: (selectedCellDate: string) => void;
  setSelectedProduct: (selectedProduct: Todo) => void;
};

const TableWithFixedSidebar: FC<Props> = ({
  selectedDate,
  data,
  selectedCell,
  handleSelectCell,
  selectIndex,
  setSelectIndex,
  rightSlideBarOpen,
  handleCloseSidebar,
  setSelectedProduct,
}: Props) => {
  const [searchWord, setSearchWord] = useState('');
  const [tagMenuActive, setTagMenuActive] = useState(false);
  const [tagListModalActive, setTagListModalActive] = useState(false);
  const [selectedTags, setSelectedTags] = useState<Todo>([]);
  const selectedTagIds = selectedTags.map((item: Todo) => item['id']);

  const ProductNameArr = data
    ? data.map((p: Todo) => ({
        productName: p.name,
        productCode: p.productCode,
        tagIds: p.tagIds,
        orderSummaryDetails: p.orderSummaries,
      }))
    : [];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(e.target.value);
  };

  const MemorizedNoDataListCell = memo(
    ({ p, shipping, idx, monthlyDateNum }: Todo) => {
      const isToday = isSameIndex(idx, selectIndex, monthlyDateNum);

      return (
        <S.ListCellContainer
          highLighten={isToday}
          className={`${p.id}-${idx}` === selectedCell ? 'select-cell' : ''}
        >
          {addComma(shipping.amount)}
        </S.ListCellContainer>
      );
    }
  );
  // 商品数詳細
  const MemorizedListCell = memo(
    ({ p, shipping, idx, monthlyDateNum }: Todo) => {
      const isToday = isSameIndex(idx, selectIndex, monthlyDateNum);
      const isTodayOrEarlier =
        shipping.date === ''
          ? true
          : isTodayOrEarlierCheck(moment(shipping.date));
      return (
        <S.ListCellContainer
          onClick={() => {
            handleSelectCell(p, shipping, idx);
          }}
          isTodayOrEarlier={isTodayOrEarlier}
          highLighten={isToday}
          className={`${p.id}-${idx}` === selectedCell ? 'select-cell' : ''}
          isCursor={true}
        >
          {addComma(shipping.amount)}
        </S.ListCellContainer>
      );
    }
  );

  const isTodayOrEarlierCheck = (dateToCheck: moment.Moment): boolean => {
    const today = moment().startOf('day');
    // 与えられたmomentオブジェクトが今日かそれ以前かを判断
    return dateToCheck.isSameOrBefore(today);
  };

  useEffect(() => {
    setTagMenuActive(false);
  }, [selectedTagIds.length === 0]);

  return (
    <>
      <S.ListWrapper>
        <S.ListSidebar>
          <S.ListLeftHeadCell
            onClick={() => setTagMenuActive(!tagMenuActive)}
            isBlue={searchWord !== '' || selectedTagIds.length > 0}
          >
            <span className="filter-product">
              {searchWord !== '' || selectedTagIds.length > 0 ? (
                <>
                  <FilteredIcon />
                  <div className="filter_text">商品絞込み中</div>
                </>
              ) : (
                <>
                  <Filter />
                  <div className="filter_text">商品の絞込</div>
                </>
              )}
            </span>
          </S.ListLeftHeadCell>
          {/* 商品名、商品コード */}
          {ProductNameArr.filter(
            (p: Todo) =>
              p.productName?.includes(searchWord) ||
              p.productCode?.toString().includes(searchWord)
          )
            .filter((p: Todo) => tagSearch(p, selectedTagIds))
            .map(
              (
                s: {
                  productName: string;
                  productCode: number;
                },
                i: number
              ) => {
                return (
                  <S.ListLeftHeadCell key={i} isProductName={true}>
                    <S.ListLeftHeadName>{s.productName}</S.ListLeftHeadName>
                    <S.ListLeftHeadMeta>
                      <span>{s.productCode}</span>
                    </S.ListLeftHeadMeta>
                  </S.ListLeftHeadCell>
                );
              }
            )}
        </S.ListSidebar>
        {/* <S.ListBody className={selectedCell ? 'move-to-left' : ''}> */}
        <S.ListBody>
          {/* ヘッダー日付 */}
          <S.ListRow className="list-row">
            {data &&
              data[0]?.numberOfDaysParamsMonth &&
              generateMonthlyDateArray(
                data[0].numberOfDaysParamsMonth,
                selectedDate,
                selectIndex
              ).map((p, i) => {
                return (
                  <S.ListHeadCell
                    key={i}
                    index={i}
                    numOfIndex={data[0].numberOfDaysParamsMonth}
                    highLighten={p.isToday}
                    dayOfWeek={moment(p.productionDate).format('ddd')}
                    onClick={() => setSelectIndex(i)}
                  >
                    {i === 0
                      ? moment(p.productionDate).format('M/D(ddd)')
                      : i === data[0].numberOfDaysParamsMonth + 1
                      ? '合計'
                      : i === data[0].numberOfDaysParamsMonth + 2
                      ? '前年同月'
                      : moment(p.productionDate).format('DD(ddd)')}
                  </S.ListHeadCell>
                );
              })}
          </S.ListRow>
          {/* 出荷実績数 */}
          {data ? (
            data
              .filter(
                (p: Todo) =>
                  p.name?.includes(searchWord) ||
                  p.productCode?.toString().includes(searchWord)
              )
              .filter((p: Todo) => tagSearch(p, selectedTagIds))
              .map((p: Todo, i: number) => {
                return (
                  <S.ListRow key={i} className="list-row-number">
                    {p?.shippings?.map(
                      (shipping: ShippingAmount, idx: number) => {
                        return shipping?.amount === null ? (
                          <MemorizedNoDataListCell
                            key={idx}
                            p={p}
                            shipping={shipping}
                            idx={idx}
                            isDataCell={true}
                            monthlyDateNum={p.numberOfDaysParamsMonth}
                          />
                        ) : (
                          <MemorizedListCell
                            key={idx}
                            p={p}
                            shipping={shipping}
                            idx={idx}
                            isDataCell={true}
                            monthlyDateNum={p.numberOfDaysParamsMonth}
                          />
                        );
                      }
                    )}
                  </S.ListRow>
                );
              })
          ) : (
            <S.CircularIconWrapper>
              <CircularProgress style={{ color: '#64b2f9' }} />
            </S.CircularIconWrapper>
          )}
        </S.ListBody>
      </S.ListWrapper>
      {tagMenuActive && (
        <TagMenu
          searchParam={searchWord}
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          setOpenTagModal={() => setTagListModalActive(true)}
          handleChange={handleSearch}
          deleteSearchParam={() => setSearchWord('')}
        />
      )}
      {tagListModalActive && (
        <TagListModal
          selectedTags={selectedTags}
          setSelectedTags={(tags) => setSelectedTags(tags)}
          tagListModalActive={tagListModalActive}
          productKind={PRODUCT_KIND.PRODUCT}
          handleClose={() => setTagListModalActive(false)}
        />
      )}
      {(tagMenuActive || rightSlideBarOpen) && (
        <Overlay
          zIndex={9997}
          handleClick={() => {
            setTagMenuActive(false);
            setSelectedProduct('');
            handleCloseSidebar();
          }}
        />
      )}
    </>
  );
};

export default TableWithFixedSidebar;
