import { useEffect, useState } from 'react';
import * as S from './MaterialContents.styles';
import { ReactComponent as AddImage } from '@assets/icons/add_image.svg';
import { ReactComponent as CautionIcon } from '@assets/icons/caution.svg';
import MaterialTypeSelect from '@components/modules/pc/settings/material_master_setting/MaterialMasterEdit/MaterialTypeSelect/MaterialTypeSelect';
import LabelOfAmountSelect from '@components/modules/pc/settings/material_master_setting/MaterialMasterEdit/LabelOfAmountSelect/LabelOfAmountSelect';
import LabelOfUnitSelect from '@components/modules/pc/settings/material_master_setting/MaterialMasterEdit/LabelOfUnitSelect/LabelOfUnitSelect';
import OrderUnitSelect from '@components/modules/pc/settings/material_master_setting/MaterialMasterEdit/OrderUnitSelect/OrderUnitSelect';
import OrderTypeSelect from '@components/modules/pc/settings/material_master_setting/MaterialMasterEdit/OrderTypeSelect/OrderTypeSelect';
import type { Todo } from '@lib/common/type';
import { useFileHooks } from '@lib/pc/common/functions';
import ProductsUsedList from '../ProductsUsedList/ProductsUsedList';
import {
  MaterialsAndProduct,
  MaterialsAndSemiProduct,
} from '@lib/pc/settings/material_master_edit/type';

type Props = {
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  newMaterialData: Todo;
  setNewMaterialData: (newMaterialData: Todo) => void;
  handleChangeCode: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  handleChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  handleChangeNumber: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    decimalOk?: boolean
  ) => void;
  handleChangeImage: (imageBase64: string) => void;
  handleChangeCheckBox: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setPopUp: (popUp: boolean) => void;
  caseAlert?: boolean;
  storeRoomAlert?: boolean;
  setIsChanged: (isChanged: boolean) => void;
  materialTypes: Todo[];
  orderTypes: Todo[];
  labelOfAmounts: Todo[];
  orderUnits: Todo[];
  labelOfUnits: Todo[];
  materialsAndProducts: MaterialsAndProduct[];
  materialsAndSemiProducts: MaterialsAndSemiProduct[];
};

const MaterialContents = ({
  newMaterialData,
  setNewMaterialData,
  handleChangeCode,
  handleChange,
  handleChangeNumber,
  handleChangeImage,
  handleChangeCheckBox,
  storeRoomAlert,
  setIsChanged,
  materialTypes,
  orderTypes,
  labelOfAmounts,
  orderUnits,
  labelOfUnits,
  materialsAndProducts,
  materialsAndSemiProducts,
}: Props) => {
  const { handleFiles, imageContainerRef } = useFileHooks();
  const imageSelected = !!imageContainerRef?.current?.innerHTML;
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFiles(event);
    const file = event?.currentTarget?.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        typeof reader.result === 'string' && handleChangeImage?.(reader.result);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // 資材・原材料タイプ
  const materialTypesObjects =
    materialTypes &&
    Object.values(materialTypes)?.map((value, key) => ({
      id: key,
      name: value,
    }));

  // 最小数量単位
  const labelsOfAmountObjects =
    labelOfAmounts &&
    Object.values(labelOfAmounts)?.map((value, key) => ({
      id: key,
      name: value,
    }));

  // 最小まとめ単位
  const labelsOfUnitObjects =
    labelOfUnits &&
    Object.values(labelOfUnits)?.map((value, key) => ({
      id: key,
      name: value,
    }));

  // 発注単位
  const orderUnitsObjects =
    orderUnits &&
    Object.values(orderUnits)?.map((value, key) => ({
      id: key,
      name: value,
    }));

  // 発注方法
  const orderTypesObjects =
    orderTypes &&
    Object.values(orderTypes)?.map((value, key) => ({
      id: key,
      name: value,
    }));

  type ObjectWitKey = { id: number; name: string; key: string };

  // 最小数量単位
  const labelsOfAmountKeys = labelOfAmounts && Object.keys(labelOfAmounts);
  const [selectedLabelOfAmountName, setSelectedLabelOfAmountName] =
    useState('');

  // 最小数量まとめ単位
  const labelsOfUnitKeys = labelOfUnits && Object.keys(labelOfUnits);
  const [selectedLabelOfUnitName, setSelectedLabelOfUnitName] = useState('');

  // 発注単位
  const orderUnitKeys = orderTypes && Object.keys(orderUnits);
  const [selectedOrderUnitName, setSelectedOrderUnitName] = useState('');

  // 発注方法
  const orderTypeKeys = orderTypes && Object.keys(orderTypes);
  const [selectedOrderTypeName, setSelectedOrderTypeName] = useState('');

  // 「最小数量単位」初期値設定
  useEffect(() => {
    const objectsWithKey: ObjectWitKey[] = [];
    labelsOfAmountObjects?.forEach((obj, idx: number) => {
      const appendedKey = Object.assign({}, obj, {
        key: labelsOfAmountKeys[idx],
      });
      objectsWithKey.push(appendedKey);
    });

    const labelValue = objectsWithKey.find(
      (o) => o.key === newMaterialData?.labelOfAmount
    );
    labelValue && setSelectedLabelOfAmountName(labelValue?.name);
  }, [labelsOfAmountObjects?.length, newMaterialData?.labelOfAmount]);

  // 「最小数量まとめ単位」初期値設定
  useEffect(() => {
    const objectsWithKey: ObjectWitKey[] = [];
    labelsOfUnitObjects?.forEach((obj, idx: number) => {
      const appendedKey = Object.assign({}, obj, {
        key: labelsOfUnitKeys[idx],
      });
      objectsWithKey.push(appendedKey);
    });
    const labelValue = objectsWithKey.find(
      (o) => o.name === newMaterialData?.labelOfUnitValue
    );
    labelValue && setSelectedLabelOfUnitName(labelValue?.name);
  }, [labelsOfUnitObjects?.length, newMaterialData?.labelOfUnitValue]);

  // 「発注単位」初期値設定
  useEffect(() => {
    const objectsWithKey: ObjectWitKey[] = [];
    orderUnitsObjects?.forEach((obj, idx: number) => {
      const appendedKey = Object.assign({}, obj, {
        key: orderUnitKeys[idx],
      });
      objectsWithKey.push(appendedKey);
    });
    const labelValue = objectsWithKey.find(
      (o) => o.name === newMaterialData?.orderUnitValue
    );
    labelValue && setSelectedOrderUnitName(labelValue?.name);
  }, [orderUnitsObjects?.length, newMaterialData?.orderUnitValue]);

  // 「発注方法」初期値設定
  useEffect(() => {
    const objectsWithKey: ObjectWitKey[] = [];
    orderTypesObjects?.forEach((obj, idx: number) => {
      const appendedKey = Object.assign({}, obj, {
        key: orderTypeKeys[idx],
      });
      objectsWithKey.push(appendedKey);
    });
    const labelValue = objectsWithKey.find(
      (o) => o.name === newMaterialData?.orderTypeLabel
    );
    labelValue && setSelectedOrderTypeName(labelValue?.name);
  }, [orderTypesObjects?.length, newMaterialData?.orderTypeLabel]);

  return (
    <>
      <div style={{ display: 'flex', gap: '24px' }}>
        {/* 商品画像 */}
        <div className="product-img">
          <label>
            {imageSelected ? (
              ''
            ) : newMaterialData?.imageUrl ? (
              <img src={newMaterialData.imageUrl} />
            ) : (
              <div className="add-img">
                <AddImage />
              </div>
            )}
            <div ref={imageContainerRef} />
            <S.ImageInput
              type="file"
              accept="image/*"
              onChange={onFileChange}
              name="image"
            />
          </label>
        </div>
        <S.AlertAndMaterialCodeArea>
          <S.AlertArea>
            {storeRoomAlert && (
              <S.AlertWrapper>
                <CautionIcon />
                <S.Alert> 在庫室が未登録です。</S.Alert>
              </S.AlertWrapper>
            )}
          </S.AlertArea>
          <S.MaterialCodeArea
            alertExist={storeRoomAlert ? storeRoomAlert : false}
          >
            <S.MaterialCodeElement>
              <dt>資材／原材料</dt>
              <dd>
                <MaterialTypeSelect
                  allMaterialType={materialTypesObjects}
                  value={newMaterialData?.materialTypeValue}
                  newMaterialData={newMaterialData}
                  setNewMaterialData={setNewMaterialData}
                  setIsChanged={setIsChanged}
                />
              </dd>
            </S.MaterialCodeElement>

            <S.MaterialCodeElement>
              <dt>管理コード</dt>
              <dd>
                <S.Input
                  type="text"
                  pattern="^[a-zA-Z0-9]+$"
                  name="materialCode"
                  id="materialCode"
                  autoComplete="off"
                  align="left"
                  padding="0 20px"
                  value={
                    newMaterialData?.materialCode
                      ? newMaterialData?.materialCode
                      : ''
                  }
                  onChange={handleChangeCode}
                />
              </dd>
            </S.MaterialCodeElement>

            <S.MaterialCodeElement>
              <dt>使用期限日数</dt>
              <dd>
                <S.Input
                  type="text"
                  pattern="^[a-zA-Z0-9]+$"
                  name="expiryDays"
                  id="expiryDays"
                  autoComplete="off"
                  align="left"
                  padding="0 20px"
                  value={
                    newMaterialData?.expiryDays
                      ? newMaterialData?.expiryDays
                      : ''
                  }
                  onChange={handleChangeNumber}
                />
              </dd>
            </S.MaterialCodeElement>
          </S.MaterialCodeArea>
        </S.AlertAndMaterialCodeArea>
      </div>
      <dl className="product-infos">
        <div className="product-info">
          <dt>資材・原材料名</dt>
          <dd>
            <S.Input
              type="text"
              name="name"
              id="name"
              value={newMaterialData?.name ? newMaterialData?.name : ''}
              onChange={handleChange}
              autoComplete="new-password"
              align="left"
              padding="0 20px"
            />
          </dd>
        </div>

        <div className="product-info">
          <dt>資材・原材料名（ふりがな）</dt>
          <dd>
            <S.Input
              type="text"
              name="nameKana"
              id="nameKana"
              value={newMaterialData?.nameKana ? newMaterialData?.nameKana : ''}
              onChange={handleChange}
              autoComplete="new-password"
              align="left"
              padding="0 20px"
            />
          </dd>
        </div>

        <div className="product-info">
          <dt>資材・原材料名（略称）</dt>
          <dd>
            <S.Input
              type="text"
              name="nameAbbreviation"
              id="nameAbbreviation"
              value={
                newMaterialData?.nameAbbreviation
                  ? newMaterialData?.nameAbbreviation
                  : ''
              }
              onChange={handleChange}
              autoComplete="new-password"
              align="left"
              padding="0 20px"
            />
          </dd>
        </div>

        <S.SummaryLine>
          <S.EachSummaryCell>
            <dt>最大単位量</dt>
            <S.EachSummaryCellDd>
              <S.Input
                type="text"
                name="piecesPerBox"
                id="piecesPerBox"
                autoComplete="off"
                align="left"
                padding="0 20px"
                value={
                  newMaterialData?.piecesPerBox
                    ? newMaterialData?.piecesPerBox
                    : ''
                }
                onChange={handleChangeNumber}
              />
              {selectedLabelOfUnitName !== 'なし' ? (
                <span>{selectedLabelOfUnitName}</span>
              ) : (
                <span>{selectedLabelOfAmountName}</span>
              )}
            </S.EachSummaryCellDd>
          </S.EachSummaryCell>
          <S.EachSummaryCell>
            <dt>最小単位まとめ量</dt>
            <S.EachSummaryCellDd top={selectedLabelOfAmountName === 'g'}>
              <S.Input
                type="text"
                inputMode="numeric"
                name="piecesPerUnit"
                id="piecesPerUnit"
                autoComplete="off"
                align="left"
                padding="0 20px"
                value={
                  newMaterialData?.piecesPerUnit
                    ? newMaterialData?.piecesPerUnit
                    : ''
                }
                onChange={(e) => handleChangeNumber(e, true)}
              />
              {selectedLabelOfAmountName === 'g' ? (
                <span>&nbsp;{selectedLabelOfAmountName}</span>
              ) : selectedLabelOfAmountName !== 'なし' ? (
                <span>{selectedLabelOfAmountName}</span>
              ) : (
                <span>&ensp;</span>
              )}
            </S.EachSummaryCellDd>
          </S.EachSummaryCell>
          <S.EachSummaryCell marginRight={'20px'}>
            <dt>最小単位まとめ量の単位</dt>
            <dd>
              <LabelOfUnitSelect
                allLabelOfAmount={labelsOfUnitObjects}
                value={selectedLabelOfUnitName}
                setSelectedLabelOfUnitName={setSelectedLabelOfUnitName}
                newMaterialData={newMaterialData}
                setNewMaterialData={setNewMaterialData}
                setIsChanged={setIsChanged}
              />
            </dd>
          </S.EachSummaryCell>
          <S.EachSummaryCell>
            <dt>最小数量単位</dt>
            <dd>
              <LabelOfAmountSelect
                allLabelOfAmount={labelsOfAmountObjects}
                value={selectedLabelOfAmountName}
                setSelectedLabelOfAmountName={setSelectedLabelOfAmountName}
                newMaterialData={newMaterialData}
                setNewMaterialData={setNewMaterialData}
                setIsChanged={setIsChanged}
              />
            </dd>
          </S.EachSummaryCell>
        </S.SummaryLine>

        <S.SummaryLine>
          <S.EachSummaryCell>
            <dt>基準発注数</dt>
            <S.EachSummaryCellDd>
              <S.Input
                type="text"
                inputMode="numeric"
                name="defaultOrderAmount"
                id="defaultOrderAmount"
                autoComplete="off"
                align="left"
                padding="0 20px"
                value={
                  newMaterialData?.defaultOrderAmount
                    ? newMaterialData?.defaultOrderAmount
                    : ''
                }
                onChange={handleChangeNumber}
              />
              <span>{selectedOrderUnitName}</span>
            </S.EachSummaryCellDd>
          </S.EachSummaryCell>
          <S.EachSummaryCell marginRight={'20px'}>
            <dt>発注単位</dt>
            <dd>
              <OrderUnitSelect
                allLabelOfAmount={orderUnitsObjects}
                value={selectedOrderUnitName}
                setSelectedOrderUnitName={setSelectedOrderUnitName}
                newMaterialData={newMaterialData}
                setNewMaterialData={setNewMaterialData}
                setIsChanged={setIsChanged}
              />
            </dd>
          </S.EachSummaryCell>
          <S.EachSummaryCell marginRight={'20px'}>
            <dt>発注方法</dt>
            <dd>
              <OrderTypeSelect
                allLabelOfAmount={orderTypesObjects}
                value={selectedOrderTypeName}
                setSelectedOrderTypeName={setSelectedOrderTypeName}
                newMaterialData={newMaterialData}
                setNewMaterialData={setNewMaterialData}
                setIsChanged={setIsChanged}
              />
            </dd>
          </S.EachSummaryCell>
          <S.EachSummaryCell>
            <dt>在庫下限閾値</dt>
            <S.EachSummaryCellDd>
              <S.Input
                type="text"
                name="alertThreshold"
                id="alertThreshold"
                autoComplete="off"
                align="left"
                padding="0 20px"
                value={
                  newMaterialData?.alertThreshold
                    ? newMaterialData?.alertThreshold
                    : ''
                }
                onChange={handleChangeNumber}
              />
              <span>{selectedLabelOfAmountName}</span>
            </S.EachSummaryCellDd>
          </S.EachSummaryCell>
        </S.SummaryLine>

        <S.CheckBoxArea>
          <S.Checkbox
            type="checkbox"
            name="hidden"
            onChange={handleChangeCheckBox}
            checked={newMaterialData?.hidden ? newMaterialData?.hidden : false}
          />
          <S.HiddenAndDiscontinued defaultChecked={newMaterialData?.hidden}>
            非表示
          </S.HiddenAndDiscontinued>
        </S.CheckBoxArea>
        <div className="remarks">
          <dt
            style={{
              fontWeight: 700,
              marginBottom: '10px',
            }}
          >
            備考
          </dt>
          <dd>
            <S.TextArea
              name="remarks"
              id="remarks"
              value={newMaterialData?.remarks ? newMaterialData?.remarks : ''}
              onChange={handleChange}
              autoComplete="off"
            />
          </dd>
        </div>
      </dl>
      <S.ProductsUserList>使用されている製品の工程名</S.ProductsUserList>
      <S.ProductsAndSemiProducts>
        {/* 完成品 */}
        <div style={{ width: '50%' }}>
          <ProductsUsedList
            products={materialsAndProducts}
            productKind={'Product'}
          />
        </div>
        {/* 半製品 */}
        <div style={{ width: '50%' }}>
          <ProductsUsedList
            products={materialsAndSemiProducts}
            productKind={'SemiProduct'}
          />
        </div>
      </S.ProductsAndSemiProducts>
    </>
  );
};

export default MaterialContents;
