import * as S from './TableWithFilter.styles';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import SearchMenu from '@components/modules/pc/picking/SearchMenu/SearchMenu';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { ShippingGroup } from '@lib/common/type';
import { ShippingGroupState } from '@lib/pc/settings/shipping_group_setting/type';

type Props = {
  shippingGroups: ShippingGroup[];
};

const TableWithFilter = ({ shippingGroups }: Props) => {
  const { state } = useLocation<ShippingGroupState>();
  const history = useHistory();

  const [stateScrollPosition, setScrollPosition] = useState<number | null>(
    null
  );

  // 検索modal表示
  const [searchModalActive, setSearchModalActive] = useState(false);
  // 検索文字列
  const [searchWord, setSearchWord] = useState('');

  // 検索出荷先グループ名
  const handleSetWordSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(e.target.value);
  };

  // スクロール位置保存
  document?.getElementById('table-contents')?.addEventListener('scroll', () => {
    const currentScrollPosition =
      document?.getElementById('table-contents')?.scrollTop;
    if (currentScrollPosition) {
      setScrollPosition(currentScrollPosition);
    }
  });

  useEffect(() => {
    if (state === undefined) {
      document?.getElementById('table-contents')?.scrollTo(0, 0);
      setSearchWord('');
    } else {
      const { stateScrollPosition, stateSearchWord } = state;
      stateSearchWord && setSearchWord(stateSearchWord);
      document
        ?.getElementById('table-contents')
        ?.scrollTo(0, stateScrollPosition);
    }
  }, [state]);

  const FilterFunctionIcon = () => {
    return (
      <S.ListLeftHeadCell
        onClick={() => setSearchModalActive(!searchModalActive)}
        isBlue={searchWord !== ''}
      >
        <span>
          {searchWord !== '' ? (
            <FilteredIcon />
          ) : (
            <Filter isBlue={searchWord !== ''} />
          )}
        </span>
      </S.ListLeftHeadCell>
    );
  };
  return (
    <>
      <S.Table>
        <thead>
          <S.Tr>
            <S.TCell className="shipping-code">出荷先グループコード</S.TCell>
            <S.HeaderName
              isBlue={searchWord !== ''}
              onClick={() => {
                setSearchModalActive(!searchModalActive);
              }}
            >
              出荷先グループ名
              <FilterFunctionIcon />
            </S.HeaderName>
            <S.TCell className="shipping-time">出荷予定時刻</S.TCell>
          </S.Tr>
        </thead>
      </S.Table>
      <S.TableContents className="table-contents" id="table-contents">
        <S.StyledTable>
          <tbody>
            {shippingGroups &&
              shippingGroups
                .filter(
                  (sg: ShippingGroup) =>
                    sg.name.includes(searchWord) || sg.code.includes(searchWord)
                )
                .map((s, i) => {
                  return (
                    <S.TRow
                      key={i}
                      onClick={() => {
                        history.push(
                          `/pc/settings/shipping_group_setting_edit/${s.id}`,
                          {
                            ...state,
                            stateScrollPosition: stateScrollPosition,
                            stateSearchWord: searchWord,
                            id: s.id,
                            name: s.name,
                            code: s.code,
                            shippingTime: s.shippingTime,
                          }
                        );
                      }}
                    >
                      <S.TCell className="shipping-code">{s.code}</S.TCell>
                      <S.TCell className="shipping-name">{s.name}</S.TCell>
                      <S.TCell className="shipping-time">
                        {s.shippingTime
                          ? moment(s.shippingTime).format('LT')
                          : ''}
                      </S.TCell>
                    </S.TRow>
                  );
                })}
          </tbody>
        </S.StyledTable>
      </S.TableContents>
      {searchModalActive && (
        <SearchMenu
          title={'出荷先グループ名/コード'}
          searchParam={searchWord}
          handleClose={() => setSearchModalActive(false)}
          handleChange={handleSetWordSearch}
          left={'14rem'}
          top={'184px'}
          deleteSearchParam={setSearchWord}
        />
      )}
      {searchModalActive && (
        <Overlay
          handleClick={() => {
            setSearchModalActive(false);
          }}
        />
      )}
    </>
  );
};

export default TableWithFilter;
