import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  max-width: 100vw;
  max-height: 100vh;
  height: calc(100vh - 143px);
  overflow: scroll;
  position: relative;
  background: ${(props) => props.theme.palette.beige};
`;

const normalCell = css`
  border-bottom: 1px solid #efefef;
`;

const lastCell = css`
  border-bottom: 2px solid ${(props) => props.theme.palette.sandGray};
`;

export const ListWrapper = styled.div`
  max-width: 100vw;
  max-height: 100vh;
  height: calc(100vh - 139px);

  overflow: scroll;
  display: flex;
  position: relative;
  background: ${(props) => props.theme.palette.beige};
`;

export const List = styled.div`
  position: relative;
  border-collapse: initial;

  td,
  th {
    height: 44px;
    padding: 0.25em;
    vertical-align: middle;
    border: 1px solid ${(props) => props.theme.palette.yellowGray};

    :nth-child(2) {
      border-left: none;
    }
  }

  tr:nth-child(odd) td:nth-child(odd) {
    background: ${(props) => props.theme.palette.yellowGray};
    background-blend-mode: multiply;
    :first-child {
      background: ${(props) => props.theme.palette.paleYellow} !important;
    }
  }

  tr {
    border-bottom: 1px solid ${(props) => props.theme.palette.yellowGray};
  }

  tr:nth-child(odd) td:nth-child(even) {
    background: ${(props) => props.theme.palette.paleYellow};
    background-blend-mode: multiply;
    :first-child {
      background: #fff !important;
    }
  }

  tr:nth-child(even) td:nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
    background-blend-mode: multiply;
    :first-child {
      background: #fff !important;
    }
  }

  tr:nth-child(even) td:nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }

  thead th:nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
  }

  thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
    z-index: 1000;
    background: ${(props) => props.theme.palette.white};
    color: ${(props) => props.theme.palette.baseBlack};
    &.today {
      color: ${(props) => props.theme.palette.white};
      background: ${(props) => props.theme.palette.blue};
      border-color: ${(props) => props.theme.palette.blue};
    }

    :first-child {
      z-index: 1001 !important;
    }

    //シャドウ後でクラス名に変える
    :not(:first-child) {
      box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);
    }
  }

  tbody td {
    &.today {
      position: relative;

      ::before {
        border-right: 1px solid ${(props) => props.theme.palette.blue};
        content: '';
        height: 105%;
        top: 0px;
        right: -1px;
        position: absolute;
      }
      ::after {
        border-left: 1px solid ${(props) => props.theme.palette.blue};
        content: '';
        height: 105%;
        top: 0px;
        left: 0;
        position: absolute;
      }
    }
  }
  tr:last-child {
    td.today {
      border-bottom: 1px solid ${(props) => props.theme.palette.blue};
    }
  }
  thead th:first-child {
    left: 0;
    z-index: 1;
    background: ${(props) => props.theme.palette.white};
    color: ${(props) => props.theme.palette.baseBlack};
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
    background: #fff;
    z-index: 1000;
    box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.2);
  }
  tbody tr th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;

    left: 0;
    border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
    background: #fff;
    z-index: 1000;
    :nth-child(odd) {
      background: ${(props) => props.theme.palette.paleYellow};
    }

    //シャドウ後でクラス名に変える
    box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.2);
  }
`;

/**
 *
 * リスト
 *
 */
export const ListHeader = styled.div`
  display: flex;
  align-items: center;

  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);
`;

const highLightenHeadCell = css`
  height: 49px;
  color: ${(props) => props.theme.palette.white}!important;
  background: ${(props) => props.theme.palette.blue}!important;
  border-color: ${(props) => props.theme.palette.blue}!important;
`;

const highLightenCell = css`
  position: relative;
  border-right: 2px solid ${(props) => props.theme.palette.blue};
  border-left: 2px solid ${(props) => props.theme.palette.blue};
`;

const cornerArrow = css`
  ::before,
  ::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
  }
  ::before {
    border-width: 0.4rem;
  }
  ::after {
    border-width: 0.4rem;
    border-right-color: #c74141;
    border-top-color: #c74141;
  }
`;

// 月日、合計、前年同月
export const ListHeadCell = styled.div<{
  highLighten: boolean;
  dayOfWeek?: string;
  index: number;
  numOfIndex: number;
}>`
  height: 49px;
  width: 91px;
  box-sizing: border-box;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :last-child {
    position: sticky;
    top: 0;
    right: 0;
    border-left: 2px double ${(props) => props.theme.palette.sandGray};
    font-weight: 700;
  }

  :nth-last-child(2) {
    position: sticky;
    top: 0;
    right: 91px;
    border-left: 2px double ${(props) => props.theme.palette.sandGray};
    font-weight: 700;
  }

  :nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
  }

  :nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }

  ${({ highLighten }) => (highLighten ? highLightenHeadCell : null)}

  color: ${(props) =>
    props.dayOfWeek === '土' && props.index <= props.numOfIndex
      ? '#1046cf'
      : props.dayOfWeek === '日' && props.index <= props.numOfIndex
      ? '#c74141'
      : ''};
`;

export const ListCell = styled.div<{
  highLighten: boolean;
}>`
  cursor: pointer;

  height: 45px;
  width: 80px;
  box-sizing: border-box;
  padding: 0.5rem 0;
  border-spacing: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ highLighten }) => (highLighten ? highLightenCell : null)}
`;

// 各数値セル
export const ListCellContainer = styled.div<{
  highLighten: boolean;
  withCheckEnabled?: boolean;
  invalid?: boolean;
  last?: boolean;
  productionSchedule?: boolean;
}>`
  position: relative;
  width: 91px;
  box-sizing: border-box;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.productionSchedule ? 'pointer' : '')};
  height: 30px;
  color: ${(props) => (props.withCheckEnabled ? '#C74141' : '')};
  font-weight: ${(props) => (props.withCheckEnabled ? 'bold' : '')};
  background-color: ${(props) =>
    props.withCheckEnabled ? '#FDDEDE!important' : ''};
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};

  /* 前年同月 */
  :last-child {
    position: sticky;
    top: 0;
    right: 0;
    border-left: 2px double ${(props) => props.theme.palette.sandGray};
    font-weight: 700;
    background: ${(props) => props.theme.palette.white};
    pointer-events: none;
  }

  /* 合計 */
  :nth-last-child(2) {
    position: sticky;
    top: 0;
    right: 91px;
    border-left: 2px double ${(props) => props.theme.palette.sandGray};
    font-weight: 700;
    background: ${(props) => props.theme.palette.beige};
    pointer-events: none;
  }

  ${({ withCheckEnabled }) => (withCheckEnabled ? cornerArrow : null)};
  ${({ highLighten }) => (highLighten ? highLightenCell : null)};
  ${({ last }) => (last ? lastCell : normalCell)};

  &.select-cell {
    border: 2px solid ${(props) => props.theme.palette.primary.main};
  }
`;

export const ListBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: relative;
  height: max-content;
  -webkit-transition: transform 320ms ease-in-out;
  -moz-transition: transform 320ms ease-in-out;
  -ms-transition: transform 320ms ease-in-out;
  -o-transition: transform 320ms ease-in-out;
  transition: transform 320ms ease-in-out;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  :first-child {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: -1px;
    z-index: 4;
    box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);
  }
  &.move-to-left {
    -webkit-transform: translateX(-320px);
    -moz-transform: translateX(-320px);
    -ms-transform: translateX(-320px);
    -o-transform: translateX(-320px);
    transform: translateX(-320px);
  }

  & > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.paleYellow};
  }

  & > div:nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }
  & > div:not(:first-child):nth-child(even) > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
  }
  & > div:not(:first-child):nth-child(odd) > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.yellowGray};
  }
`;

// 商品名、商品コード
export const ListLeftHeadNameAndCode = styled.div<{
  productIndex: number;
}>`
  width: 205px;
  vertical-align: middle;
  border-right: 1px solid #efefef;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  background: ${(props) =>
    props.productIndex % 2 === 0
      ? props.theme.palette.white
      : props.theme.palette.paleYellow};
`;

export const ListProductNameAndCode = styled.div`
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ListProductName = styled.div`
  color: ${(props) => props.theme.palette.blue};
`;

// 表示タイプ
export const ListLeftHeadTypeName = styled.div<{
  notDisplay: string;
  productIndex: number;
  typeIndex: number;
  last: boolean;
}>`
  width: 100px;
  height: 30px;
  font-size: 15px;
  color: #6b675c;
  text-align: left;
  padding-left: 1.1rem;
  vertical-align: middle;
  background: aquamarine;
  display: ${(props) => (props.notDisplay === 'true' ? 'none' : 'flex')};
  border-bottom: 1px solid #efefef;
  align-items: center;
  ${({ last }) => (last ? lastCell : normalCell)};
  background: ${(props) =>
    props.productIndex % 2 === 0
      ? props.typeIndex % 2 === 0
        ? props.theme.palette.white
        : props.theme.palette.paleYellow
      : props.typeIndex % 2 === 0
      ? props.theme.palette.paleYellow
      : props.theme.palette.white};
`;

// 月日、合計、前年同月ラッパー
export const ListRow = styled.div<{
  notDisplay?: string;
}>`
  display: ${(props) => (props.notDisplay === 'true' ? 'none' : 'flex')};
  background: #fff;
  position: -webkit-sticky; /* for Safari */
  position: sticky;

  :first-child {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: -1px;
    z-index: 4;
    height: 49px;
    box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);
  }
`;

export const SearchArea = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.25rem;
`;

export const ListSidebar = styled.div`
  min-width: 300px;
  height: max-content;
  border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
  background: #fff;
  background: ${(props) => props.theme.palette.beige};

  position: sticky;
  left: -1px;
  z-index: 5;

  & > div:first-child {
    background: ${(props) => props.theme.palette.paleYellow};

    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: -1px;
    padding: 1px;
    background: ${(props) => props.theme.palette.yellowGray};
    z-index: 1000;
  }
`;

// 商品の絞込、表示選択
export const ListLeftHeadCell = styled.div<{
  isBlue?: boolean;
}>`
  height: 49px;
  min-width: 298px;
  box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.05);
  padding-left: 0.75rem;
  position: -webkit-sticky; /* for Safari */
  display: flex;
  justify-content: space-between;
  padding: 6px 0.5rem;
  gap: 0.25rem;
  flex-direction: column;

  > span {
    font-size: 0.75rem;
    &.filter-product {
      width: 61%;
      padding-left: 30px;
      height: 32px;
      line-height: 32px;
      position: absolute;
      background: ${(props) => props.theme.palette.white};
      color: ${(props) =>
        props.isBlue ? props.theme.palette.blue : '#9ca3af'};
      border-radius: 0.5rem;
      cursor: pointer;

      > svg {
        position: absolute;
        top: 12%;
        left: 5%;
      }

      > div {
        padding-left: 3px;
        font-size: 0.7rem;
      }
    }
  }

  :first-child {
    background-color: #f5f2e6 !important;
    > div > input {
      cursor: pointer;
      height: 32px;
    }
  }
`;

export const DisplayButton = styled.div<{
  isBlue: boolean;
}>`
  > span {
    &.filter-product {
      width: 29%;
      padding-left: 30px;
      height: 32px;
      line-height: 32px;
      top: 7px;
      right: 8px;
      position: absolute;
      background: ${(props) => props.theme.palette.white};
      color: ${(props) =>
        props.isBlue ? props.theme.palette.blue : '#9ca3af'};
      border-radius: 0.5rem;
      cursor: pointer;

      > svg {
        position: absolute;
        top: 12%;
        left: 5%;
      }

      > div {
        /* padding-left: 3px; */
        font-size: 0.7rem;
      }
    }
  }

  > svg {
    position: absolute;
    top: 12%;
    left: 5%;
  }
`;

// 商品名、商品コードラッパー
export const ListLeftHeadProductCell = styled.div<{
  invalid?: boolean;
  productIndex: number;
}>`
  box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.05);
  padding-left: 0.75rem;
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};
  cursor: pointer;
  background: ${(props) =>
    props.productIndex % 2 === 0
      ? props.theme.palette.white
      : props.theme.palette.paleYellow};
`;

export const ListTbody = styled.div`
  display: flex;
`;

export const ListLeftHeadName = styled.tr<{
  productIndex: number;
  typeIndex: number;
}>`
  height: 30px;
  > th {
    border-right: 1px solid #efefef;
    width: 145px;
  }
  > td {
    font-size: 15px;
    color: #6b675c;
    width: 100px;
    text-align: left;
    padding-left: 1.1rem;
    vertical-align: middle;
    border-bottom: 1px solid #efefef;
    background: ${(props) =>
      props.productIndex % 2 === 0
        ? props.typeIndex % 2 === 0
          ? props.theme.palette.white
          : props.theme.palette.paleYellow
        : props.typeIndex % 2 === 0
        ? props.theme.palette.paleYellow
        : props.theme.palette.white};
  }
  > th > span {
    font-size: 0.9rem;
    margin-left: 9.5px;

    :first-child {
      color: ${(props) => props.theme.palette.blue};
    }

    :last-child {
      font-size: 14px;
    }
  }
`;

export const ListLeftHeadMeta = styled.div`
  color: rgba(51, 51, 51, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
  > span {
    font-weight: 700;
  }
`;

export const CircularIconWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  background-color: ${(props) => props.theme.palette.beige} !important;
`;
