import { useState, useEffect } from 'react';
import moment from 'moment';
import { WithHeader } from '@components/templates/WithHeader';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import UpdatePopUp from '@components/modules/common/UpdatePopUp/UpdatePopUp';
import ProductProductionResultCopyModal from '@components/modules/pc/product_production_result/ProductProductionResultCopyModal/ProductProductionResultCopyModal';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import SemiProductionResultHeaderControl from '@components/modules/pc/semi_product_production_result/SemiProductProductionResultHeaderControl/SemiProductProductionResultHeaderControl';
import SemiProductProductionResultContents from '@components/modules/pc/semi_product_production_result/SemiProductProductionResultContents/SemiProductProductionResultContents';
import useDuplicateSemiProductProductionResultMutation from '@lib/pc/semi_product_production_result/useDuplicateSemiProductProductionResultMutation';
import useSemiProductProductionResults from '@lib/pc/semi_product_production_result/useSemiProductProductionResults';
import useSemiProductsProcesses from '@lib/pc/semi_product_production_result/useSemiProductsProcesses';
import useSemiProducts from '@lib/pc/semi_product_production_result/useSemiProducts';
import useCases from '@lib/pc/common/hooks/useCases';
import { PcScrollStyle } from '@lib/styled-components/globalStyle';
import { getTodayForInput } from '@lib/common/functions';
import { Factory } from '@lib/common/type';
import { PCPath } from '@config/path';

export const SemiProductProductionResultRoot = () => {
  const selectedFactoryAndLine = localStorage
    .getItem('semi_product_production_selected_line')
    ?.split(',');

  const today = new Date();
  const [date, setDate] = useState(new Date());
  const [sevenDaysBefore, setSevenDaysBefore] = useState(new Date());
  // コピーしたいデータの日付
  const [duplicateTargetDate, setDuplicateTargetDate] = useState(new Date());
  const [selectedType, setSelectedType] = useState('');
  const [popUp, setPopUp] = useState(false);
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [messageKind, setMessageKind] = useState('');
  const [editProductName, setEditProductName] = useState('');
  const [errMsg, setErrMsg] = useState('');

  const { semiProductionResults, refetch } =
    useSemiProductProductionResults(date);

  const { data: semiProducts } = useSemiProducts();
  const { data: semiProductsProcesses } = useSemiProductsProcesses();
  const { data: cases } = useCases();

  const [selectedFactoryId, setSelectedFactoryId] = useState(
    selectedFactoryAndLine
      ? selectedFactoryAndLine[0]
      : semiProductionResults?.factories[0]?.id
  );

  const [factoriesData] = useState(
    semiProductionResults?.semiProductionResults
  );

  const [selectedFactoryData, setSelectedFactoryData] = useState(
    semiProductionResults?.semiProductionResults[0]
  );
  const [selectedLineId, setSelectedLineId] = useState(
    semiProductionResults?.semiProductionResults[0]?.lines[0]?.id
  );
  // 選択ラインのIndex
  const [selectedLineIndex, setSelectedLine] = useState(0);
  // 商品選択時の1台車あたりのケース数
  const [casesPerCart, setCasesPerCart] = useState('');
  // 登録済みsemiProductIds
  const [registeredSemiProductIds, setRegisteredSemiProductIds] = useState<
    number[]
  >([]);

  // 1日戻る
  const getBeforeDate = (date: Date) => {
    setDate(moment(date).subtract(1, 'days').toDate());
    setSevenDaysBefore(moment(date).subtract(1, 'days').toDate());
    setPopUp(false);
  };

  // 1日進む
  const getNextDate = (date: Date) => {
    // 未来日も選択可能
    setDate(moment(date).add(1, 'days').toDate());
    setSevenDaysBefore(moment(date).add(1, 'days').toDate());
    setPopUp(false);
  };

  const handleClickToday = () => {
    getTodayForInput(date, today, setDate, refetch);
    // 1週間前日付の取得
    setSevenDaysBefore(moment(date).subtract(7, 'days').toDate());
  };

  const onClickLast = async () => {
    await setSelectedType('latest');
    duplicateSemiProductProductionResultMutation.mutate();
  };

  const onClickLastWeek = async () => {
    await setDuplicateTargetDate(sevenDaysBefore);
    await setSelectedType('last_week');
    duplicateSemiProductProductionResultMutation.mutate();
  };

  const onClickCalender = async () => {
    await setSelectedType('selected');
    duplicateSemiProductProductionResultMutation.mutate();
  };

  const handleDuplicateSuccess = () => {
    refetch();
    setSelectedType('');
    setCopyModalOpen(false);
  };

  // 完成品生産実績データ作成処理
  const duplicateSemiProductProductionResultMutation =
    useDuplicateSemiProductProductionResultMutation(
      date,
      selectedType,
      duplicateTargetDate,
      selectedLineId,
      handleDuplicateSuccess,
      setErrMsg
    );

  useEffect(() => {
    localStorage.setItem(
      'last_view_page',
      `${PCPath.semiProductProductionResult}`
    );
  }, []);

  useEffect(() => {
    // 1週間前日付の取得
    setSevenDaysBefore(moment(date).subtract(7, 'days').toDate());
  }, []);

  useEffect(() => {
    if (errMsg) {
      setCopyModalOpen(false);
    }
  }, [errMsg]);

  // localStorageに保存されたfactoryIdが存在しない場合、存在する最初のfactoryIdを設定する
  useEffect(() => {
    const ids = semiProductionResults?.factories.map(
      (item: Factory) => item['id']
    );
    if (ids && !ids.includes(Number(selectedFactoryId))) {
      setSelectedFactoryId(ids[0]);
    }
  }, [semiProductionResults?.semiProductionResults?.length]);

  if (!semiProductsProcesses || !cases) return null;

  return (
    <WithHeader>
      <PcScrollStyle />
      <SemiProductionResultHeaderControl
        semiProductionResults={semiProductionResults}
        factories={
          factoriesData
            ? factoriesData
            : semiProductionResults?.semiProductionResults
        }
        selectedFactoryId={selectedFactoryId}
        setSelectedFactoryId={setSelectedFactoryId}
        setSelectedFactoryData={setSelectedFactoryData}
        setSelectedLineId={setSelectedLineId}
        setSelectedLine={setSelectedLine}
        selectedDate={date}
        getBeforeDate={getBeforeDate}
        getNextDate={getNextDate}
        setPopUp={setPopUp}
        handleClickToday={handleClickToday}
        handleClickCopy={() => {
          setCopyModalOpen(!copyModalOpen);
          setPopUp(false);
        }}
        setDate={setDate}
      />
      <SemiProductProductionResultContents
        semiProductionResults={semiProductionResults}
        selectedFactoryId={selectedFactoryId}
        setSelectedFactoryId={setSelectedFactoryId}
        selectedFactoryData={
          selectedFactoryData
            ? selectedFactoryData
            : semiProductionResults?.semiProductionResults[0]
        }
        setSelectedFactoryData={setSelectedFactoryData}
        selectedLineId={
          selectedLineId
            ? selectedLineId
            : semiProductionResults?.semiProductionResults[0]?.lines[0]?.id
        }
        setSelectedLineId={setSelectedLineId}
        selectedLineIndex={selectedLineIndex}
        setSelectedLine={setSelectedLine}
        date={date}
        semiProducts={semiProducts}
        semiProductsProcesses={semiProductsProcesses}
        cases={cases}
        setPopUp={setPopUp}
        editProductName={editProductName}
        setEditProductName={setEditProductName}
        refetch={refetch}
        setMessageKind={setMessageKind}
        casesPerCart={casesPerCart}
        setCasesPerCart={setCasesPerCart}
        registeredSemiProductIds={registeredSemiProductIds}
        setRegisteredSemiProductIds={setRegisteredSemiProductIds}
      />
      <UpdatePopUp
        popUp={popUp}
        handleClose={() => setPopUp(false)}
        fromPc={true}
        taskKind={'productionResult'}
        messageKind={messageKind}
        productName={editProductName}
      />
      <ProductProductionResultCopyModal
        copyModalOpen={copyModalOpen}
        onClickLast={onClickLast}
        onClickLastWeek={onClickLastWeek}
        onClickCalender={onClickCalender}
        sevenDaysBefore={sevenDaysBefore}
        date={date}
        setDuplicateTargetDate={setDuplicateTargetDate}
      />
      {copyModalOpen && (
        <Overlay dark handleClick={() => setCopyModalOpen(false)} />
      )}
      {errMsg && (
        <ErrorMsgPopUp
          errMsg={errMsg}
          handleClose={() => setErrMsg('')}
          fromPc={true}
        />
      )}
    </WithHeader>
  );
};
