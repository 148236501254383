import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: calc(100vh - 143px);
  overflow: auto;
`;

// 商品の絞り込み
export const ListLeftHeadCell = styled.span<{
  invalid?: boolean;
  isBlue?: boolean;
}>`
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};
  cursor: pointer !important;
  justify-content: space-between;
  color: ${(props) => (props.isBlue ? props.theme.palette.blue : '#9ca3af')};
  > span {
    vertical-align: middle;
    > svg {
      display: inline;
      width: 24px;
      height: 24px;
      margin: auto;
    }
    .filter_text {
    }
  }

  :nth-child(2) {
    > div > input {
      cursor: pointer;
    }
  }
`;

export const HeaderName = styled.div<{ isBlue: boolean }>`
  color: ${(props) => (props.isBlue ? props.theme.palette.blue : '')};
  width: 280px;
  display: flex;
  cursor: pointer;
`;

export const CircularIconWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Table = styled.table`
  border: 0;
  width: 100vw;
  margin-bottom: 2.15rem;
`;

export const TableHead = styled.th`
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 5px;
`;

export const TableRow = styled.tr<{
  maxWidth?: string;
}>`
  border: 1px solid #e5e1dd;
  cursor: pointer;
  width: 100vw;

  &.header {
    border: 0;
    background-color: #f8f7f4;
    cursor: default;
    position: sticky;
    top: 0;
  }
  &.hover-effect {
    &:hover {
      box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
      border: solid 1px #e5e1dd;
    }
  }
  &.remarks-popup {
    width: 100vw;
    vertical-align: middle;
    text-align: left;
  }
  &.remarks-popup-hidden {
    display: none;
  }
  td {
    &.remarks {
      padding: 10px 10px 10px 30px;
      word-break: break-all;
      max-width: ${(props) => (props.maxWidth ? props.maxWidth : '1340px')};
    }
    div {
      &.remarks-ellipsis {
        max-width: 10ch;
        max-height: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  img,
  svg {
    height: 40px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  &.hover-effect {
    &:hover {
      box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
      border: solid 1px #e5e1dd;
    }
  }
`;

export const TableColumn = styled.td`
  padding-left: 10px;
  vertical-align: middle;
  text-align: left;
  .product-image {
    width: 40px;
    object-fit: contain;
  }
`;
