import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import * as S from './RightSlideSidebar.styles';
import { Button } from '@components/elements/buttons/Button';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import PickingByShippingChangeSelect from '@components/modules/pc/picking_by_shipping/picking_by_shipping_detail/PickingByShippingChangeSelect/PickingByShippingChangeSelect';
import type { Todo } from '@lib/common/type';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import ShippingStopModal from '@components/modules/pc/picking_by_shipping/picking_by_shipping_detail/ShippingStopModal/ShippingStopModal';
import ErrorMsgPopUpForStock from '@components/modules/pc/stock_status/ErrorMsgPopUpForStock/ErrorMsgPopUpForStock';
import { selectedItem } from '@lib/pc/picking_by_shipping/type';
import { pickingStatus } from '@lib/pc/picking/constants';

type Props = {
  popUp: boolean;
  setPopUp: (popUp: boolean) => void;
  handleCloseSidebar: (date: Date) => void;
  refetch: () => void;
  selectedEditItem: selectedItem;
  data: Todo;
  setSelectedProduct: (selectedPlace: string) => void;
  setSelectedPlace: (selectedPlace: string) => void;
  handleStopShipping: () => void;
  openStopModal: boolean;
  setOpenStopModal: (openStopModal: boolean) => void;
  changeAmount: number | string;
  setChangeAmount: (changeAmount: number | string) => void;
  handleUpdateProductAmount: () => void;
};

const RightSlideSidebar: FC<Props> = ({
  popUp,
  setPopUp,
  handleCloseSidebar,
  selectedEditItem,
  data,
  setSelectedProduct,
  setSelectedPlace,
  handleStopShipping,
  openStopModal,
  setOpenStopModal,
  changeAmount,
  setChangeAmount,
  handleUpdateProductAmount,
}: Props) => {
  const today = new Date(data?.shippingDate);
  today.setHours(0, 0, 0, 0);
  const day = today;

  const [isChanged, setIsChanged] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [selectedId, setSelectedId] = useState(0);

  const handleChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setChangeAmount(value);
    setIsChanged(true);
  };

  const handleSubmit = () => {
    if (!isChanged) {
      setErrMsg('変更するデータはありません。');
    } else if (selectedId === 1) {
      // 個数を変更する処理
      handleUpdateProductAmount();
    } else if (selectedId === 2 || selectedId === 3) {
      // 出荷止または元に戻す処理
      setOpenStopModal(!openStopModal);
    }
  };

  const onClickCancel = () => {
    if (isChanged) {
      setPopUp(false);
      setConfirmMsg('保存せずに編集を終了します。よろしいですか。');
    } else {
      setPopUp(false);
    }
  };

  const handleOk = () => {
    handleCloseSidebar(day);
    setIsChanged(false);
    setConfirmMsg('');
    setChangeAmount('');
  };

  const handleCancel = () => {
    setConfirmMsg('');
  };

  const formattedDate = moment(day).format('YYYY/MM/DD (ddd)');

  const changes = [
    { id: 1, name: 'ピッキング数を変更する' },
    { id: 2, name: '出荷止にする' },
  ];

  const undoChanges = [
    { id: 1, name: 'ピッキング数を変更する' },
    { id: 3, name: '出荷止を元に戻す' },
  ];

  const withoutStopShippingChanges = [
    { id: 1, name: 'ピッキング数を変更する' },
  ];

  useEffect(() => {
    setChangeAmount(selectedEditItem.total);
  }, []);

  return (
    <>
      <S.Wrapper>
        <S.ScrollableContainer>
          <S.HeadContainer>
            <S.HeadDate>{formattedDate}</S.HeadDate>
            <S.StatusBar
              color={data && pickingStatus[data?.pickingStatus].statusBarColor}
              bgColor={
                data && pickingStatus[data?.pickingStatus].statusBarBgColor
              }
              borderColor={
                data && pickingStatus[data?.pickingStatus].statusBorderColor
              }
            >
              {data && pickingStatus[data?.pickingStatus].label}
            </S.StatusBar>
          </S.HeadContainer>

          <S.PickingInfoWrapper>
            <S.ProductContainer>
              <div className="title-name">商品</div>
              <div className="name">
                {selectedEditItem && selectedEditItem.productName}
              </div>
            </S.ProductContainer>
            <S.ProductContainer>
              <div className="title">出荷先</div>
              <div className="name">
                {selectedEditItem && selectedEditItem.shippingName}
              </div>
            </S.ProductContainer>
          </S.PickingInfoWrapper>
          <>
            <S.ChangeContainer>
              <S.ChangeLabel>変更内容</S.ChangeLabel>
              <PickingByShippingChangeSelect
                changes={
                  !(Number(selectedEditItem.total) > 0)
                    ? withoutStopShippingChanges
                    : selectedEditItem.stopShipping
                    ? undoChanges
                    : changes
                }
                value={selectedId}
                setSelectedId={setSelectedId}
                setIsChanged={setIsChanged}
              />

              {selectedId === 1 && (
                <S.ChangeAmountWrapper>
                  <S.ChangeLabel>個数</S.ChangeLabel>
                  <S.ChangeAmountInput
                    onChange={handleChangeAmount}
                    value={changeAmount || ''}
                    type="number"
                    inputMode="numeric"
                    autoComplete="off"
                    id="productAmountInput"
                  />
                </S.ChangeAmountWrapper>
              )}
            </S.ChangeContainer>

            <S.ButtonContainer invalid={confirmMsg || errMsg ? true : false}>
              <Button
                borderWidth={1}
                outlined={true}
                onClick={() => {
                  isChanged ? onClickCancel() : handleCloseSidebar(day);
                }}
              >
                キャンセル
              </Button>
              {selectedEditItem && (
                <Button borderWidth={1} onClick={handleSubmit}>
                  変更
                </Button>
              )}
            </S.ButtonContainer>
            <S.CheckBoxAndButtonContainer></S.CheckBoxAndButtonContainer>
            <ErrorMsgPopUpForStock
              errMsg={errMsg}
              handleClose={() => setErrMsg('')}
              fromPc={true}
              width={'20rem'}
            />
          </>
          <ConfirmPopUp
            fromPc={true}
            confirmMsg={confirmMsg}
            width={'20rem'}
            handleOk={handleOk}
            handleCancel={handleCancel}
          />
          {openStopModal && (
            <ShippingStopModal
              selectedProduct={selectedEditItem.productName}
              selectedPlace={selectedEditItem.shippingName}
              shippingStop={selectedId === 3 ? true : false}
              handleCancel={() => {
                setOpenStopModal(false);
                setSelectedProduct('');
                setSelectedPlace('');
              }}
              handleOk={() => handleStopShipping()}
            />
          )}
        </S.ScrollableContainer>
      </S.Wrapper>
      {popUp && (
        <Overlay
          handleClick={() => {
            setPopUp(false);
          }}
        />
      )}
    </>
  );
};

export default RightSlideSidebar;
