import { Heading } from '@components/elements/typographies/Heading';
import { WithHeader } from '@components/templates/WithHeader';
import { FC, useCallback, useState, useEffect } from 'react';
import * as S from './SemiProductionSchedule.styles';
import RightSlideSidebar from '@components/modules/pc/semi_production_schedule/RightSlideSidebar/RightSlideSidebar';
import TableWithFixedSidebar from '@components/modules/pc/semi_production_schedule/TableWithFixedSidebar/TableWithFixedSidebar';
import ProductDetailModal from '@components/modules/pc/semi_production_schedule/ProductDetailModal/ProductDetailModal';
import moment from 'moment';
import { useSemiProductionSchedules } from '@lib/pc/production_schedule/hooks';
import UpdatePopUp from '@components/modules/common/UpdatePopUp/UpdatePopUp';
import type { SelectedProduct } from '@lib/pc/production_schedule/type';
import ArrowRight from '@assets/icons/arrow_right_24px';
import { ReactComponent as ArrowLeft } from '@assets/icons/arrow_left_24px.svg';
import { ReactComponent as BrainIcon } from '@assets/icons/brain.svg';
import { Button } from '@components/elements/buttons/Button';
import { getTodayForReference } from '@lib/common/functions';
import { PRODUCT_KIND } from '@lib/common/type';
// import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import useFactoryLines from '@lib/pc/common/hooks/useFactoryLines';
import FactoryLineSelect from '@components/modules/pc/production_schedule/FactoryLineSelect/FactoryLineSelect';
import { useDeviceOrientationAndSize } from '@lib/common/functions';
import { PCPath } from '@config/path';

export const SemiProductionScheduleRoot: FC = () => {
  const today = moment();
  const [date, setDate] = useState(moment());
  const [productionDate, setProductionDate] = useState<Date>();
  const [selectedProduct, setSelectedProduct] =
    useState<SelectedProduct | null>(null);
  const [productDetailId, setProductDetailId] = useState<number | null>(null);
  const [selectedId, setSelectedId] = useState(false);
  const [productName, setProductName] = useState('');
  const [productSafetyStockDays, setProductSafetyStockDays] = useState('');
  const [popUp, setPopUp] = useState(false);
  const [selectedCell, setSelectedCell] = useState('');
  const [selectIndex, setSelectIndex] = useState<number>(
    new Date().getDate() - 1
  );
  const [selectedFactoryLineId, setSelectedFactoryLineId] = useState(0);
  const [selectedFactoryLineName, setSelectedFactoryLineName] =
    useState('全てのライン');

  const formattedDate = moment(productionDate).format('YYYY/MM/DD (ddd)');
  const { deviceType } = useDeviceOrientationAndSize();
  const isTablet = deviceType === 'tablet';

  const { data: factoryLines } = useFactoryLines(PRODUCT_KIND.SEMI_PRODUCT);

  const { semiProductionSchedules, refetch } = useSemiProductionSchedules(
    moment(date),
    selectedFactoryLineId
  );

  const handleSelectedProduct = useCallback(
    (product: SelectedProduct, name: string, indexId: string) => {
      setSelectedProduct(product);
      setProductName(name);
      setPopUp(false);
      setSelectedCell(indexId);
    },
    []
  );

  const handleCloseSidebar = useCallback((date: Date) => {
    setProductionDate(date);
    setSelectedProduct(null);
  }, []);

  const handleOpenProductDetail = useCallback(
    (id: number, name: string, safetyStockDays: string) => {
      setProductDetailId(id);
      setSelectedId(true);
      setProductName(name);
      setProductSafetyStockDays(safetyStockDays);
      setPopUp(false);
    },
    []
  );

  const handleCloseProductDetail = useCallback(() => {
    setProductDetailId(null);
    setSelectedId(false);
    setProductName('');
  }, []);

  const handleClosePopUp = () => {
    setPopUp(false);
  };

  const getMonth = (num: number) => {
    setDate(moment(date).add(num, 'months').startOf('month'));
  };

  const handleClick = () => {
    getTodayForReference(
      date.format('YYYY-MM'),
      today.format('YYYY-MM'),
      setDate,
      setSelectIndex,
      refetch
    );
  };

  useEffect(() => {
    localStorage.setItem('last_view_page', `${PCPath.semiProductionSchedule}`);
  }, []);

  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <S.PageTitle>
              <S.ProductKind>半製品</S.ProductKind>
              <S.PageTitleText>
                <Heading tag="span" text="生産計画" fs="32" fw="lg" />
                <Heading tag="span" text="を確認" fs="24" fw="lg" />
              </S.PageTitleText>
            </S.PageTitle>
            <S.DateWrapper>
              <S.SelectWrapper>
                <FactoryLineSelect
                  data={factoryLines}
                  value={selectedFactoryLineId}
                  setSelectedId={setSelectedFactoryLineId}
                  height={'42px'}
                  width={isTablet ? '148px' : '180px'}
                  searchable={true}
                  selectedFactoryLineName={selectedFactoryLineName}
                  setSelectedFactoryLineName={setSelectedFactoryLineName}
                />
              </S.SelectWrapper>
              <Button
                width={isTablet ? '70px' : '90px'}
                margin={'0 3px 0 0'}
                outlined={true}
                borderWidth={1}
                onClick={handleClick}
              >
                今日
              </Button>
              <S.ArrowLeft selected={false} onClick={() => getMonth(-1)}>
                <ArrowLeft />
                <span className="circle"></span>
              </S.ArrowLeft>
              <S.Date>
                {moment(date).format('YYYY')}
                <span>年</span>
                {moment(date).format('MM')}
                <span>月</span>
              </S.Date>
              <S.ArrowRight selected={false} onClick={() => getMonth(1)}>
                <ArrowRight isActive={true} />
              </S.ArrowRight>
              <S.ButtonContainer>
                <S.calcButton
                  onClick={() => console.log('button')}
                  updateCount={false}
                  // onClick={() =>
                  //   updateCount >= 1 && setReCalculation(!reCalculation)
                  // }
                  // updateCount={updateCount >= 1}
                >
                  <BrainIcon />
                  <span className="tooltip">再計算</span>
                  {/* <S.hoverSpan>再計算</S.hoverSpan> */}
                </S.calcButton>
              </S.ButtonContainer>
            </S.DateWrapper>
          </S.HeadContainer>
        </div>
        <div>
          <TableWithFixedSidebar
            selectedDate={date}
            data={semiProductionSchedules}
            selected={selectedProduct}
            selectedCell={selectedCell}
            handleSelectedProduct={handleSelectedProduct}
            handleOpenProductDetail={handleOpenProductDetail}
            selectIndex={selectIndex}
            setSelectIndex={setSelectIndex}
          />
          <RightSlideSidebar
            handleCloseSidebar={handleCloseSidebar}
            selectedProduct={selectedProduct}
            popUp={popUp}
            setPopUp={setPopUp}
            refetch={refetch}
          />
        </div>
        {productDetailId && (
          <ProductDetailModal
            selectedDate={date}
            id={productDetailId}
            productName={productName}
            productSafetyStockDays={productSafetyStockDays}
            selectedId={selectedId}
            handleClose={handleCloseProductDetail}
          />
        )}
        <UpdatePopUp
          popUp={popUp}
          formattedDate={formattedDate}
          productName={productName}
          handleClose={handleClosePopUp}
          fromPc={true}
          taskKind={'production_schedule'}
        />
        {/* {selectedProduct && (
          <Overlay handleClick={() => setSelectedProduct(null)} />
        )} */}
      </S.Wrapper>
    </WithHeader>
  );
};
