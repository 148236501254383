import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.white};
  position: relative;
  height: calc(100vh - 64px);
  background-color: #f8f7f4;
`;

export const HeadContainer = styled.div`
  height: 86px;
  padding: 1.15rem 2.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f5f2e6;
  background-color: ${(props) => props.theme.palette.white};
`;

export const PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PageTitleText = styled.div`
  display: flex;
  align-items: baseline;
  padding-bottom: 4px;
`;

export const DateFilter = styled.div<{
  invalid?: boolean;
  isBlue?: boolean;
}>`
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};
  min-height: 42px;
  width: 230px;
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.yellowGray};
  position: -webkit-sticky; /* for Safari */
  -webkit-transform: translateZ(0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 2px;
  gap: 0.25rem;
  display: flex;
  position: relative;
  margin-left: 12px;

  > span {
    font-size: 0.75rem;
    &.filter-product {
      width: 90%;
      padding-left: 36px;
      height: 24px;
      line-height: 24px;
      position: absolute;
      background: ${(props) => props.theme.palette.white};
      color: ${(props) =>
        props.isBlue ? props.theme.palette.blue : '#9ca3af'};
      border-radius: 0.5rem;
      cursor: pointer;

      > .filter_text {
        font-size: 13px;
        line-height: 16px;
        padding-left: 18px;
        text-align: left;
        position: absolute;
        top: -8%;
        color: ${(props) => props.theme.palette.black};
      }

      > .filter_text_false {
        position: absolute;
        top: -8%;
        font-size: 13px;
        line-height: 16px;
        padding-left: 8px;
        color: ${(props) => props.theme.palette.black};
      }
    }
  }

  :first-child {
    > div > input {
      cursor: pointer;
      height: 32px;
      padding: 0.5rem 40px 0.5rem;
    }
  }
`;

export const SearchFilterIconContainer = styled.div`
  position: absolute;
  top: 12%;
  left: 5%;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  bottom: 0;
  right: 0;
  left: 0;
  height: 83px;
  margin-right: 20px;
`;

export const ButtonContainer = styled.div<{
  padding?: string;
  width?: string;
  buttonType?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : '100px')};
  height: 45px;
  padding: ${(props) => (props.padding ? props.padding : '')};
  border-radius: 5px;
  color: ${(props) =>
    props.buttonType === 'blue'
      ? props.theme.palette.white
      : props.buttonType === 'delete'
      ? props.theme.palette.error
      : props.theme.palette.primary.main};
  background: ${(props) =>
    props.buttonType === 'delete'
      ? props.theme.palette.white
      : props.buttonType === 'blue'
      ? props.theme.palette.primary.main
      : props.theme.palette.white};
  border: ${(props) =>
    `${
      props.buttonType === 'blue'
        ? props.theme.palette.primary.main
        : props.buttonType === 'delete'
        ? props.theme.palette.error
        : props.theme.palette.primary.main
    } 1px solid`};
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
`;

export const HeaderRightContainer = styled.div`
  display: flex;
`;

export const DetailConditionText = styled.div`
  font-size: 13px;
  color: ${(props) => props.theme.palette.black};
`;

export const MainContent = styled.div`
  display: flex;
  overflow: scroll;
  height: calc(100vh - 139px);
`;

export const PurchaseOrderFiltering = styled.div<{
  invalid?: boolean;
  isBlue?: boolean;
}>`
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};
  cursor: pointer;
  height: 45px;
  width: 250px;
  border: 1px solid ${(props) => props.theme.palette.yellowGray};
  /* border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
  border-left: 1px solid ${(props) => props.theme.palette.yellowGray};
  border-bottom: 1px solid ${(props) => props.theme.palette.yellowGray}; */
  /* box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.05); */
  position: -webkit-sticky; /* for Safari */
  -webkit-transform: translateZ(0);
  /* display: flex; */
  justify-content: space-between;
  padding: 6px 0.75rem;
  gap: 0.25rem;

  display: flex;
  flex-direction: column;

  cursor: pointer;

  /* :first-child {
    display: grid;
    place-items: center;
  } */

  > span {
    font-size: 0.75rem;
    &.filter-product {
      width: 90%;
      padding-left: 30px;
      height: 32px;
      line-height: 32px;
      position: absolute;
      background: ${(props) => props.theme.palette.white};
      color: ${(props) =>
        props.isBlue ? props.theme.palette.blue : '#9ca3af'};
      border-radius: 0.5rem;
      cursor: pointer;

      > svg {
        position: absolute;
        top: 12%;
        left: 5%;
      }

      > div {
        padding-left: 15px;
        font-size: 16px;
      }
    }
  }
`;

export const CenterColumn = styled.div`
  width: 60%;
  align-self: stretch;
  overflow-y: scroll;
`;

export const OperationPanel = styled.div`
  padding: 2rem 2rem 0 2rem;
`;

export const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  /* justify-content: center; */
  gap: 1rem;
  padding: 1rem;
  background-color: white;
`;

export const PdfView = styled.div`
  /* background: #f8f7f4; */
  /* background: #e7e2dd; */
  width: 100%;
  padding: 0.5rem;
`;

export const PurchaseOrderItem = styled.div<{
  index: number;
  selectedIndex: boolean;
}>`
  /* height: 78px; */
  /* height: 52px; */
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 6px 0.75rem;
  border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
  border-left: 1px solid ${(props) => props.theme.palette.yellowGray};
  border-bottom: 1px solid ${(props) => props.theme.palette.yellowGray};
  background: ${(props) => (props.index % 2 === 0 ? '#fff' : '#fffef5')};
  border-left: ${(props) =>
    props.selectedIndex
      ? `3px solid  ${props.theme.palette.primary.main}`
      : ''};
  cursor: pointer;
  :first-child {
    border-top: 1px solid #f5f2e6;
  }
`;

export const PurchaseOrderItemRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PurchaseOrderItemDate = styled.div`
  font-size: 0.75rem;
`;

export const KindLabel = styled.div`
  font-size: 0.75rem;
  padding: 2px 6px;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.main};
  white-space: nowrap;
  height: 18px;
  font-weight: bold;
`;

export const FaxFailedLabel = styled.div`
  font-size: 0.75rem;
  padding: 2px 6px;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.palette.error};
  color: ${(props) => props.theme.palette.error};
  white-space: nowrap;
  height: 18px;
  font-weight: bold;
  margin-right: 8px;
`;

export const KindLabelFill = styled(KindLabel)`
  background: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.white};
`;

export const PurchaseOrderItemName = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.palette.primary.main};
`;

export const RightSide = styled.div`
  width: 20%;
  /* display: flex; */
  /* flex-direction: column; */
  gap: 0.5rem;
  padding: 0.5rem 0.5rem 0.5rem 0;
  overflow-y: scroll;
`;

export const DetailList = styled.div`
  padding: 1rem 0.5rem;
  height: 150px;
  background-color: ${(props) => props.theme.palette.white};
  overflow-y: scroll;
`;

export const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 5px;
`;

export const DetailItemText = styled.div`
  line-height: 1.2;
  font-size: 14px;
`;

export const SearchConditionNameList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  width: 100%;
`;

export const FilterIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const SearchConditionLabel = styled.div`
  max-width: 188px;
  font-size: 0.75rem;
  padding: 2px 6px;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.main};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 18px;
`;

export const TelWrapper = styled.div`
  text-align: end;
  padding-right: 1rem;
  background-color: ${(props) => props.theme.palette.white};
`;

export const OperationPanelUpper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: ${(props) => props.theme.palette.white};
`;
export const OperationPanelDestName = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;
export const OrderAndFaxStatus = styled.div`
  display: flex;
  justify-content: end;
  width: 140px;
`;
