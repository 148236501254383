import { FC, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import * as S from './OrderSummary.styles';
import ArrowRight from '@assets/icons/arrow_right_24px';
import { ReactComponent as ArrowLeft } from '@assets/icons/arrow_left_24px.svg';
import { Button } from '@components/elements/buttons/Button';
import { Heading } from '@components/elements/typographies/Heading';
import { WithHeader } from '@components/templates/WithHeader';
import RightSlideSidebar from '@components/modules/pc/order_summary/RightSlideSidebar/RightSlideSidebar';
import CustomerSelect from '@components/modules/pc/order_summary/CustomerSelect/CustomerSelect';
import TableWithFixedSidebar from '@components/modules/pc/order_summary/TableWithFixedSidebar/TableWithFixedSidebar';
import { Todo } from '@lib/common/type';
import { SelectedProductsOrder } from '@lib/pc/order_summary/type';
import { getTodayForReference } from '@lib/common/functions';
import useCustomers from '@lib/pc/common/hooks/useCustomers';
import useOrderSummaries from '@lib/pc/order_summary/useOrderSummaries';
import { PCPath } from '@config/path';

export const OrderSummaryRoot: FC = () => {
  const [selectedProductsOrder, setSelectedProductsOrder] =
    useState<SelectedProductsOrder | null>(null);
  const [rightSlideBarOpen, setRightSlideBarOpen] = useState(false);

  // 得意先の取得
  const { data: customers } = useCustomers();

  const handleCloseSidebar = useCallback(() => {
    setRightSlideBarOpen(false);
    setSelectedProductsOrder(null);
  }, []);

  const today = moment();
  const [date, setDate] = useState<Todo>(moment());
  const [selectIndex, setSelectIndex] = useState<number>(
    new Date().getDate() - 1
  );

  const [selectedProductId, setSelectedProductId] = useState(0);
  const [selectedCustomerId, setSelectedCustomerId] = useState(0);

  const [focusedCellIndex, setFocusedCellIndex] = useState<number | null>(null);
  const [focusedRowIndex, setFocusedRowIndex] = useState<number | null>(null);
  const [selectedDate, setSelectedDate] = useState('');

  const { orderSummaries, refetch } = useOrderSummaries(
    moment(date).toDate(),
    selectedCustomerId
  );

  const getMonth = (num: number) => {
    setDate(moment(date).add(num, 'months').startOf('month'));
  };

  const handleClickToday = () => {
    setFocusedCellIndex(null);
    setFocusedRowIndex(null);
    getTodayForReference(
      date.format('YYYY-MM'),
      today.format('YYYY-MM'),
      setDate,
      setSelectIndex,
      refetch
    );
  };

  useEffect(() => {
    refetch();
  }, [selectedCustomerId, date]);

  useEffect(() => {
    localStorage.setItem('last_view_page', `${PCPath.orderSummary}`);
  }, []);

  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <S.PageTitle>
              <S.PageTitleText>
                <Heading tag="span" text="受注状況" fs="32" fw="lg" />
                <Heading tag="span" text="を確認" fs="24" fw="lg" />
              </S.PageTitleText>
            </S.PageTitle>

            <S.DateWrapper>
              <CustomerSelect
                options={customers && customers}
                value={selectedCustomerId}
                onChange={setSelectedCustomerId}
                searchable={true}
              />
              <Button
                width="90px"
                margin={'0 8px 0 0'}
                outlined={true}
                borderWidth={1}
                onClick={handleClickToday}
              >
                今日
              </Button>
              <S.ArrowLeft selected={false} onClick={() => getMonth(-1)}>
                <ArrowLeft />
                <span className="circle"></span>
              </S.ArrowLeft>
              <S.Date>
                {moment(date).format('YYYY')}
                <span>年</span>
                {moment(date).format('MM')}
                <span>月</span>
              </S.Date>
              <S.ArrowRight selected={false} onClick={() => getMonth(1)}>
                <ArrowRight isActive={true} />
              </S.ArrowRight>
            </S.DateWrapper>
          </S.HeadContainer>
        </div>
        <div>
          {/* 商品の絞り込みなどの縦列 */}
          <TableWithFixedSidebar
            date={date}
            data={orderSummaries}
            rightSlideBarOpen={rightSlideBarOpen}
            selectedProductsOrder={selectedProductsOrder}
            setRightSlideBarOpen={setRightSlideBarOpen}
            selectIndex={selectIndex}
            selectedProductId={selectedProductId}
            setSelectedProductId={setSelectedProductId}
            setSelectedCustomerId={setSelectedCustomerId}
            focusedCellIndex={focusedCellIndex}
            setFocusedCellIndex={setFocusedCellIndex}
            focusedRowIndex={focusedRowIndex}
            setFocusedRowIndex={setFocusedRowIndex}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
          {rightSlideBarOpen && (
            <RightSlideSidebar
              selectedDate={selectedDate}
              rightSlideBarOpen={rightSlideBarOpen}
              handleCloseSidebar={handleCloseSidebar}
              selectedProductsOrder={selectedProductsOrder}
              selectedProductId={selectedProductId}
              selectedCustomerId={selectedCustomerId}
            />
          )}
        </div>
      </S.Wrapper>
    </WithHeader>
  );
};
