import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.white};
  position: relative;
`;

export const HeadContainer = styled.div`
  padding: 1.3rem 1.15rem 1.3rem 2.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProductKind = styled.span`
  padding: 5px 10px 7px;
  width: 80px;
  height: 32px;
  background: ${(props) => props.theme.palette.white};
  color: ${(props) => props.theme.palette.blue};
  border: 1px solid ${(props) => props.theme.palette.blue};
  font-size: 19px;
  margin-right: 16px;
  margin-top: 6px;
  border-radius: 2px;
  vertical-align: super;
`;

export const DateWrapper = styled.div`
  display: flex;
`;

export const ArrowLeft = styled.div`
  align-items: baseline;
  cursor: pointer;
  width: 36px;
  height: 36px;
`;

export const ArrowRight = styled.div<{
  isActive?: boolean;
}>`
  align-items: baseline;
  cursor: ${(props) => (props.isActive ? 'pointer' : '')};
`;

export const Date = styled.div`
  font-size: 24px;
  font-weight: 700;
  align-items: baseline;
  padding: 4px 4px 0 5px;
`;
