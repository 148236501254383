import LABEL_OF_AMOUNT_MAP from '@components/modules/common/LabelOfAmountMap/LabelOfAmountMap';
import * as S from './CommonConstituentPartsModal.styles';
import { Button } from '@components/elements/buttons/Button';
import CommonConstituentPartsSelect from '@components/modules/pc/settings/product_master_setting/ProductMasterEdit/CommonConstituentPartsSelect/CommonConstituentPartsSelect';
import { Todo } from '@lib/common/type';
import { SemiProduct } from '@lib/pc/settings/product_master_setting/type';
import {
  MATERIAL,
  PRODUCT,
  SEMI_PRODUCT,
} from '@lib/pc/settings/product_master_edit/type';

type ProcessData = {
  id: number;
  name: string;
};

type Props = {
  modalActiveFrom: string;
  processName: string;
  pieces: Todo;
  flitteredOptions: Todo;
  selectedId: number;
  selectedLabelOfAmount: string;
  newProcessData: ProcessData;
  selectedProcessId: number;
  editItem: Todo;
  onClickAddButton: (editItem: SemiProduct) => void;
  handleCancel: () => void;
  handleChangeSelect: (e: number, s: string, labelOfAmount: string) => void;
  handleChangePieces: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const CommonConstituentPartsModal = ({
  modalActiveFrom,
  processName,
  pieces,
  flitteredOptions,
  selectedId,
  selectedLabelOfAmount,
  newProcessData,
  selectedProcessId,
  editItem,
  onClickAddButton,
  handleCancel,
  handleChangeSelect,
  handleChangePieces,
}: Props) => {
  const ConstituentName =
    modalActiveFrom === PRODUCT
      ? '完成品'
      : modalActiveFrom === SEMI_PRODUCT
      ? '半製品'
      : modalActiveFrom === MATERIAL
      ? '資材'
      : '原材料';

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        <S.Content>
          <S.FlexBaseline>
            <S.ProcessName>{processName}</S.ProcessName>
          </S.FlexBaseline>

          <div className="piece-breakdown-list-header">
            <dl className="piece-breakdown-list-header__inputs">
              <div className="product-name">
                <dt>{ConstituentName}名</dt>
                <dd>
                  <CommonConstituentPartsSelect
                    width={'100%'}
                    options={flitteredOptions}
                    value={selectedId}
                    disabled={
                      newProcessData?.name === '' && selectedProcessId === 0
                    }
                    onChange={handleChangeSelect}
                  />
                </dd>
              </div>
              <div className="product-number-wrapper">
                <div className="product-number">
                  <dt>数量</dt>
                  <dd>
                    <S.Input
                      type="number"
                      inputMode="numeric"
                      name="pieces"
                      id="pieces"
                      autoComplete="off"
                      align="left"
                      padding="0 5px"
                      value={pieces ? pieces : ''}
                      onChange={handleChangePieces}
                      disabled={
                        newProcessData?.name === '' && selectedProcessId === 0
                      }
                    />
                  </dd>
                </div>
              </div>
              <div className="label-of-amount">
                <dt>単位</dt>
                <span>
                  {(modalActiveFrom === PRODUCT ||
                    modalActiveFrom === SEMI_PRODUCT) &&
                  selectedLabelOfAmount
                    ? LABEL_OF_AMOUNT_MAP[selectedLabelOfAmount]
                    : selectedLabelOfAmount
                    ? selectedLabelOfAmount
                    : ''}
                </span>
              </div>
            </dl>
          </div>
          <S.ButtonContainer>
            <Button borderWidth={1} outlined={true} onClick={handleCancel}>
              キャンセル
            </Button>
            <Button
              borderWidth={1}
              onClick={() => {
                onClickAddButton(editItem);
                handleCancel();
              }}
            >
              追加
            </Button>
          </S.ButtonContainer>
        </S.Content>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default CommonConstituentPartsModal;
