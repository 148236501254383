import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { MaterialAllocationEditData } from '@lib/pc/material_allocation/type';
import { ErrorResponse } from '@lib/common/type';
import { AxiosError } from 'axios';

const useUpdateMaterialStockAllocationMutation = (
  id: number,
  handleUpdateSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: MaterialAllocationEditData) => {
      return await axiosInstance().patch(
        `/api/v1/material_stock_allocation_details/${id}`,
        humps.decamelizeKeys({ materialStockAllocation: params })
      );
    },
    {
      onSuccess: () => {
        handleUpdateSuccess();
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
      },
    }
  );
};

export default useUpdateMaterialStockAllocationMutation;
