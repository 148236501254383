import { useEffect } from 'react';
import * as S from './SalesStoreMasterRoot.styles';
import { WithHeader } from '@components/templates/WithHeader';
import SalesStoreHeaderControl from '@components/modules/pc/settings/sales_store_setting/SalesStoreHeaderControl/SalesStoreHeaderControl';
import TableWithFilter from '@components/modules/pc/settings/sales_store_setting/TableWithFilter/TableWithFilter';
import useCustomers from '@lib/pc/settings/customer_setting/useCustomers';
import useAllSalesStores from '@lib/pc/settings/sales_store_master/useSalesStores';
import { PCPath } from '@config/path';

export const SalesStoreMasterRoot = () => {
  const { customers: customers } = useCustomers();
  const { allSalesStores } = useAllSalesStores();

  useEffect(() => {
    localStorage.setItem('last_view_page', `${PCPath.salesStoreMaster}`);
  }, []);

  return (
    <WithHeader>
      {/* 販売店管理 */}
      <S.Wrapper>
        <SalesStoreHeaderControl />
      </S.Wrapper>
      {customers && <TableWithFilter salesStores={allSalesStores} />}
    </WithHeader>
  );
};
