import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 80%;
  height: 12rem;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  z-index: 9999;
  padding: 1.15rem 2.25rem;
  background: ${(props) => props.theme.palette.white};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
  background-color: ${(props) => props.theme.palette.beige};
  /* 個数紐付けリスト ヘッダー 半製品入力エリア*/
  .piece-breakdown-list-header {
    margin-top: 24px;
    display: grid;
    grid-template-areas: 'piece-breakdown-list-header__inputs';
    align-items: center;
    &__inputs {
      grid-template-areas: 'product-name product-number-wrapper label-of-amount';
      grid-area: piece-breakdown-list-header__inputs;
      display: grid;
      grid-template-columns: auto 230px 0px 80px;
      .product-name {
        grid-area: product-name;
        display: grid;
        dt {
          font-weight: 700;
          margin-bottom: 8px;
        }
      }
    }
    .product-number-wrapper {
      grid-area: product-number-wrapper;
      width: auto;
      display: grid;
      justify-content: center;
      align-items: center;
      .product-number {
        grid-area: product-number;
        display: grid;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 16px;
        dt {
          text-align: left;
          padding-bottom: 8px;
          font-weight: 700;
        }
        dd > input {
          text-align: end;
          padding: 0.75rem;
        }
      }
    }
    .label-of-amount {
      width: 50px;
      dt {
        padding-bottom: 22px;
        font-weight: 700;
      }
      text-align: center;
      > span {
        height: 40px;
      }
    }
  }
`;

export const FlexBaseline = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
`;

export const Input = styled.input<{
  align?: string;
  padding?: string;
  height?: string;
}>`
  width: 100%;
  height: 40px;
  margin-top: 24px;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  padding: ${(props) => (props.padding ? props.padding : '0.325rem')};
  text-align: ${(props) => props.align};
  outline: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  height: ${(props) => (props.height ? props.height : '')};
  background: ${(props) => props.theme.palette.white};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;
  & > button {
    width: 136px;
  }
`;
