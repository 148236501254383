import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { StockAllocationEditData } from '@lib/pc/stock_allocation/type';
import { Todo } from '@lib/common/type';

const useUpdateSemiProductStockAllocationMutation = (
  id: number,
  handleUpdateSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: StockAllocationEditData) => {
      return await axiosInstance().patch(
        `/api/v1/semi_product_stock_allocations/${id}`,
        humps.decamelizeKeys({
          productStockAllocation: {
            detailId: params.detailId,
            bestBeforeDate: params.bestBeforeDate,
            allocationType: params.allocationType,
            storeroomId: params.storeroomId,
            caseId: params.caseId,
            case: params.case,
            piece: params.piece,
            amount: params.amount,
            comment: params.comment,
            productId: params.productId,
          },
        })
      );
    },
    {
      onSuccess: () => {
        handleUpdateSuccess();
      },
      onError: (error: Todo) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
      },
    }
  );
};

export default useUpdateSemiProductStockAllocationMutation;
