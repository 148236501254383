import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  max-width: 100vw;
  max-height: 100vh;
  height: calc(100vh - 143px);
  overflow: auto;
  position: relative;
  background: ${(props) => props.theme.palette.beige};
`;

export const ListWrapper = styled.div`
  max-width: 100vw;
  height: calc(100vh - 139px);

  overflow: scroll;
  display: flex;
  position: relative;
  background: ${(props) => props.theme.palette.beige};
`;

const highlightenHeadCell = css`
  color: ${(props) => props.theme.palette.white}!important;
  background: ${(props) => props.theme.palette.blue}!important;
  border-color: ${(props) => props.theme.palette.blue}!important;
`;

const highlightenListLeftCell = css`
  position: relative;
  border-right: 2px solid ${(props) => props.theme.palette.blue};
  border-left: 2px solid ${(props) => props.theme.palette.blue};
`;

const cornerArrow = css`
  ::before,
  ::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
  }
  ::before {
    border-width: 0.4rem;
  }
  ::after {
    border-width: 0.4rem;
    border-right-color: ${(props) => props.theme.palette.blue};
    border-top-color: ${(props) => props.theme.palette.blue};
  }
`;

export const ListHeadCell = styled.div<{
  highlighten: boolean;
  dayOfWeek?: string;
  index: number;
  numOfIndex: number;
}>`
  height: 52px;
  width: 91px;
  box-sizing: border-box;
  white-space: nowrap;
  padding: 0.5rem 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) =>
    props.dayOfWeek === '土' &&
    !props.highlighten &&
    props.index <= props.numOfIndex
      ? '#1046cf'
      : props.dayOfWeek === '日' &&
        !props.highlighten &&
        props.index <= props.numOfIndex
      ? '#c74141'
      : ''};

  :last-child {
    position: sticky;
    top: 0;
    right: 0;
    width: 91px;
    border-left: 2px double ${(props) => props.theme.palette.sandGray};
    font-weight: 700;
    pointer-events: none;
  }

  :nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
  }

  :nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }

  ${({ highlighten }) => (highlighten ? highlightenHeadCell : null)}
`;

export const ListCellContainer = styled.div<{
  highlighten: boolean;
  dayOfWeek?: string;
  index: number;
  numOfIndex: number;
  invalid?: boolean;
  withComment?: boolean;
  noData?: boolean;
}>`
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};
  position: relative;
  cursor: pointer;
  height: 52px;
  width: 91px;
  box-sizing: border-box;
  padding: 0.5rem 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  /* 下記は一旦コメントアウト */
  /* background-color: ${(props) =>
    props.withComment ? '#FDDEDE!important' : ''}; */
  pointer-events: ${(props) => (props.noData ? 'none' : '')};

  ${({ highlighten }) => (highlighten ? highlightenListLeftCell : null)};
  ${({ withComment }) => (withComment ? cornerArrow : null)};

  &.select-cell {
    border: 2px solid ${(props) => props.theme.palette.primary.main};
  }

  :last-child {
    position: sticky;
    top: 0;
    right: 0;
    width: 91px;
    border-left: 2px double ${(props) => props.theme.palette.sandGray};
    font-weight: 700;
    background: ${(props) => props.theme.palette.white};
    pointer-events: none;
    border-bottom: 1px solid #f5f2e6;
  }
`;

export const ListBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: relative;
  height: max-content;
  -webkit-transition: transform 390ms ease-in-out;
  -moz-transition: transform 390ms ease-in-out;
  -ms-transition: transform 390ms ease-in-out;
  -o-transition: transform 390ms ease-in-out;
  transition: transform 390ms ease-in-out;
  &.move-to-left {
    -webkit-transform: translateX(-390px);
    -moz-transform: translateX(-390px);
    -ms-transform: translateX(-390px);
    -o-transform: translateX(-390px);
    transform: translateX(-390px);
  }

  & > div:first-child {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0px;
    z-index: 4;
    //COMMENT: 色確認、figmaと違う？ figma:#F5F2E6
    background-color: #f8f7f4;
  }

  & > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.paleYellow};
  }

  & > div:nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }
  & > div:not(:first-child):nth-child(even) > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
  }
  & > div:not(:first-child):nth-child(odd) > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.yellowGray};
  }
`;

export const ListRow = styled.div`
  display: flex;
  background: #fff;
  height: 52px;
  border-bottom: 1px solid ${(props) => props.theme.palette.yellowGray};

  :first-child {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: -1px;
    z-index: 4;
    box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);
  }
`;

export const CircularIconWrapperOuter = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`;

export const CircularIconWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  background-color: ${(props) => props.theme.palette.white} !important;
`;

export const ListDateRow = styled.div`
  display: flex;
  background: #fff;
  cursor: pointer;
  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);
`;

export const ListSidebar = styled.div`
  max-width: 250px;
  border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
  background: #fff;
  background: ${(props) => props.theme.palette.beige};
  height: max-content;

  position: sticky;
  left: -1px;
  z-index: 5;

  & > div:first-child {
    background: ${(props) => props.theme.palette.paleYellow};

    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: -1px;
    background: ${(props) => props.theme.palette.yellowGray};
    z-index: 1000;
  }
`;

export const ListLeftHeadCell = styled.div<{
  invalid?: boolean;
  isBlue?: boolean;
}>`
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};
  cursor: pointer;
  height: 52px;
  min-width: 250px;
  border-bottom: 1px solid ${(props) => props.theme.palette.yellowGray};
  box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.05);
  position: -webkit-sticky; /* for Safari */
  -webkit-transform: translateZ(0);
  display: flex;
  justify-content: space-between;
  padding: 6px 0.75rem;
  gap: 0.25rem;
  flex-direction: column;
  :first {
    place-items: center;
  }

  > span {
    font-size: 0.75rem;
    &.filter-product {
      width: 100%;
      padding-left: 30px;
      height: 40px;
      line-height: 37px;
      background: ${(props) => props.theme.palette.white};
      color: ${(props) =>
        props.isBlue ? props.theme.palette.blue : '#9ca3af'};
      border-radius: 0.5rem;
      cursor: pointer;

      > svg {
        position: absolute;
        top: 30%;
        left: 10%;
      }

      > div {
        padding-top: 3px;
        padding-left: 15px;
        font-size: 16px;
      }
    }
  }

  //COMMENT: OKであれば後で書き方変えたほうがいい
  :first-child {
    background-color: #f5f2e6 !important;
    > div > input {
      cursor: pointer;
      height: 32px;
      padding: 0.5rem 40px 0.5rem;
    }
  }

  :nth-child(odd) {
    background: ${(props) => props.theme.palette.paleYellow};
  }
  :nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }
`;

export const ListLeftHeadNameWrapper = styled.div`
  color: ${(props) => props.theme.palette.blue};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
`;

export const ListLeftHeadName = styled.div`
  color: ${(props) => props.theme.palette.blue};
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  max-width: 190px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ListLeftHeadMeta = styled.div`
  color: rgba(51, 51, 51, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
  > span {
    font-weight: 700;
  }
`;

export const ProductKindDiv = styled.div<{
  productKind: string;
}>`
  font-size: 0.75rem;
  padding: 2px 6px;
  border-radius: 2px;
  border: 1px solid #2ba9d1;
  background: ${(props) => (props.productKind === '完' ? '#2BA9D1' : '#fff')};
  color: ${(props) => (props.productKind === '完' ? '#fff' : '#2BA9D1')};
`;
