import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.white};
  position: relative;
`;

export const HeadContainer = styled.div`
  padding: 1.3rem 1.15rem 1.3rem 2.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FlexBaseline = styled.div`
  display: flex;
  align-items: baseline;
`;
