import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.white};
  position: relative;
  height: calc(100vh - 5rem);
`;

export const HeadContainer = styled.div`
  padding: 1.3rem 1.15rem 1.3rem 2.25rem;
  display: flex;
  align-items: center;
  > div {
    > span {
      font-feature-settings: 'palt';
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;
  left: 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  right: 175px;
  & > a {
    width: 160px;
    height: 45px;
    padding: 10px 10px 10px 0;
    border-radius: 5px;
    color: ${(props) => props.theme.palette.white};
    background: ${(props) => props.theme.palette.primary.main};
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
  }
`;
