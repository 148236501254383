import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.white};
  position: relative;
`;

export const HeadContainer = styled.div`
  padding: 1rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FlexBaseline = styled.div`
  display: flex;
  align-items: baseline;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ArrowLeft = styled.div`
  align-items: baseline;
  cursor: pointer;
  width: 36px;
  height: 36px;
`;

export const ArrowRight = styled.div<{
  isActive?: boolean;
}>`
  align-items: baseline;
  cursor: ${(props) => (props.isActive ? 'pointer' : '')};
`;

export const Date = styled.div`
  font-size: 24px;
  font-weight: 700;
  align-items: baseline;
  padding: 4px 4px 0 5px;
`;
