import { Heading } from '@components/elements/typographies/Heading';
import { Link, useLocation } from 'react-router-dom';
import * as S from './SupplierHeaderControl.styles';
import { ShippingGroupState } from '@lib/pc/settings/shipping_group_setting/type';

const SupplierHeaderControl = () => {
  const { state } = useLocation<ShippingGroupState>();

  return (
    <div>
      <S.HeadContainer className="head-container">
        <div>
          <Heading tag="span" text="仕入先管理" fs="32" fw="lg" />
        </div>

        {/* 新規登録ボタン */}
        <S.ButtonWrapper>
          <S.ButtonContainer>
            <Link
              to={{
                pathname: `/pc/settings/supplier_setting_create`,
                state: {
                  ...state,
                  stateSearchWord: '',
                  stateSelectedTags: [],
                  editData: null,
                },
              }}
            >
              ＋ 新規登録
            </Link>
          </S.ButtonContainer>
        </S.ButtonWrapper>
      </S.HeadContainer>
    </div>
  );
};

export default SupplierHeaderControl;
