import { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as S from './SalesInformation.styles';
import { ReactComponent as DetailClose } from '@assets/icons/detail_close.svg';
import { ReactComponent as DetailOpen } from '@assets/icons/detail_open.svg';
import { Heading } from '@components/elements/typographies/Heading';
import { WithHeader } from '@components/templates/WithHeader';
import { Button } from '@components/elements/buttons/Button';
import UpdatePopUp from '@components/modules/common/UpdatePopUp/UpdatePopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import SalesManagementTable from '@components/modules/pc/sales_management/SalesManagementTable/SalesManagementTable';
import { useSalesInformationList } from '@lib/pc/sales_information/useSalesInformationList';
import { SalesInformationState } from '@lib/pc/sales_information/type';
import { PCPath } from '@config/path';

export const SalesInformationRoot = () => {
  const { state } = useLocation<SalesInformationState>();
  const history = useHistory();
  const [msgKind, setMsgKind] = useState('');
  const [expand, setExpand] = useState(state ? state.stateExpand : true);
  const [date, setDate] = useState<Date | null>(
    state ? state.stateSelectedEntryDate : null
  );
  // 営業検索文字列
  const [searchEmployee, setSearchEmployee] = useState(
    state ? state.stateSearchEmployee : ''
  );
  // 得意先検索文字列
  const [searchCustomer, setSearchCustomer] = useState(
    state ? state.stateSearchCustomer : ''
  );

  const [created, setCreated] = useState(
    state?.stateMsgKind === 'create' ? true : false
  );
  const [updated, setUpdated] = useState(
    state?.stateMsgKind === 'update' ? true : false
  );

  const [scrollPosition, setScrollPosition] = useState<number | null>(null);

  // スクロール位置保存
  document?.getElementById('table-contents')?.addEventListener('scroll', () => {
    const currentScrollPosition =
      document?.getElementById('table-contents')?.scrollTop;
    if (currentScrollPosition) {
      setScrollPosition(currentScrollPosition);
    }
  });

  const handleClosePopUp = () => {
    setCreated(false);
    setUpdated(false);
    setMsgKind('');
    history.push({
      pathname: '/pc/sales_information',
      state: { ...state, stateMsgKind: '' },
    });
  };

  const useDebounce = (value: string, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  };

  // 500ミリ秒でデバウンス
  const debouncedSearchEmployee = useDebounce(searchEmployee, 500);
  const debouncedSearchCustomer = useDebounce(searchCustomer, 500);

  const { salesInformationList, refetch } = useSalesInformationList(
    date,
    debouncedSearchEmployee,
    debouncedSearchCustomer
  );

  useEffect(() => {
    localStorage.setItem('last_view_page', `${PCPath.salesInformation}`);
  }, []);

  return (
    <WithHeader>
      <S.Wrapper>
        <div>
          <S.HeadContainer>
            <S.FlexBaseline>
              <div>
                <Heading tag="span" text="営業情報" fs="32" fw="lg" />
                <Heading tag="span" text="を確認" fs="24" fw="lg" />
              </div>
            </S.FlexBaseline>
            <S.ButtonWrapper className="button-wrapper">
              <S.ExpandButtonWrapper>
                <S.Expand onClick={() => setExpand(!expand)}>
                  {expand ? (
                    <Button
                      borderWidth={1}
                      outlined={true}
                      padding="sm"
                      width="160px"
                      height="42px"
                      iconAfter={<DetailClose />}
                      onClick={() => setExpand(!expand)}
                    >
                      詳細クローズ
                    </Button>
                  ) : (
                    <Button
                      padding="sm"
                      width="160px"
                      height="42px"
                      iconAfter={<DetailOpen />}
                      onClick={() => setExpand(!expand)}
                    >
                      詳細オープン
                    </Button>
                  )}
                </S.Expand>
              </S.ExpandButtonWrapper>

              <S.ButtonContainer>
                <Link
                  to={{
                    pathname: `/pc/sales_information/new`,
                    state: {
                      ...state,
                      stateExpand: expand,
                      stateScrollPosition: scrollPosition,
                      stateSelectedEntryDate: date,
                      stateSearchEmployee: searchEmployee,
                      stateSearchCustomer: searchCustomer,
                    },
                  }}
                >
                  ＋ 新規登録
                </Link>
              </S.ButtonContainer>
            </S.ButtonWrapper>
          </S.HeadContainer>
          {salesInformationList && (
            <SalesManagementTable
              salesInformationList={salesInformationList}
              refetch={refetch}
              setMsgKind={setMsgKind}
              expand={expand}
              setExpand={setExpand}
              date={date}
              setDate={setDate}
              searchEmployee={searchEmployee}
              setSearchEmployee={setSearchEmployee}
              searchCustomer={searchCustomer}
              setSearchCustomer={setSearchCustomer}
              scrollPosition={scrollPosition}
            />
          )}
        </div>
      </S.Wrapper>
      <UpdatePopUp
        taskKind="sales_information"
        messageKind={
          created ? 'create' : updated ? 'update' : msgKind ? msgKind : ''
        }
        popUp={created ? true : updated ? true : msgKind ? true : false}
        handleClose={() => handleClosePopUp()}
        fromPc
      />
      {(created || updated || msgKind) && (
        <Overlay zIndex={1} handleClick={() => handleClosePopUp()} />
      )}
    </WithHeader>
  );
};
