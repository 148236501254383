import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { Todo } from '@lib/common/type';

const useMenuLinkFunctions = () => {
  const { data, refetch } = useQuery('menu_link_functions', async () => {
    const data = await axiosInstance().get(`/api/v1/user_and_menus`);
    return data;
  });
  const linkFunctions = humps.camelizeKeys(
    data?.data?.link_functions
  ) as Todo[];
  return { linkFunctions, refetch };
};

export default useMenuLinkFunctions;
