import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.white};
  position: relative;
  height: calc(100vh - 80px);
`;

export const HeadContainer = styled.div`
  padding: 1rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PageTitleText = styled.div`
  display: flex;
  align-items: baseline;
  padding-bottom: 4px;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const Date = styled.div`
  font-size: 24px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  text-align: center;
  padding: 5px 0;
  user-select: none;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: -3px;

  > span {
    font-size: 22px;
    margin: 0 5px 0 5px;
  }
`;

export const ButtonContainer = styled.div`
  position: relative;
  margin-left: 14px;
`;

export const ArrowLeft = styled.div<{
  selected: boolean;
}>`
  cursor: pointer;
  margin-right: 0.3rem;
  pointer-events: ${(props) => (props.selected ? 'none' : '')}; ;
`;

export const ArrowRight = styled.div<{
  selected: boolean;
}>`
  cursor: pointer;
  pointer-events: ${(props) => (props.selected ? 'none' : '')}; ;
`;
