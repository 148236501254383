import { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import * as S from './MaterialsForStocks.styles';
import BarcodeReader from '../RealtimeScanner/BarcodeReader';
import { ReactComponent as FactoryIcon } from '@assets/icons/mobile/factory.svg';
import scanSound from '@assets/sounds/sound.mp3';
import { WithHeader } from '@components/templates/mobile/WithHeader';
import { ReactComponent as ExpandMore } from '@assets/icons/expand_more.svg';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import MaterialList from '@components/modules/mobile/materials_for_stocks/MaterialList/MaterialList';
import SearchConditionList from '@components/modules/common/mobile/SearchConditionList/SearchConditionList';
import SelectedTagList from '@components/modules/common/mobile/SelectedTagList/SelectedTagList';
import TagsForStocksModal from '@components/modules/mobile/tags_for_stocks_modal/TagsForStocksModal/TagsForStocksModal';
import StoreroomsForStocksModal from '@components/modules/mobile/storerooms_for_stocks_modal/StoreroomsForStocksModal/StoreroomsForStocksModal';
import { MATERIAL_PRODUCT_KIND, type Product } from '@lib/common/type';
import useTags from '@lib/mobile/common/hooks/useTags';
import useStorerooms from '@lib/mobile/common/hooks/useStorerooms';
import { useMaterialStocks } from '@lib/mobile/materials_for_stocks/useMaterialStocks';
import type { MaterialsSearchState } from '@lib/mobile/materials_for_stocks/type';
import type { Storeroom, Todo } from '@lib/common/type';
import InfiniteLoader from 'react-window-infinite-loader';
import { axiosInstance } from '@lib/mobile/common/api/axiosConfig';
import useSWR, { useSWRConfig } from 'swr';
import humps from 'humps';
import { CircularProgress } from '@material-ui/core';
import { useSelectedStoreroom } from '@lib/common/hooks/useSelectedStoreroom';
import { useSearchChar } from '@lib/common/hooks/useSearchChar';
import { useMetaPagination } from '@lib/common/hooks/useMetaPagination';
import { useSelectedTagIds } from '@lib/common/hooks/useSelectedTagIds';
import { ListOnItemsRenderedProps } from 'react-window';

export const MaterialsForStocksRoot = () => {
  const history = useHistory();
  const { selectedTagIds, setSelectedTagIds } = useSelectedTagIds();
  const {
    selectedStoreroomId,
    setSelectedStoreroomId,
    selectedStoreroomName,
    setSelectedStoreroomName,
  } = useSelectedStoreroom();
  const [scanning, setScanning] = useState(true);
  const [results, setResults] = useState<Todo>([]);
  const [inputText] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [audio] = useState(new Audio(scanSound));
  // 在庫室検索文字列
  const [searchStoreroom, setSearchStoreroom] = useState('');
  const [filteredStoreroom, setFilteredStoreroom] = useState<Storeroom[]>([]);
  const { state } = useLocation<MaterialsSearchState>();
  const { data: tags } = useTags(MATERIAL_PRODUCT_KIND.MATERIAL);
  const { materialStocks } = useMaterialStocks(); // バーコードリーダー対応の為に新しいエンドポイントが必要??
  const { data: storerooms } = useStorerooms(MATERIAL_PRODUCT_KIND.MATERIAL);

  // タグmodal画面open用
  const [tagsOpen, setTagsOpen] = useState(false);
  // 在庫室modal画面open用
  const [storeroomOpen, setStoreroomOpen] = useState(false);

  // Use the global searchChar state from SWR
  const { searchChar, setSearchChar } = useSearchChar();

  // products一覧state
  const [stateProductsData, _setStateProductsData] = useState<Product[]>([]);
  // 検索➜在庫入力➜検索結果状態を取得する

  const searchState = {
    stateSearchChar: searchChar,
    stateSelectedTagIds: selectedTagIds,
    stateSelectedStoreroom: selectedStoreroomName,
    stateSelectedStoreroomId: selectedStoreroomId,
    stateProductsData: stateProductsData,
  };

  const {
    metaCurrentPage,
    setMetaCurrentPage,
    metaTotalPages,
    setMetaTotalPages,
  } = useMetaPagination();

  const isInitialLoadComplete = useRef(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    let combinedDataFromStocks: Todo[] = [];
    if (selectedStoreroomId && materialStocks) {
      const filteredFromStocks = materialStocks.filter(
        (stock: { storeroomIds: number[] }) =>
          stock.storeroomIds.includes(selectedStoreroomId)
      );
      combinedDataFromStocks = [...filteredFromStocks];
    }
    if (results.length !== 0) {
      const getData = [
        ...searchState.stateProductsData,
        ...combinedDataFromStocks,
      ].filter(
        (pro: Todo) =>
          pro.materialCode === results[0]?.codeResult?.code &&
          pro.storeroomIds.includes(Number(selectedStoreroomId))
      );
      if (!!getData && getData.length > 0) {
        history.push(
          `/mobile/materials/${getData[0].id}/stocks/${moment().format(
            'YYYY-MM-DD'
          )}/${selectedStoreroomId}/edit`,
          searchState
        );
      } else {
        // 該当のjanCodeがなかった場合
        setErrMsg('該当の資材原材料が見つかりませんでした。');
      }
    }
  }, [results]);

  const selectedTags =
    tags?.filter((tag) => selectedTagIds.includes(tag.id)) || [];

  // 場所検索機能
  useEffect(() => {
    if (!storerooms) return;
    if (searchStoreroom) {
      setFilteredStoreroom(
        storerooms.filter((sr) => sr.name.match(searchStoreroom))
      );
    } else {
      setFilteredStoreroom(storerooms);
    }
  }, [searchStoreroom, storerooms]);

  // 初期の在庫室選択画面表示時、localStorageからuseScanning削除
  useEffect(() => {
    if (state || selectedStoreroomId) return;
    localStorage.removeItem('useScanning');
  }, [state]);

  const [hasNextPage, setHasNextPage] = useState(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { mutate } = useSWRConfig();
  const { data: filteredMaterials } = useSWR('filteredMaterials');

  const fetchData = useCallback(
    async (currentPage: number) => {
      try {
        const response = await axiosInstance().get(`/api/v1/material_stocks`, {
          params: {
            date: moment().format('YYYYMMDD'),
            storeroom_id: selectedStoreroomId,
            page: currentPage + 1,
            search: searchChar,
            tag_ids: selectedTagIds,
            per_page: 30,
          },
        });
        const newItems = humps.camelizeKeys(response?.data?.material_stocks);
        setMetaCurrentPage(response?.data?.meta.current_page);
        setMetaTotalPages(response?.data?.meta.total_pages);

        mutate('filteredMaterials', newItems, false);

        return newItems;
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [searchChar, selectedTagIds, selectedStoreroomId]
  );

  const loadMoreItems = useCallback(async () => {
    if (isNextPageLoading || !hasNextPage) return;

    setIsNextPageLoading(true);
    if (metaCurrentPage < metaTotalPages) {
      const newProducts = await fetchData(metaCurrentPage);

      if (!newProducts?.length) {
        setHasNextPage(false);
      } else {
        mutate(
          'filteredMaterials',
          [...(filteredMaterials || []), ...newProducts],
          false
        );
      }
    }
    setIsNextPageLoading(false);
  }, [
    hasNextPage,
    isNextPageLoading,
    metaCurrentPage,
    metaTotalPages,
    filteredMaterials,
    fetchData,
  ]);

  const isItemLoaded = (index: number) =>
    index < (filteredMaterials || []).length;

  const clearAll = () => {
    setHasNextPage(true);
    setIsNextPageLoading(false);
    mutate('filteredMaterials', [], false);
  };

  const fetchFilteredMaterials = async () => {
    fetchData(0);
  };

  const prevStoreroomId = useRef(selectedStoreroomId);
  const prevTagIds = useRef(selectedTagIds);
  const prevSearchChar = useRef(searchChar);

  useEffect(() => {
    // 初回マウント時は前回の値と比較
    if (prevStoreroomId.current === selectedStoreroomId) {
      return;
    }

    clearAll();

    if (selectedStoreroomId) {
      fetchFilteredMaterials();
      setTimeout(() => {
        isInitialLoadComplete.current = true;
      }, 1500);
    }

    prevStoreroomId.current = selectedStoreroomId;
  }, [selectedStoreroomId]);

  useEffect(() => {
    const areArraysEqual =
      prevTagIds.current?.length === selectedTagIds.length &&
      prevTagIds.current?.every((id, index) => id === selectedTagIds[index]);

    if (areArraysEqual) {
      return;
    }

    setIsLoading(true);
    clearAll();
    fetchFilteredMaterials();
    prevTagIds.current = selectedTagIds;
  }, [selectedTagIds]);

  useEffect(() => {
    if (prevSearchChar.current === searchChar) {
      return;
    }

    setIsLoading(true);
    clearAll();
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      fetchFilteredMaterials();
    }, 2000);
    prevSearchChar.current = searchChar;
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [searchChar]);

  const { data: scrollPositionData, mutate: mutateScrollPosition } = useSWR(
    'scrollPosition',
    null,
    {
      fallbackData: 0,
    }
  );

  // StyledFixedSizeListのonItemsRenderedを更新
  const handleItemsRendered = useCallback(
    (
      props: ListOnItemsRenderedProps,
      onItemsRendered: (props: ListOnItemsRenderedProps) => void
    ) => {
      const currentPosition = props.visibleStartIndex * 60;
      mutateScrollPosition(currentPosition, false);
      onItemsRendered(props);
    },
    [mutateScrollPosition]
  );

  useEffect(() => {
    if (scrollPositionData && filteredMaterials?.length > 0) {
      const listElement = document.querySelector('.StyledFixedSizeList');
      if (listElement) {
        listElement.scrollTo({
          top: scrollPositionData,
          behavior: 'instant',
        });
      }
    }
  }, []);

  const [listHeight, setListHeight] = useState(window.innerHeight - 240);

  useEffect(() => {
    const updateHeight = () => {
      setListHeight(window.innerHeight - 240);
    };

    // 初期設定
    updateHeight();

    // リサイズイベントの購読
    window.addEventListener('resize', updateHeight);

    // クリーンアップ
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  if (tagsOpen) {
    return (
      <TagsForStocksModal
        setTagsOpen={setTagsOpen}
        selectedTagIds={selectedTagIds}
        setSelectedTagIds={setSelectedTagIds}
        fromComponent="materialsForStocks"
      />
    );
  }

  if (storeroomOpen) {
    return (
      <StoreroomsForStocksModal
        searchStoreroom={searchStoreroom}
        setSearchStoreroom={setSearchStoreroom}
        storerooms={filteredStoreroom}
        setStoreroomOpen={setStoreroomOpen}
        setSelectedStoreroomId={setSelectedStoreroomId}
        setSelectedStoreroomName={setSelectedStoreroomName}
        materialsSearchState={state}
        fromPicking={'materials_for_stocks'}
      />
    );
  }

  return (
    <WithHeader>
      <S.Wrapper>
        <S.Title>
          <S.TitleText>資材・原材料</S.TitleText>
          <S.StoreroomPanel onClick={() => setStoreroomOpen(true)}>
            <FactoryIcon />
            {selectedStoreroomName ? (
              <S.StorageText>{selectedStoreroomName}</S.StorageText>
            ) : (
              <S.StorageText>場所を選択</S.StorageText>
            )}
            <S.ExpandMore>
              <ExpandMore />
            </S.ExpandMore>
          </S.StoreroomPanel>
        </S.Title>
        {selectedStoreroomId && (
          <S.SearchConditionList>
            {selectedTags.length === 0 ? (
              <SearchConditionList
                setTagsOpen={setTagsOpen}
                searchChar={searchChar}
                setSearchChar={setSearchChar}
              />
            ) : (
              <SelectedTagList
                tags={selectedTags}
                clearAll={() => setSelectedTagIds([])}
                clear={(tagId) =>
                  setSelectedTagIds(selectedTagIds.filter((id) => id !== tagId))
                }
                tagsOpen={tagsOpen}
                setTagsOpen={setTagsOpen}
                clearTagSearch={() => {
                  setSelectedTagIds([]);
                }}
              />
            )}
          </S.SearchConditionList>
        )}
        <S.ProductList id="product-list">
          {filteredMaterials === undefined ? (
            <S.SelectStoreroomText>
              場所を選択してください。
            </S.SelectStoreroomText>
          ) : !isLoading &&
            (searchChar !== '' || selectedTagIds.length > 0) &&
            filteredMaterials.length === 0 ? (
            <S.NoProductsToShow>
              資材・原材料が見つかりませんでした。
              <div>条件を変更して再度検索してください。</div>
            </S.NoProductsToShow>
          ) : filteredMaterials?.length === 0 && selectedStoreroomId ? (
            <S.SelectStoreroomText>
              <S.CenteredCircularProgress>
                <CircularProgress style={{ color: '#64b2f9' }} />
              </S.CenteredCircularProgress>
            </S.SelectStoreroomText>
          ) : (
            <>
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={
                  hasNextPage
                    ? (filteredMaterials?.length || 0) + 1
                    : filteredMaterials?.length || 0
                }
                loadMoreItems={loadMoreItems}
                threshold={15}
              >
                {({ onItemsRendered, ref }) => (
                  <S.StyledFixedSizeList
                    height={listHeight}
                    itemCount={filteredMaterials?.length || 0}
                    itemSize={60}
                    width="100%"
                    onItemsRendered={(props) =>
                      handleItemsRendered(props, onItemsRendered)
                    }
                    ref={ref}
                    className="StyledFixedSizeList"
                  >
                    {({ index, style }) => (
                      <div style={style}>
                        <MaterialList
                          key={index}
                          index={index}
                          material={filteredMaterials[index]}
                          searchChar={searchChar}
                          selectedTagIds={selectedTagIds}
                          selectedStoreroom={selectedStoreroomName}
                          selectedStoreroomId={selectedStoreroomId}
                        />
                      </div>
                    )}
                  </S.StyledFixedSizeList>
                )}
              </InfiniteLoader>
              {scanning ? (
                <BarcodeReader
                  onDetected={(result: Todo) => {
                    setScanning(false);
                    audio.play();
                    const newResult = {
                      codeResult: { code: result.codeResult.code },
                      inputText,
                    };
                    setResults([...results, newResult]);
                  }}
                />
              ) : (
                <ErrorMsgPopUp
                  fromPc={true}
                  errMsg={errMsg}
                  handleClose={() => {
                    setResults([]);
                    setScanning(true);
                    setErrMsg('');
                  }}
                />
              )}
            </>
          )}
        </S.ProductList>
      </S.Wrapper>
      {/* カメラボタン、一旦非表示 */}
      {/* {selectedStoreroomId && (
        <FloatingCameraButton
          handleClick={() =>
            history.push(`/mobile/live/${selectedStoreroomId}`, searchState)
          }
        />
      )} */}
    </WithHeader>
  );
};
export default MaterialsForStocksRoot;
