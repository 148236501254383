import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  /* height: calc(100vh - 221px); */
  min-height: calc(100vh - 245px);
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #cdcdcd;
  border-bottom: 1px solid ${(props) => props.theme.palette.sandGray};
  background-color: ${(props) => props.theme.palette.white};
`;

export const Content = styled.div`
  width: 100vw;
`;

export const Table = styled.table`
  width: 100vw;
  border-collapse: collapse;
  border-spacing: 0 1em;
  font-size: 0.875rem;
`;

export const TRow = styled.tr`
  border-bottom: 1px solid #e6e1dd;
  border-right: 0;
  border-left: 0;
  height: 46px;
  cursor: pointer;
  &.hover-effect {
    &:hover {
      box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
      border: solid 1px #e5e1dd;
    }
  }
`;

export const THead = styled.tr`
  background-color: ${(props) => props.theme.palette.beige};
  height: 2.6rem;
  position: sticky;
  top: 0;
  border-bottom: 2px solid #cdcdcd;
`;

export const THeadCell = styled.td<{
  align?: string;
  width?: string;
  wordBreak?: string;
  colSpan?: number;
}>`
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  vertical-align: middle;
  word-break: ${(props) => (props.wordBreak ? props.wordBreak : '')};
  width: ${(props) => (props.width ? props.width : '0rem')};
  min-width: ${(props) => (props.width ? props.width : '0rem')};
  padding: 0 0.625rem 0 0.5rem;
  text-align: -webkit-left;
  word-spacing: 100vw;
  > svg {
    display: inline;
    width: 18px;
    height: 18px;
  }
  > span {
    font-size: 10px;
    margin-left: 4px;
  }
`;

export const TCell = styled.td<{
  align?: 'left' | 'right' | 'center';
  width?: string;
  marginRight?: string;
  background?: string;
  wordBreak?: string;
  fontSize?: string;
  colSpan?: number;
  productKind?: number;
}>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  vertical-align: middle;
  word-break: ${(props) => (props.wordBreak ? props.wordBreak : '')};
  padding: 1rem 0;
  min-width: ${(props) => (props.width ? props.width : '1rem')};
  padding: ${(props) =>
    props.align === 'right'
      ? '1rem 0.5rem 1rem 0'
      : '0.4rem 0.5rem 0.4rem 0.5rem'};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '')};
  background: ${(props) => (props.background ? props.background : '')};
  > svg {
    display: inline;
    width: 18px;
    height: 18px;
  }
  > span {
    font-size: 10px;
    margin-left: 4px;
  }
  &.label {
    vertical-align: top;
    width: 26px;
    padding: 10px 0.25rem;
    border-right: solid 1px #e6e1dd;
    border-left: solid 1px #e6e1dd;
  }
  > div {
    &.product-wrapper {
      gap: 0.25rem;
      display: flex;
      flex-direction: column;
      > div {
        &.product-name-kind {
          display: flex;
          justify-content: space-between;
        }
        > div {
          &.product-name {
            margin-right: 1rem;
          }
          &.product-kind {
            height: 18px;
            font-size: 0.75rem;
            font-weight: 600;
            padding: 2px 6px;
            border-radius: 2px;
            border: 1px solid #2ba9d1;
            background: ${(props) =>
              props.productKind === 1 ? '#2BA9D1' : '#fff'};
            color: ${(props) => (props.productKind === 1 ? '#fff' : '#2BA9D1')};
          }
        }
      }
      > div {
        &.product-code {
          font-size: 0.75rem;
          color: rgba(51, 51, 51, 0.5);
        }
      }
    }
  }

  &.display-quantity {
    padding: 0.4rem 0 0.25rem;
    vertical-align: top;
    &__count {
      /* ケース */
      &--case {
        width: 6.5rem;
      }
      /* 個 */
      &--quantity {
        width: 6.5rem;
      }
      /* 合計 */
      &--total {
        width: 9rem;
        .with-label__inner-wrap {
          width: 100%;
        }
        .quantity {
          width: 100%;
          padding-right: 1rem;
          display: flex;
          justify-content: flex-end;
          .title {
            margin-right: auto;
            font-size: 14px;
          }
          .unit {
            font-size: 14px;
            margin-left: 2px;
          }
        }
      }
      /* 不良品 */
      &--defective-product {
        width: 5rem;
        .quantity {
          width: 100%;
          padding-right: 1rem;
        }
      }
    }
    .display-quantity__inner-wrap {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .cell-content {
        justify-content: center;
        align-items: flex-end;
        .quantity {
          display: flex;
          align-items: flex-end;
          .num {
            font-size: 20px;
          }
          .unit {
            font-size: 11px;
          }
        }
        .explanation {
          margin-top: 4px;
          font-size: 12px;
          font-size: 0.75rem;
          color: #6b675c;
        }
      }
    }
    .symbol {
      padding: 0 8px;
    }
    .cell-content {
      display: flex;
      flex-direction: column;
    }
  }
  &.order {
    cursor: pointer;
  }
  &.person {
    padding: 0.2rem 0.5rem;
    /* border-left: solid 1px #e6e1dd; */
  }
  /* 備考 */
  &.packaging-comment {
    position: relative;
    cursor: pointer;
    .cell-content {
      white-space: pre-wrap;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      word-break: break-all;
    }
  }
`;

/* 備考 全文表示 */
export const CommentBalloon = styled.tr`
  .comment-full__title {
    font-weight: bold;
  }
  .cell-content__balloon {
    white-space: pre-wrap;
    background-color: white;
    line-height: 1.8;
    padding: 8px 12px;
    display: none;
    &.active {
      display: table-cell;
      border-bottom: 1px solid #e6e1dd;
      word-break: break-all;
    }
  }
`;

export const PencilIcon = styled.td`
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  padding-left: 14px;
  background: white;
`;

export const FlexTableDivRow = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2ba9d1;
  font-size: 1rem;
  font-weight: bold;
  height: 4rem;
  border-bottom: 1px solid #cdcdcd;
  gap: 0.5rem;
  svg path {
    fill: #2ba9d1;
  }
`;

export const CircularIconWrapperOuter = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`;

export const CircularIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40%;
  left: 48%;
`;
