import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
  background-color: ${(props) => props.theme.palette.beige};
  overflow-x: hidden;
  z-index: 4;
`;

export const HeadContainer = styled.div`
  width: 100%;
  height: 88px;
  padding: 1.15rem 1.15rem 1.15rem 80px;
  background-color: ${(props) => props.theme.palette.white};
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  padding: 16px 80px;
`;

export const InputContainer = styled.form`
  margin-top: 3rem;
  margin-bottom: 2.75rem;
  width: 100%;
  text-align: left;
  display: flex;
  gap: 2rem;
`;

export const InputArea = styled.div`
  width: 40%;
  > dt {
    margin-bottom: 8px;
    font-weight: 700;
  }
`;

export const PasswordInputArea = styled.div`
  width: 30%;
  > dt {
    margin-bottom: 8px;
    font-weight: 700;
  }
  > dd {
    display: flex;
  }
`;

export const Input = styled.input`
  width: 90%;
  height: 44px;
  color: ${(props) => props.theme.palette.baseBlack};
  font-size: 1rem;
  padding: 0.75rem;
  text-align: left;
  outline: none;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: ${(props) => props.theme.palette.white};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const ButtonWrapper = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.beige};
  width: 100vw;
  height: 83px;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  display: flex;
  bottom: 0;
  padding: 22px 0;
  align-items: center;
  justify-content: space-evenly;
  & > button {
    width: 136px;
  }
  & > button:first-child {
    margin-right: 1rem;
  }
`;
