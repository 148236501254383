import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { WithHeader } from '@components/templates/WithHeader';
import * as S from './PurchaseOrders.styles';
import DeliveryStatus from '@components/modules/pc/purchase_orders/DeliveryStatus/DeliveryStatus';
import Header from '@components/modules/pc/purchase_orders/Header/Header';
import PdfContent from '@components/modules/pc/purchase_orders/PdfContent/PdfContent';
import PurchaseOrderList from '@components/modules/pc/purchase_orders/PurchaseOrderList/PurchaseOrderList';
import UpdatePopUp from '@components/modules/common/UpdatePopUp/UpdatePopUp';
import SupplierInfo from '@components/modules/pc/purchase_orders/SupplierInfo/SupplierInfo';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import { Overlay } from '@components/elements/backdrops/Overlay/Overlay.styles';
import { Todo } from '@lib/common/type';
import {
  Detail,
  MaterialOrderInfo,
  PurchaseOrderState,
} from '@lib/pc/purchase_orders/type';
import useDeleteMaterialDeliveriesMutation from '@lib/pc/purchase_orders/useDeleteMaterialDeliveriesMutation';
import useMaterialOrders from '@lib/pc/purchase_orders/useMaterialOrders';
import useMaterialOrder from '@lib/pc/purchase_orders_edit/useMaterialOrder';
import useMaterials from '@lib/pc/purchase_orders/useMaterials';
import useSuppliers from '@lib/pc/purchase_orders_new/useSuppliers';
import SearchConditionListModal from '@components/modules/pc/purchase_orders/SearchConditionListModal/SearchConditionListModal';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import { PCPath } from '@config/path';

export const PurchaseOrdersRoot = () => {
  const { state } = useLocation<PurchaseOrderState>();
  const history = useHistory();
  const [purchaseOrderState, setPurchaseOrderState] = useState(state);

  const [popUp, setPopUp] = useState(false);
  const [msgKind, setMsgKind] = useState('');
  const [taskKind, setTaskKind] = useState('');
  const [confirmMsg, setConfirmMsg] = useState('');
  const [selectedMaterialOrderId, setSelectedMaterialOrderId] = useState(
    state?.selectedMaterialOrderId ? state?.selectedMaterialOrderId : null
  );
  // 選択された注文書の情報
  const [, setPurchaseOrderInfo] = useState<Todo>([]);
  const [deleteId, setDeleteId] = useState(0);

  // 下位のコンポーネントに渡しているのがネストの深いオブジェクトなので、強制再レンダリングする必要がある
  const [, setToggleValue] = useState(false);
  const reRender = () => setToggleValue((prev) => !prev);

  const [isChangedArray, setIsChangedArray] = useState<boolean[]>([]);
  const [updating, setUpdating] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // 初期値を作成日にする
  const [selectedDateTypeId, setSelectedDateTypeId] = useState('1');

  const today = moment();
  // subtract()メソッドで1ヶ月前の日付を設定
  const oneMonthAgo = today.clone().subtract(1, 'months');

  const [dateRange, setDateRange] = useState<{
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }>({
    startDate: oneMonthAgo,
    endDate: today,
  });

  // 日付検索modalオープン
  const [searchDateMenuActive, setSearchDateMenuActive] = useState(false);
  // 検索modalオープン
  const [searchConditionOpen, setSearchConditionOpen] = useState(false);
  // 取引先検索
  const [destConditions, setDestConditions] = useState<number[]>([]);
  const [selectedDestConditions, setSelectedDestConditions] =
    useState<number[]>(destConditions);
  // 発送状況検索
  const [statusConditions, setStatusConditions] = useState<string[]>([]);
  const [selectedStatusConditions, setSelectedStatusConditions] =
    useState<string[]>(statusConditions);
  // 納品状況検索
  const [receivedConditions, setReceivedConditions] = useState<string[]>([]);
  const [selectedReceivedConditions, setSelectedReceivedConditions] =
    useState<string[]>(receivedConditions);
  const [filteredOrders, setFilteredOrders] = useState<MaterialOrderInfo[]>([]);
  // 納品日と数に変更があったか判定
  const [isLineChangedArray, setIsLineChangedArray] = useState<boolean[]>([]);
  // 他の納品物に保存可能な入力があった場合、別の納品物の入力を不可とする
  const [disableAnotherMaterial, setDisableAnotherMaterial] = useState(false);
  const [stateScrollPosition, setScrollPosition] = useState(0);

  // 注文一覧情報取得
  const { materialOrders, refetch: materialOrdersRefetch } =
    useMaterialOrders();

  // 取引先情報取得
  const { data: suppliers } = useSuppliers();

  // 注文書個別情報取得
  const { materialOrder: materialOrder, refetch } = useMaterialOrder(
    purchaseOrderState?.selectedMaterialOrderId
      ? purchaseOrderState?.selectedMaterialOrderId
      : materialOrders?.firstMaterialOrder?.orderId
      ? materialOrders?.firstMaterialOrder?.orderId
      : materialOrders?.materialOrders[0]?.id
  );

  const [supplierId, setSupplierId] = useState(
    materialOrder?.materialOrder?.supplierId
  );

  // 資材・原材料情報取得
  const { materials: materials, refetch: refetchMaterial } = useMaterials(
    supplierId ? supplierId : 0
  );

  // 注文書削除後処理
  const handleMaterialOrderDeleteSuccess = () => {
    materialOrdersRefetch();
    setConfirmMsg('');
    setMsgKind('delete');
    setTaskKind('orderStatus');
    refetch();
    reRender();
    setPopUp(true);
    filteredOrders && setSelectedMaterialOrderId(filteredOrders[0].id);
  };

  // 納品状況削除後処理
  const handleDeleteSuccess = () => {
    materialOrdersRefetch();
    refetch();
    setConfirmMsg('');
    setMsgKind('delete');
    setTaskKind('materialDelivery');
    reRender();
    setPopUp(true);
  };

  const deleteMaterialDeliveriesMutation = useDeleteMaterialDeliveriesMutation(
    deleteId,
    handleDeleteSuccess,
    setErrMsg
  );

  // 削除ボタンで納品状況レコードを削除する
  const handleDelete = () => {
    deleteMaterialDeliveriesMutation.mutate();
    setConfirmMsg('');
  };

  useEffect(() => {
    // refetch();
    reRender();
  }, [selectedMaterialOrderId]);

  // supplierIdが変更されたら、materialを取得し直す
  useEffect(() => {
    refetchMaterial();
  }, [supplierId]);

  useEffect(() => {
    setSupplierId(materialOrders?.firstMaterialOrder?.supplierId);
  }, [materialOrders?.firstMaterialOrder?.supplierId]);

  useEffect(() => {
    if (state === undefined || state?.afterCreateOrder) {
      materialOrders?.materialOrders[0]?.id &&
        setSelectedMaterialOrderId(materialOrders?.materialOrders[0]?.id);
    }
  }, [materialOrders?.materialOrders?.length]);

  useEffect(() => {
    localStorage.setItem('last_view_page', `${PCPath.purchaseOrders}`);
  }, []);

  return (
    <>
      <WithHeader>
        <S.Wrapper>
          <Header
            dateRange={dateRange}
            setDateRange={setDateRange}
            selectedDateTypeId={selectedDateTypeId}
            setSelectedDateTypeId={setSelectedDateTypeId}
            materials={materials}
            setSelectedMaterialOrderId={setSelectedMaterialOrderId}
            setSearchConditionOpen={setSearchConditionOpen}
            statusConditions={statusConditions}
            destConditions={destConditions}
            receivedConditions={receivedConditions}
            searchDateMenuActive={searchDateMenuActive}
            setSearchDateMenuActive={setSearchDateMenuActive}
          />
          <S.MainContent>
            {/* 注文書一覧 */}
            {materialOrders && (
              <PurchaseOrderList
                materialOrders={materialOrders}
                isChangedArray={isChangedArray}
                setIsChangedArray={setIsChangedArray}
                selectedMaterialOrderId={
                  selectedMaterialOrderId
                    ? selectedMaterialOrderId
                    : materialOrders?.firstMaterialOrder?.orderId
                }
                setSelectedMaterialOrderId={setSelectedMaterialOrderId}
                setSupplierId={setSupplierId}
                setPurchaseOrderInfo={setPurchaseOrderInfo}
                popUp={popUp}
                setPopUp={setPopUp}
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                selectedDateTypeId={selectedDateTypeId}
                destConditions={destConditions}
                statusConditions={statusConditions}
                receivedConditions={receivedConditions}
                setFilteredOrders={setFilteredOrders}
                setDisableAnotherMaterial={setDisableAnotherMaterial}
                purchaseOrderState={purchaseOrderState}
                setPurchaseOrderState={setPurchaseOrderState}
                stateScrollPosition={stateScrollPosition}
                setScrollPosition={setScrollPosition}
              />
            )}
            <S.CenterColumn>
              {/* サプライヤー情報 */}
              <SupplierInfo
                materialOrder={
                  materialOrder
                    ? materialOrder?.materialOrder
                    : materialOrders?.firstMaterialOrder
                }
                selectedMaterialOrderId={
                  selectedMaterialOrderId
                    ? selectedMaterialOrderId
                    : materialOrders?.firstMaterialOrder?.orderId
                }
                setSelectedMaterialOrderId={setSelectedMaterialOrderId}
                materials={materials}
                handleMaterialOrderDeleteSuccess={
                  handleMaterialOrderDeleteSuccess
                }
                refetch={refetch}
                materialOrdersRefetch={materialOrdersRefetch}
                setPopUp={setPopUp}
                setMsgKind={setMsgKind}
                setTaskKind={setTaskKind}
                setPurchaseOrderState={setPurchaseOrderState}
                stateScrollPosition={stateScrollPosition}
              />
              {/* 注文書 */}
              <S.PdfView>
                {materialOrders && (
                  <PdfContent
                    purchaseOrderInfo={
                      materialOrder?.materialOrder
                        ? materialOrder?.materialOrder
                        : materialOrders?.firstMaterialOrder
                    }
                    manufactureInfo={materialOrder?.manufacture}
                  />
                )}
              </S.PdfView>
            </S.CenterColumn>
            <S.RightSide>
              {/* 発生条件・リードタイム */}
              {materialOrders ? (
                <>
                  <S.DetailList>
                    {materialOrder &&
                      materialOrder.details?.map(
                        (detail: Detail, index: number) => (
                          <div key={index}>
                            <S.DetailItem>
                              <S.KindLabel>{detail.kind}</S.KindLabel>
                              <S.DetailItemText>{detail.text}</S.DetailItemText>
                            </S.DetailItem>
                          </div>
                        )
                      )}
                  </S.DetailList>
                  {/* 納品状況 */}
                  <DeliveryStatus
                    supplierId={supplierId}
                    materialOrder={materialOrder?.materialOrder}
                    isChangedArray={isChangedArray}
                    updating={updating}
                    setUpdating={setUpdating}
                    setMsgKind={setMsgKind}
                    setTaskKind={setTaskKind}
                    setPopUp={setPopUp}
                    setDeleteId={setDeleteId}
                    setConfirmMsg={setConfirmMsg}
                    msgKind={msgKind}
                    refetch={refetch}
                    materialOrdersRefetch={materialOrdersRefetch}
                    isLineChangedArray={isLineChangedArray}
                    setIsLineChangedArray={setIsLineChangedArray}
                    disableAnotherMaterial={disableAnotherMaterial}
                    setDisableAnotherMaterial={setDisableAnotherMaterial}
                  />
                </>
              ) : (
                ''
              )}
            </S.RightSide>
          </S.MainContent>
        </S.Wrapper>
      </WithHeader>
      {searchConditionOpen && (
        <SearchConditionListModal
          suppliers={suppliers}
          setStatusConditions={setStatusConditions}
          setDestConditions={setDestConditions}
          setReceivedConditions={setReceivedConditions}
          selectedDestConditions={selectedDestConditions}
          setSelectedDestConditions={setSelectedDestConditions}
          selectedStatusConditions={selectedStatusConditions}
          setSelectedStatusConditions={setSelectedStatusConditions}
          selectedReceivedConditions={selectedReceivedConditions}
          setSelectedReceivedConditions={setSelectedReceivedConditions}
          handleClose={() => setSearchConditionOpen(false)}
        />
      )}

      <UpdatePopUp
        popUp={
          popUp
            ? popUp
            : state?.afterCreateOrUpdate
            ? state?.afterCreateOrUpdate
            : false
        }
        handleClose={() => {
          setPopUp(false);
          setMsgKind('');
          setTaskKind('');
          history.replace({
            state: {
              ...state,
              afterCreateOrUpdate: false,
            },
          });
        }}
        fromPc={true}
        taskKind={state?.afterCreateOrUpdate ? 'orderStatus' : taskKind}
        messageKind={state?.afterCreateOrUpdate ? 'update' : msgKind}
      />
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        handleOk={handleDelete}
        handleCancel={() => setConfirmMsg('')}
      />
      {(confirmMsg || errMsg) && <Overlay dark zIndex={1} />}
      <ErrorMsgPopUp
        fromPc={true}
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
        }}
      />
    </>
  );
};
