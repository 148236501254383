import { useMutation } from 'react-query';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { AxiosError } from 'axios';
import { ErrorResponse } from '@lib/common/type';

const useDeleteMaterialStockAllocationMutation = (
  id: number,
  handleDeleteSuccess: () => void,
  setErrMsg: (errMsg: string) => void,
  setDeleteId: (deleteId: number | '') => void
) => {
  return useMutation(
    async () => {
      return await axiosInstance().delete(
        `/api/v1/material_stock_allocation_details/${id}`
      );
    },
    {
      onSuccess: () => {
        handleDeleteSuccess();
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
        setDeleteId('');
      },
    }
  );
};

export default useDeleteMaterialStockAllocationMutation;
