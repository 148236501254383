import React, { useState } from 'react';
import * as S from './LoginUserInfo.styles';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as CircleCloseIcon } from '@assets/icons/circle_close.svg';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import DeleteConfirmModal from '@components/modules/common/DeleteConfirmModal/DeleteConfirmModal';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import LoginUserCreateModal from '@components/modules/pc/settings/manufacture_setting/LoginUserCreateModal/LoginUserCreateModal';
import LoginUserEditModal from '@components/modules/pc/settings/manufacture_setting/LoginUserEditModal/LoginUserEditModal';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import SearchMenu from '@components/modules/pc/picking/SearchMenu/SearchMenu';
import UpdatePopUp from '@components/modules/pc/settings/common/UpdatePopUp/UpdatePopUp';
import {
  EmployeesInfo,
  UsersInfo,
} from '@lib/pc/settings/manufacture_setting/type';
import useLoginUsers from '@lib/pc/settings/manufacture_setting/useLoginUsers';
import useDeleteUserMutation from '@lib/pc/settings/manufacture_setting/useDeleteUserMutation';

type Props = {
  employeesInfo: EmployeesInfo;
  refetch: () => void;
};

const LoginUserInfo = ({ employeesInfo }: Props) => {
  // 検索modal表示
  const [searchModalActive, setSearchModalActive] = useState(false);
  // 検索文字列
  const [searchWord, setSearchWord] = useState('');
  const [loginUserCreateModalActive, setLoginUserCreateModalActive] =
    useState(false);
  const [loginUserEditModalActive, setLoginUserEditModalActive] =
    useState(false);
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [selectedEmail, setSelectedEmail] = useState('');
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [msgKind, setMsgKind] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [confirmMsg, setConfirmMsg] = useState('');

  const { usersInfo, refetch } = useLoginUsers();

  const handleDeleteSuccess = () => {
    refetch();
    setConfirmMsg('');
  };

  const deleteUserMutation = useDeleteUserMutation(
    selectedUserId,
    handleDeleteSuccess,
    setErrMsg
  );

  const deleteFunction = () => {
    deleteUserMutation.mutate();
  };

  // 検索配送先グループ名
  const handleSetWordSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(e.target.value);
  };

  const FilterFunctionIcon = () => {
    return (
      <S.ListLeftHeadCell
        onClick={() => setSearchModalActive(!searchModalActive)}
        isBlue={searchWord !== ''}
      >
        <span>
          {searchWord !== '' ? (
            <FilteredIcon />
          ) : (
            <Filter isBlue={searchWord !== ''} />
          )}
        </span>
      </S.ListLeftHeadCell>
    );
  };

  const handleClickCell = (e: UsersInfo) => {
    setSelectedUserId(e.id);
    setSelectedEmail(e.email);
    setLoginUserEditModalActive(true);
  };

  const onClickDelButton = (id: number, email: string) => {
    setConfirmMsg(`「${email}」を削除してもよろしいですか？`);
    setSelectedUserId(id);
  };

  return (
    <S.Wrapper>
      <S.HeadContainer className="head-container">
        <S.CompanyName>
          <span>会社名</span>
          {employeesInfo?.manufactureName}
        </S.CompanyName>

        <S.ControlArea className="control-area">
          {/* 新規登録ボタン */}
          {usersInfo?.isDeveloper && (
            <S.ButtonWrapper>
              <S.ButtonContainer
                onClick={() => {
                  setUpdatePopUp(false);
                  setLoginUserCreateModalActive(true);
                }}
              >
                ＋ 新規登録
              </S.ButtonContainer>
            </S.ButtonWrapper>
          )}
        </S.ControlArea>
      </S.HeadContainer>
      <S.TableContents className="table-contents" id="table-contents">
        {/* 固定箇所 */}
        <S.EmployeeColumn className="employee-column">
          <S.HeaderName
            isBlue={searchWord !== ''}
            onClick={() => {
              setSearchModalActive(!searchModalActive);
            }}
          >
            <span>メールアドレス</span>
            <FilterFunctionIcon />
          </S.HeaderName>
          {usersInfo &&
            usersInfo?.users
              .filter((ui: UsersInfo) => ui?.email?.includes(searchWord))
              .map((ui: UsersInfo, i: number) => {
                return (
                  <S.DelAndEmail key={ui?.email + i}>
                    {/* 削除ボタン */}
                    <S.DeleteButtonTd>
                      <S.CloseButton
                        onClick={() => onClickDelButton(ui?.id, ui?.email)}
                      >
                        <CircleCloseIcon />
                      </S.CloseButton>
                    </S.DeleteButtonTd>

                    <S.ListSidebar
                      className="list-side-bar"
                      key={ui?.email + i}
                      onClick={() => {
                        handleClickCell(ui);
                        setUpdatePopUp(false);
                      }}
                    >
                      <span>{ui?.email}</span>
                    </S.ListSidebar>
                  </S.DelAndEmail>
                );
              })}
        </S.EmployeeColumn>
      </S.TableContents>
      {searchModalActive && (
        <SearchMenu
          title={'メールアドレス'}
          searchParam={searchWord}
          handleClose={() => setSearchModalActive(false)}
          handleChange={handleSetWordSearch}
          left={'0.5rem'}
          top={'268px'}
          deleteSearchParam={setSearchWord}
        />
      )}
      {searchModalActive && (
        <Overlay
          handleClick={() => {
            setSearchModalActive(false);
          }}
        />
      )}
      {loginUserCreateModalActive && (
        <LoginUserCreateModal
          setLoginUserCreateModalActive={setLoginUserCreateModalActive}
          setUpdatePopUp={setUpdatePopUp}
          setMsgKind={setMsgKind}
          refetch={refetch}
        />
      )}
      {loginUserEditModalActive && (
        <LoginUserEditModal
          selectedUserId={selectedUserId}
          selectedEmail={selectedEmail}
          refetch={refetch}
          setLoginUserEditModalActive={setLoginUserEditModalActive}
          setSelectedUserId={setSelectedUserId}
          setUpdatePopUp={setUpdatePopUp}
          setMsgKind={setMsgKind}
        />
      )}
      {confirmMsg && (
        <DeleteConfirmModal
          confirmMsg={confirmMsg}
          onClickCancel={() => setConfirmMsg('')}
          onClickDelete={() => deleteFunction()}
        />
      )}
      <UpdatePopUp
        popUp={updatePopUp}
        taskKind={'messageOnly'}
        messageKind={msgKind}
        handleClose={() => setUpdatePopUp(false)}
      />
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
        }}
        fromPc={true}
        zIndex={9999}
      />
    </S.Wrapper>
  );
};

export default LoginUserInfo;
