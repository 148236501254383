export const MainPath = {
  pc: '/pc',
  mobile: '/mobile',
} as const;

// eslint-disable-next-line
export type MainPath = typeof MainPath[keyof typeof MainPath];

// あらかたpath決め終わったら消す
export const Path = {
  demandForecast: '/demand_forecst',
  stockStatus: '/stock_status',
  productionSchedule: '/production_schedule',
  stacker: '/stacker',
  productDetail: '/product',
  scheduleDetail: '/schedule',
  login: '/login',
  private: '/private',
} as const;

// eslint-disable-next-line
export type Path = typeof Path[keyof typeof Path];

export const PCPath = {
  demandForecast: '/demand_forecst',
  stockStatus: '/stock_status',
  materialAllocation: '/material_allocation',
  materialAllocationStatus: '/material_allocation_status',
  materialInventory: '/material_inventory',
  rowMaterialAllocation: '/row_material_allocation',
  purchaseOrders: '/purchase_orders',
  acceptingOrders: '/accepting_orders',
  stockAllocationAll: '/stock_allocation_all',
  semiProductStockAllocationAll: '/semi_product_stock_allocation_status',
  stockAllocationStatus: '/stock_allocation_status',
  orderSummary: '/order_summary',
  wrappingStatus: '/wrapping_status',
  salesInformation: '/sales_information',
  basisForProductionSchedule: '/production_schedule',
  productProductionResult: '/product_production_result',
  semiProductProductionResult: '/semi_product_production_result',
  productProductionResultPc: '/product_production_result_pc',
  salesStoreMaster: '/settings/sales_store_setting',
  salesStoreMasterCreate: '/settings/sales_store_setting_create',
  salesStoreMasterEdit: '/settings/sales_store_setting_edit',
  semiProductProductionResultPc: '/semi_product_production_result_pc',
  semiProductionSchedule: '/semi_production_schedule',
  shippingStatus: '/shipping_status',
  stacker: '/stacker',
  picking: '/picking',
  pickingDetail: '/picking_detailing',
  pickingByShipping: '/picking_by_shipping',
  pickingByShippingEdit: '/picking_by_shipping_detail',
  productDetail: '/product',
  scheduleDetail: '/schedule',
  login: '/login',
  private: '/private',
  /**
   * 設定
   */
  /** 管理 */
  settingsProductMaster: '/settings/product_master_setting',
  settingsProductMasterCreate: '/settings/product_master_setting_create',
  settingsProductMasterEdit: '/settings/product_master_setting_edit',
  settingsSemiProductMaster: '/settings/semi_product_master_setting',
  settingsSemiProductMasterCreate:
    '/settings/semi_product_master_setting_create',
  settingsSemiProductMasterEdit: '/settings/semi_product_master_setting_edit',
  /** 会社情報管理 */
  settingsManufacture: '/settings/manufacture_setting',
  /** 資材・原材料管理 */
  settingsMaterialMaster: '/settings/material_master_setting',
  settingsMaterialMasterCreate: '/settings/material_master_setting_create',
  settingsMaterialMasterEdit: '/settings/material_master_setting_edit',
  settingsSupplierMaster: '/settings/supplier_setting',
  settingsSupplierMasterCreate: '/settings/supplier_setting_create',
  settingsSupplierMasterEdit: '/settings/supplier_setting_edit',
  /** 受注・出荷管理 */
  settingsCustomerMaster: '/settings/customer_setting',
  settingsCustomerMasterCreate: '/settings/customer_setting_create',
  settingsCustomerMasterEdit: '/settings/customer_setting_edit',
  settingsShippingGroupMaster: '/settings/shipping_group_setting',
  settingsShippingGroupMasterCreate: '/settings/shipping_group_setting_create',
  settingsShippingGroupMasterEdit: '/settings/shipping_group_setting_edit',
  settingsShippingDestinationMaster: '/settings/shipping_destination_setting',
  settingsShippingDestinationMasterCreate:
    '/settings/shipping_destination_create',
  settingsShippingDestinationMasterEdit: '/settings/shipping_destination_edit',
} as const;

// eslint-disable-next-line
export type PCPath = typeof PCPath[keyof typeof PCPath];
