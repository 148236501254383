import styled, { css } from 'styled-components';
import { FixedSizeList } from 'react-window';

export const Wrapper = styled.div`
  max-width: 100vw;
  max-height: 100vh;
  height: calc(100vh - 143px);
  overflow: auto;
  position: relative;
  background: ${(props) => props.theme.palette.beige};
`;

export const Table = styled.table`
  position: relative;
  border-collapse: initial;

  td,
  th {
    height: 52px;
    padding: 0.25em;
    vertical-align: middle;
    border: 1px solid ${(props) => props.theme.palette.yellowGray};

    :nth-child(2) {
      border-left: none;
    }
  }

  tr:nth-child(odd) td:nth-child(odd) {
    background: ${(props) => props.theme.palette.yellowGray};
    background-blend-mode: multiply;
    :first-child {
      background: ${(props) => props.theme.palette.paleYellow} !important;
    }
  }

  tr {
    border-bottom: 1px solid ${(props) => props.theme.palette.yellowGray};
  }

  tr:nth-child(odd) td:nth-child(even) {
    background: ${(props) => props.theme.palette.paleYellow};
    background-blend-mode: multiply;
    :first-child {
      background: #fff !important;
    }
  }

  tr:nth-child(even) td:nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
    background-blend-mode: multiply;
    :first-child {
      background: #fff !important;
    }
  }

  tr:nth-child(even) td:nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }

  thead th:nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
  }

  thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
    z-index: 1;
    background: ${(props) => props.theme.palette.white};
    color: ${(props) => props.theme.palette.baseBlack};
    &.today {
      color: ${(props) => props.theme.palette.white};
      background: ${(props) => props.theme.palette.blue};
      border-color: ${(props) => props.theme.palette.blue};
    }

    :first-child {
      z-index: 1001 !important;
    }

    //シャドウ後でクラス名に変える
    :not(:first-child) {
      box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);
    }
  }

  tbody td {
    &.today {
      position: relative;

      ::before {
        border-right: 1px solid ${(props) => props.theme.palette.blue};
        content: '';
        height: 105%;
        top: 0px;
        right: -1px;
        position: absolute;
      }
      ::after {
        border-left: 1px solid ${(props) => props.theme.palette.blue};
        content: '';
        height: 105%;
        top: 0px;
        left: 0;
        position: absolute;
      }
    }
  }
  tr:last-child {
    td.today {
      border-bottom: 1px solid ${(props) => props.theme.palette.blue};
    }
  }
  thead th:first-child {
    left: 0;
    z-index: 1;
    background: ${(props) => props.theme.palette.white};
    color: ${(props) => props.theme.palette.baseBlack};
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
    background: #fff;
    z-index: 1000;
    box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.2);
  }
  tbody tr th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
    background: #fff;
    z-index: 1000;
    :nth-child(odd) {
      background: ${(props) => props.theme.palette.paleYellow};
    }

    //シャドウ後でクラス名に変える
    box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.2);
  }
`;

export const ListWrapper = styled.div`
  position: relative;
  height: 100vh;
`;

export const NoProductsToShow = styled.div`
  width: 100vw;
  padding: 10rem;
  margin-left: 5%;
  font-weight: 700;
  text-align: center;
  & > div {
    font-weight: 300;
    font-size: 14px;
    padding-top: 1rem;
  }
`;

export const List = styled.div`
  position: relative;
  border-collapse: initial;

  td,
  th {
    height: 52px;
    padding: 0.25em;
    vertical-align: middle;
    border: 1px solid ${(props) => props.theme.palette.yellowGray};

    :nth-child(2) {
      border-left: none;
    }
  }

  tr:nth-child(odd) td:nth-child(odd) {
    background: ${(props) => props.theme.palette.yellowGray};
    background-blend-mode: multiply;
    :first-child {
      background: ${(props) => props.theme.palette.paleYellow} !important;
    }
  }

  tr {
    border-bottom: 1px solid ${(props) => props.theme.palette.yellowGray};
  }

  tr:nth-child(odd) td:nth-child(even) {
    background: ${(props) => props.theme.palette.paleYellow};
    background-blend-mode: multiply;
    :first-child {
      background: #fff !important;
    }
  }

  tr:nth-child(even) td:nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
    background-blend-mode: multiply;
    :first-child {
      background: #fff !important;
    }
  }

  tr:nth-child(even) td:nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }

  thead th:nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
  }

  thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
    z-index: 1;
    background: ${(props) => props.theme.palette.white};
    color: ${(props) => props.theme.palette.baseBlack};
    &.today {
      color: ${(props) => props.theme.palette.white};
      background: ${(props) => props.theme.palette.blue};
      border-color: ${(props) => props.theme.palette.blue};
    }

    :first-child {
      z-index: 1001 !important;
    }

    //シャドウ後でクラス名に変える
    :not(:first-child) {
      box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);
    }
  }

  tbody td {
    &.today {
      position: relative;

      ::before {
        border-right: 1px solid ${(props) => props.theme.palette.blue};
        content: '';
        height: 105%;
        top: 0px;
        right: -1px;
        position: absolute;
      }
      ::after {
        border-left: 1px solid ${(props) => props.theme.palette.blue};
        content: '';
        height: 105%;
        top: 0px;
        left: 0;
        position: absolute;
      }
    }
  }
  tr:last-child {
    td.today {
      border-bottom: 1px solid ${(props) => props.theme.palette.blue};
    }
  }
  thead th:first-child {
    left: 0;
    z-index: 1;
    background: ${(props) => props.theme.palette.white};
    color: ${(props) => props.theme.palette.baseBlack};
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
    background: #fff;
    z-index: 1000;
    box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.2);
  }
  tbody tr th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;

    left: 0;
    border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
    background: #fff;
    z-index: 1000;
    :nth-child(odd) {
      background: ${(props) => props.theme.palette.paleYellow};
    }

    //シャドウ後でクラス名に変える
    box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.2);
  }
`;

export const ListHeader = styled.div`
  display: flex;
  align-items: center;

  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);
`;

const highLightenHeadCell = css`
  color: ${(props) => props.theme.palette.white}!important;
  background: ${(props) => props.theme.palette.blue}!important;
  border-color: ${(props) => props.theme.palette.blue}!important;
`;

const highLightenListLeftCell = css`
  position: relative;
  border-right: 2px solid ${(props) => props.theme.palette.blue};
  border-left: 2px solid ${(props) => props.theme.palette.blue};
`;

const cornerArrow = css`
  ::before,
  ::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
  }
  ::before {
    border-width: 0.4rem;
  }
  ::after {
    border-width: 0.4rem;
    border-right-color: ${(props) => props.theme.palette.blue};
    border-top-color: ${(props) => props.theme.palette.blue};
  }
`;

export const ListHeadCell = styled.div<{
  highLighten: boolean;
  dayOfWeek: string;
}>`
  height: 52px;
  width: calc((100vw - 250px) / 11);
  box-sizing: border-box;
  padding: 0.5rem 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) =>
    props.dayOfWeek === '土' && !props.highLighten
      ? '#1046cf'
      : props.dayOfWeek === '日' && !props.highLighten
      ? '#c74141'
      : ''};

  :nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
  }

  :nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }

  ${({ highLighten }) => (highLighten ? highLightenHeadCell : null)}
`;

export const ListCellContainer = styled.div<{
  todaysEstimated?: boolean;
  estimated?: boolean;
  highLighten: boolean;
  withComment?: boolean;
  invalid?: boolean;
  index: number;
  columnIndex: number;
}>`
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};
  position: relative;
  cursor: pointer;
  height: 52px;
  width: calc((100vw - 250px) / 11);
  box-sizing: border-box;
  padding: 0.5rem 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.yellowGray};
  ${({ withComment }) => (withComment ? cornerArrow : null)};
  ${({ highLighten }) => (highLighten ? highLightenListLeftCell : null)};
  color: ${(props) =>
    props.todaysEstimated
      ? props.theme.palette.blue
      : props.estimated
      ? props.theme.palette.dialog
      : props.theme.palette.black};
  background: ${(props) => {
    const isOddRow = props.index % 2 === 1;
    const isOddColumn = props.columnIndex % 2 === 0;

    if (isOddRow && isOddColumn) return '#F5F2E6';
    if (isOddRow && !isOddColumn) return '#FFFEF5';
    if (!isOddRow && isOddColumn) return '#F8F7F4';
    if (!isOddRow && !isOddColumn) return '#FFFFFF';
    return '#FFFFFF';
  }};
`;

export const ListBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: relative;
  height: max-content;
  -webkit-transition: transform 390ms ease-in-out;
  -moz-transition: transform 390ms ease-in-out;
  -ms-transition: transform 390ms ease-in-out;
  -o-transition: transform 390ms ease-in-out;
  transition: transform 390ms ease-in-out;
  &.move-to-left {
    -webkit-transform: translateX(-390px);
    -moz-transform: translateX(-390px);
    -ms-transform: translateX(-390px);
    -o-transform: translateX(-390px);
    transform: translateX(-390px);
  }

  & > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.paleYellow};
  }

  & > div:nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }
  & > div:not(:first-child):nth-child(even) > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
  }
  & > div:not(:first-child):nth-child(odd) > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.yellowGray};
  }
`;

export const ListUnitRow = styled.div`
  display: flex;
  background: #fff;

  :nth-child(odd) {
    background: ${(props) => props.theme.palette.beige};
  }

  :nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }
`;

export const ListRow = styled.div`
  display: flex;
  background: #fff;
`;

export const SelectStoreroomText = styled.div`
  padding: 1rem;
`;

export const CircularIconWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  background-color: ${(props) => props.theme.palette.beige} !important;
`;

export const ListDateRow = styled.div`
  display: flex;
`;

export const ListSidebar = styled.div`
  max-width: 250px;
  border-right: 1px solid ${(props) => props.theme.palette.yellowGray};
  height: max-content;
`;

export const ListLeftHeadCell = styled.div<{
  invalid?: boolean;
  isBlue?: boolean;
  index?: number;
}>`
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};
  cursor: pointer;
  height: 52px;
  min-width: 250px;
  border-bottom: 1px solid ${(props) => props.theme.palette.yellowGray};
  box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.05);
  position: -webkit-sticky; /* for Safari */
  -webkit-transform: translateZ(0);
  /* display: flex; */
  justify-content: space-between;
  padding: 6px 0.75rem;
  gap: 0.25rem;

  display: flex;
  flex-direction: column;

  cursor: pointer;

  > span {
    font-size: 0.75rem;
    &.filter-product {
      width: 90%;
      padding-left: 30px;
      height: 32px;
      line-height: 32px;
      position: absolute;
      background: ${(props) => props.theme.palette.white};
      color: ${(props) =>
        props.isBlue ? props.theme.palette.blue : '#9ca3af'};
      border-radius: 0.5rem;
      cursor: pointer;

      > svg {
        position: absolute;
        top: 12%;
        left: 5%;
      }

      > div {
        padding-left: 15px;
        font-size: 16px;
      }
    }
  }

  //COMMENT: OKであれば後で書き方変えたほうがいい
  :first-child {
    > div > input {
      cursor: pointer;
      height: 32px;
      padding: 0.5rem 40px 0.5rem;
    }
  }

  background: ${
    (props) =>
      props.index !== undefined && props.index % 2 === 0
        ? '#FFFFFF' // 偶数行
        : '#FFFEF5' // 奇数行
  };
`;

export const ListLeftHeadNameWrapper = styled.div`
  color: ${(props) => props.theme.palette.blue};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
`;

export const ListLeftHeadName = styled.div`
  color: ${(props) => props.theme.palette.blue};
  max-width: 145px;
  text-overflow: ellipsis; /* 末尾に3点リーダーを表示 */
  overflow: hidden; /* はみ出した部分を非表示 */
  white-space: nowrap; /* 改行を防止 */
`;

export const ListLeftHeadMeta = styled.div`
  color: rgba(51, 51, 51, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
  > span {
    font-weight: 700;
  }
`;

export const ProductKindDiv = styled.div<{
  productKind: number;
}>`
  font-size: 0.75rem;
  padding: 2px 6px;
  border-radius: 2px;
  border: 1px solid #2ba9d1;
  background: ${(props) => (props.productKind === 1 ? '#2BA9D1' : '#fff')};
  color: ${(props) => (props.productKind === 1 ? '#fff' : '#2BA9D1')};
`;

export const StickyWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.2);
`;

export const StyledDiv = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const StyledFixedSizeList = styled(FixedSizeList)`
  position: absolute !important;
  height: calc(100vh - 191px) !important;
  width: 100vw !important;
`;

export const CenteredCircularProgress = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 218px);
`;
