import { useState, useMemo, useEffect, useCallback } from 'react';
import * as S from './StockAllocationAll.styles';
import { Heading } from '@components/elements/typographies/Heading';
import RightSlideSidebar from '@components/modules/pc/stock_allocation_all/RightSlideSidebar/RightSlideSidebar';
import TableWithFixedSidebar from '@components/modules/pc/stock_allocation_all/TableWithFixedSidebar/TableWithFixedSidebar';
import { WithHeader } from '@components/templates/WithHeader';
import ArrowRight from '@assets/icons/arrow_right_24px';
import { ReactComponent as ArrowLeft } from '@assets/icons/arrow_left_24px.svg';
import useStorerooms from '@lib/pc/common/hooks/useStorerooms';
import useStockAllocationsALl from '@lib/pc/stock_allocation_all/useStockAllocationsAll';
import moment from 'moment';
import StockAllocationTransferSelect from '@components/modules/pc/stock_allocation_status/StockAllocationTransferSelect/StockAllocationTransferSelect';
import StockAllocationTypeSelect from '@components/modules/pc/stock_allocation_all/StockAllocationTypeSelect/StockAllocationTypeSelect';
import ProductDetailModal from '@components/modules/pc/stock_allocation_all/ProductDetailModal/ProductDetailModal';
import {
  PRODUCT_KIND,
  AllocationType,
  stockAllocationsAll,
  selectedItem,
  productStockAllocation,
  productStockAllocationDetail,
  MATERIAL_PRODUCT_KIND,
} from '@lib/common/type';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { Button } from '@components/elements/buttons/Button';
import { getTodayForReference } from '@lib/common/functions';
import { PCPath } from '@config/path';

export const StockAllocationAllRoot = () => {
  const initializeSelectedItem = {
    id: 0,
    name: '',
    type: '完' as '完' | '半',
    date: '',
    productStockAllocations: [] as productStockAllocation[],
    data: [] as productStockAllocationDetail[],
    labelOfAmount: '',
    storeroomsIds: [],
  };

  const today = moment();
  const [date, setDate] = useState(moment());
  const [initialize] = useState<selectedItem>(initializeSelectedItem);
  const [selectedItem, setSelectedItem] = useState<selectedItem>(initialize);
  useState<selectedItem>(initialize);
  const [rightSlideSidebarActive, setRightSlideSidebarActive] = useState(false);
  const [productDetailModalActive, setProductDetailModalActive] =
    useState(false);
  const [searchMenuActive, setSearchMenuActive] = useState(false);
  const [selectedCell, setSelectedCell] = useState('');
  const [selectedStoreroomId, setSelectedStoreroomId] = useState(0);
  const [selectedAllocationTypeId, setSelectedAllocationTypeId] = useState(-1);
  const [selectedProductId, setSelectedProductId] = useState(0);
  const [selectIndex, setSelectIndex] = useState<number>(
    new Date().getDate() - 1
  );

  const allocationTypeOptions: AllocationType[] = [
    { id: -1, name: '全ての払出タイプ' },
    { id: 0, name: '店舗販売' },
    { id: 1, name: '廃棄' },
    { id: 2, name: '在庫移動' },
    { id: 3, name: '消費' },
  ];
  const { stockAllocationsAll, refetch } = useStockAllocationsALl(
    moment(date).toDate(),
    selectedStoreroomId,
    selectedAllocationTypeId
  );
  const { data: storerooms } = useStorerooms(
    MATERIAL_PRODUCT_KIND.PRODUCT_AND_SEMI_PRODUCT
  );

  const filtered = useMemo(() => {
    if (!stockAllocationsAll) return;
    if (selectedStoreroomId === 0) {
      return stockAllocationsAll;
    } else {
      return stockAllocationsAll.filter((p: stockAllocationsAll) =>
        p.storeroomsIds?.includes(selectedStoreroomId)
      );
    }
  }, [selectedStoreroomId, stockAllocationsAll]);

  const handleOpenProductDetail = useCallback((item: selectedItem) => {
    setSelectedItem(item);
    setProductDetailModalActive(!productDetailModalActive);
    setSelectedProductId(item.id);
    setSelectedItem(item);
  }, []);

  const handleSelectedProduct = (item: selectedItem, indexId: string) => {
    setSelectedItem(item);
    setRightSlideSidebarActive(!rightSlideSidebarActive);
    setSelectedCell(indexId);
  };

  const getMonth = (num: number) => {
    setDate(moment(date).add(num, 'months').startOf('month'));
  };

  const handleClick = () => {
    getTodayForReference(
      date.format('YYYY-MM'),
      today.format('YYYY-MM'),
      setDate,
      setSelectIndex,
      refetch
    );
  };

  const handleCloseProductDetail = useCallback(() => {
    setProductDetailModalActive(false);
    setSelectedItem(initialize);
  }, []);

  useEffect(() => {
    localStorage.setItem('last_view_page', `${PCPath.stockAllocationAll}`);
  }, []);

  useEffect(() => {
    refetch();
  }, [selectedStoreroomId, selectedAllocationTypeId]);

  return (
    <WithHeader>
      <S.Wrapper>
        <S.HeadContainer>
          <S.PageTitle>
            <S.PageTitleText>
              <Heading text="払出状況" fs="32" fw="lg" />
              <Heading text="を確認" fs="24" fw="lg" />
            </S.PageTitleText>
          </S.PageTitle>

          <S.DateWrapper>
            <S.SelectWrapper marginRight="0.8rem">
              <StockAllocationTransferSelect
                data={storerooms}
                value={selectedStoreroomId}
                height={'42px'}
                handleChangeTransfer={setSelectedStoreroomId}
                productKind={PRODUCT_KIND.PRODUCT_AND_SEMI_PRODUCT}
              />
            </S.SelectWrapper>
            <S.SelectWrapper marginRight={'12px'}>
              <StockAllocationTypeSelect
                value={selectedAllocationTypeId}
                height={'42px'}
                width={'230px'}
                setSelectedAllocationTypeId={setSelectedAllocationTypeId}
                allocationTypeOptions={allocationTypeOptions}
              />
            </S.SelectWrapper>
            <Button
              width="90px"
              margin={'0 8px 0 0'}
              outlined={true}
              borderWidth={1}
              onClick={handleClick}
            >
              今日
            </Button>
            <S.ArrowLeft selected={false} onClick={() => getMonth(-1)}>
              <ArrowLeft />
              <span className="circle"></span>
            </S.ArrowLeft>
            <S.Date>
              {moment(date).format('YYYY')}
              <span>年</span>
              {moment(date).format('MM')}
              <span>月</span>
            </S.Date>
            <S.ArrowRight selected={false} onClick={() => getMonth(1)}>
              <ArrowRight isActive={true} />
            </S.ArrowRight>
          </S.DateWrapper>
        </S.HeadContainer>

        <TableWithFixedSidebar
          selectedDate={date}
          data={filtered}
          selected={false}
          handleSelectedProduct={handleSelectedProduct}
          selectedCell={selectedCell}
          rightSlideSidebarActive={rightSlideSidebarActive}
          handleOpenProductDetail={handleOpenProductDetail}
          selectIndex={selectIndex}
          setSelectIndex={setSelectIndex}
        />
        <RightSlideSidebar
          stockAllocationsAllInProducts={stockAllocationsAll}
          rightSlideSidebarActive={rightSlideSidebarActive}
          setRightSlideSidebarActive={setRightSlideSidebarActive}
          selectedProduct={selectedItem}
        />
      </S.Wrapper>
      <ProductDetailModal
        selectedItem={selectedItem}
        productDetailModalActive={productDetailModalActive}
        selectedDate={date}
        handleClose={handleCloseProductDetail}
        selectedAllocationTypeId={selectedAllocationTypeId}
        allocationTypeOptions={allocationTypeOptions}
        setSelectedAllocationTypeId={setSelectedAllocationTypeId}
        response={filtered}
        selectedProductId={selectedProductId}
        selectedStoreroomId={selectedStoreroomId}
        setSelectedStoreroomId={setSelectedStoreroomId}
        storerooms={storerooms}
      />
      {searchMenuActive && (
        <Overlay handleClick={() => setSearchMenuActive(false)} />
      )}
    </WithHeader>
  );
};
