import { useEffect } from 'react';
import { WithHeader } from '@components/templates/WithHeader';
import * as S from './ShippingGroupMasterRoot.styles';
import ShippingGroupHeaderControl from '@components/modules/pc/settings/shipping_group_setting/ShippingGroupHeaderControl/ShippingGroupHeaderControl';
import TableWithFilter from '@components/modules/pc/settings/shipping_group_setting/TableWithFilter/TableWithFilter';
import useShippingGroups from '@lib/pc/picking_by_shipping/useShippingGroups';
import { PCPath } from '@config/path';

export const ShippingGroupMasterRoot = () => {
  const { shippingGroups: shippingGroups } = useShippingGroups();

  useEffect(() => {
    localStorage.setItem(
      'last_view_page',
      `${PCPath.settingsShippingGroupMaster}`
    );
  }, []);

  return (
    <WithHeader>
      {/* 出荷先グループ管理 */}
      <S.Wrapper>
        <ShippingGroupHeaderControl />
      </S.Wrapper>
      {shippingGroups && <TableWithFilter shippingGroups={shippingGroups} />}
    </WithHeader>
  );
};
