import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import * as S from './TableWithFilter.styles';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import SearchMenu from '@components/modules/pc/picking/SearchMenu/SearchMenu';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { ShippingGroupState } from '@lib/pc/settings/shipping_group_setting/type';
import { SalesStore } from '@lib/pc/settings/sales_store_master/type';
import { seeFullComment } from '@lib/pc/common/functions';

type Props = {
  salesStores: SalesStore[];
};

const TableWithFilter = ({ salesStores }: Props) => {
  const { state } = useLocation<ShippingGroupState>();
  const history = useHistory();

  const [stateScrollPosition, setScrollPosition] = useState<number | null>(
    null
  );

  // 検索modal表示
  const [searchModalActive, setSearchModalActive] = useState(false);
  // 検索文字列
  const [searchWord, setSearchWord] = useState('');

  // 検索配送先グループ名
  const handleSetWordSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(e.target.value);
  };

  // スクロール位置保存
  document?.getElementById('table-contents')?.addEventListener('scroll', () => {
    const currentScrollPosition =
      document?.getElementById('table-contents')?.scrollTop;
    if (currentScrollPosition) {
      setScrollPosition(currentScrollPosition);
    }
  });

  useEffect(() => {
    if (state === undefined) {
      document?.getElementById('table-contents')?.scrollTo(0, 0);
      setSearchWord('');
    } else {
      const { stateScrollPosition, stateSearchWord } = state;
      stateSearchWord && setSearchWord(stateSearchWord);
      document
        ?.getElementById('table-contents')
        ?.scrollTo(0, stateScrollPosition);
    }
  }, [state]);

  const FilterFunctionIcon = () => {
    return (
      <S.ListLeftHeadCell
        onClick={() => setSearchModalActive(!searchModalActive)}
        isBlue={searchWord !== ''}
      >
        <span>
          {searchWord !== '' ? (
            <FilteredIcon />
          ) : (
            <Filter isBlue={searchWord !== ''} />
          )}
        </span>
      </S.ListLeftHeadCell>
    );
  };

  const handleClickCell = (c: SalesStore) => {
    history.push(`/pc/settings/sales_store_setting_edit/${c.id}`, {
      ...state,
      stateScrollPosition: stateScrollPosition,
      stateSearchWord: searchWord,
      id: c.id,
      name: c.name,
      code: c.code,
    });
  };

  return (
    <>
      <S.Table>
        <thead>
          <S.Tr>
            <S.TCell className="sales-store-code">販売店コード</S.TCell>
            <S.HeaderName
              isBlue={searchWord !== ''}
              onClick={() => {
                setSearchModalActive(!searchModalActive);
              }}
            >
              販売店名
              <FilterFunctionIcon />
            </S.HeaderName>
            <S.TCell className="destination-name">出荷先名</S.TCell>
            <S.TCell className="customer-name">得意先名</S.TCell>
          </S.Tr>
        </thead>
      </S.Table>
      <S.TableContents className="table-contents" id="table-contents">
        <S.StyledTable>
          <tbody>
            {salesStores &&
              salesStores
                .filter(
                  (as: SalesStore) =>
                    as.name.includes(searchWord) || as.code.includes(searchWord)
                )
                .map((c, i) => {
                  return (
                    <React.Fragment key={i + c.customersName}>
                      <S.TRow
                        key={i}
                        onClick={() => {
                          if (!c.customersName) {
                            handleClickCell(c);
                          }
                        }}
                      >
                        <S.TCell
                          className="sales-store-code"
                          onClick={() => handleClickCell(c)}
                        >
                          {c.code}
                        </S.TCell>
                        <S.TCell
                          className="sales-store-name"
                          onClick={() => handleClickCell(c)}
                        >
                          {c.name}
                        </S.TCell>
                        <S.TCell
                          className="destination-name"
                          onClick={() => handleClickCell(c)}
                        >
                          {c.shippingDestinationName}
                        </S.TCell>
                        <S.TCell
                          className="customer-name"
                          onClick={() => {
                            seeFullComment(i);
                          }}
                        >
                          <div className="cell-content">{c.customersName}</div>
                        </S.TCell>
                      </S.TRow>
                      <S.CommentBalloon
                        onClick={() => {
                          seeFullComment(i);
                        }}
                      >
                        <td
                          key={i + Math.random()}
                          id={`comment-full-${i}`}
                          className={'cell-content__balloon'}
                        >
                          <span className="comment-full__title">
                            得意先名：
                          </span>
                          {c.customersName}
                        </td>
                      </S.CommentBalloon>
                    </React.Fragment>
                  );
                })}
          </tbody>
        </S.StyledTable>
      </S.TableContents>
      {searchModalActive && (
        <SearchMenu
          title={'販売店名/コード'}
          searchParam={searchWord}
          handleClose={() => setSearchModalActive(false)}
          handleChange={handleSetWordSearch}
          left={'9rem'}
          top={'184px'}
          deleteSearchParam={setSearchWord}
        />
      )}
      {searchModalActive && (
        <Overlay
          handleClick={() => {
            setSearchModalActive(false);
          }}
        />
      )}
    </>
  );
};

export default TableWithFilter;
