import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { Todo } from '@lib/common/type';

const useMenuFunctions = () => {
  const { data, refetch } = useQuery('menu_functions', async () => {
    const data = await axiosInstance().get(`/api/v1/user_and_roles`);
    return data;
  });
  const menuFunctions = humps.camelizeKeys(data?.data?.functions) as Todo[];
  return { menuFunctions, refetch };
};

export default useMenuFunctions;
