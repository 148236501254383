import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { ErrorResponse } from '@lib/common/type';
import { axiosInstance } from '@lib/mobile/common/api/axiosConfig';

// StockAmountDetailテーブルのレコード削除;
const useDeleteStockMutation = (
  actualSemiProductOrProductStockId: number | undefined,
  stockKind: string,
  amountDetailId: number | null,
  selectedDateString: string,
  handleDeleteSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async () => {
      return await axiosInstance().delete(
        `/api/v1/stocks/${actualSemiProductOrProductStockId}?stock_kind=${stockKind}&amount_detail_id=${amountDetailId}&date=${selectedDateString}`
      );
    },
    {
      onSuccess: () => {
        handleDeleteSuccess();
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
      },
    }
  );
};

export default useDeleteStockMutation;
