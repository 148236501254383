import { useMutation } from 'react-query';
import humps from 'humps';
import moment from 'moment';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo } from '@lib/common/type';

type InputParams = {
  packagingId: number;
  productId: number;
  stackingStartedTime?: string;
  stackingBestBeforeDate?: Date;
  stackingCompletedQuantity?: number;
  stackingComment?: string;
  employeeIds?: number[];
  stackingResult: { id: number };
};

// stackingデータ更新
const useUpdateStackerMutation = (
  factoryId: string | number,
  lineId: string | number,
  date: Date,
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: InputParams) => {
      return await axiosInstance().patch(
        `/api/v1/stackings/${
          params.stackingResult.id
        }?factory_id=${factoryId}&factory_line_id=${lineId}&date=${moment(
          date
        ).format('YYYY-MM-DD')}`,
        humps.decamelizeKeys(params)
      );
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: (error: Todo) => {
        const message = error.response?.data.message || 'エラーが発生しました';
        setErrMsg(message);
      },
    }
  );
};

export default useUpdateStackerMutation;
