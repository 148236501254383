import styled from 'styled-components';

export const PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2.5rem;
`;

export const PageTitleText = styled.div`
  display: flex;
  align-items: baseline;
  padding-bottom: 4px;
`;

export const Select = styled.select`
  margin-left: 32px;
  font-size: 18px;
  cursor: pointer;
`;

export const ProductKind = styled.span`
  padding: 5px 10px 7px;
  width: 80px;
  height: 32px;
  background: #fff;
  color: #2ba9d1;
  font-size: 19px;
  margin-right: 16px;
  border: 1px solid #2ba9d1;
  border-radius: 2px;
  vertical-align: super;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
`;
