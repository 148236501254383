import { useHistory, useLocation } from 'react-router';
import { useCallback, useEffect, useRef, useState } from 'react';
import humps from 'humps';
import { CircularProgress } from '@material-ui/core';
import * as S from './TableWithFIlter.styles';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import SearchMenu from '@components/modules/pc/picking/SearchMenu/SearchMenu';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { ShippingDestinationMeta, Todo } from '@lib/common/type';
import { ShippingDestinationState } from '@lib/pc/settings/shipping_destination_setting/type';

const TableWithFilter = () => {
  const { state } = useLocation<ShippingDestinationState>();
  const history = useHistory();

  const [items, setItems] = useState<Todo[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(
    state?.stateCurrentPage ? state?.stateCurrentPage : 0
  );
  // 検索modal表示
  const [searchModalActive, setSearchModalActive] = useState(false);
  // 検索文字列
  const [searchWord, setSearchWord] = useState('');
  // バックエンドからくるtotalPages
  const [totalPages, setTotalPages] = useState(
    state?.stateTotalPages ? state?.stateTotalPages : 0
  );

  const perPage = 100; // 最大100件ずつ取得する

  const [debouncedValue, setDebouncedValue] = useState('');

  // 取得済み出荷先データを退避
  const [storedShippingDestinationData, setStoredShippingDestinationData] =
    useState<Todo[]>(
      state?.storedShippingDestinationData
        ? state?.storedShippingDestinationData
        : []
    );

  const observerTarget = useRef(null);

  // 検索modal画面の検索文字バツボタン押下時
  const handleClickSearchTextCloseButton = () => {
    setSearchWord('');
    history.push(`/pc/settings/shipping_destination_setting`, {
      ...state,
      stateScrollPosition: stateScrollPosition,
      // stateScrollPosition: state?.stateScrollPosition,
      stateSearchWord: '',
    });
    // 取得済みデータをもとに戻す
    setItems(
      storedShippingDestinationData.length === 0
        ? state.storedShippingDestinationData
        : storedShippingDestinationData
    );
    setCurrentPage(currentPage ? currentPage : state?.stateCurrentPage);
  };

  const useDebounce = (value: string, delay: number) => {
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  };

  // searchWordを200ミリ秒でデバウンス
  const debouncedSearchWord = useDebounce(searchWord, 200);

  const fetchData = useCallback(
    async (currentPage: number, searchWord: string) => {
      if (loading || !hasMore) return;

      setLoading(true);
      try {
        const response = await axiosInstance().get(
          `/api/v1/settings/shipping_destinations`,
          {
            params: {
              page: currentPage + 1,
              search: searchWord ? searchWord : state?.stateSearchWord,
              per_page: perPage,
            },
          }
        );
        const newItems = humps.camelizeKeys(
          response?.data?.shipping_destinations
        );
        const meta = humps.camelizeKeys(
          response.data.meta
        ) as unknown as ShippingDestinationMeta;
        if (searchWord === '') {
          setTotalPages(meta.totalPages);
        }

        if (!searchWord && newItems?.length === 0) {
          setHasMore(false);
        } else if (searchWord || state?.stateSearchWord) {
          setItems(newItems);
        } else {
          setHasMore(true);
          setItems((prevItems) => [...prevItems, ...newItems]);
          setStoredShippingDestinationData((prevItems) => [
            ...prevItems,
            ...newItems,
          ]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
        setHasMore(true);
      }
    },
    [currentPage, searchWord, debouncedValue]
  );

  const [stateScrollPosition, setScrollPosition] = useState(0);

  // スクロール位置保存
  document?.getElementById('table-contents')?.addEventListener('scroll', () => {
    const currentScrollPosition =
      document?.getElementById('table-contents')?.scrollTop;
    if (currentScrollPosition || currentScrollPosition === 0) {
      setScrollPosition(currentScrollPosition);
    }
  });

  // 編集画面から戻った時
  useEffect(() => {
    if (currentPage <= state?.stateCurrentPage) {
      // 取得済みデータをもとに戻す
      setItems(
        storedShippingDestinationData.length === 0
          ? state.storedShippingDestinationData
          : storedShippingDestinationData
      );
    }
  }, [state?.stateCurrentPage]);

  useEffect(() => {
    if (
      (state === undefined && storedShippingDestinationData.length > 0) ||
      currentPage > state?.stateCurrentPage ||
      state?.stateCurrentPage === undefined
    ) {
      // 読み込み済みページよりも現在ページが大きくなったらバックエンド呼び出し
      fetchData(currentPage, searchWord);
    }
  }, [currentPage]);

  useEffect(() => {
    if (searchWord) {
      fetchData(currentPage, searchWord);
    }
  }, [searchWord]);

  useEffect(() => {
    if (debouncedSearchWord) {
      fetchData(currentPage, debouncedSearchWord);
    }
  }, [debouncedSearchWord]);

  // 検索出荷先名
  const handleSetWordSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(e.target.value);
  };

  useEffect(() => {
    if (state === undefined) {
      setSearchWord('');
    } else {
      setSearchWord(state.stateSearchWord);
    }
  }, [state]);

  // スクロール位置復元
  useEffect(() => {
    if (state === undefined) {
      document?.getElementById('table-contents')?.scrollTo(0, 0);
      setSearchWord('');
    } else if (state && state.stateScrollPosition !== null) {
      const tryScroll = (attempts = 0) => {
        const element = document.getElementById('table-contents');
        if (element) {
          element.scrollTo(0, state.stateScrollPosition);
          if (element.scrollTop !== state.stateScrollPosition && attempts < 5) {
            setTimeout(() => tryScroll(attempts + 1), 100);
          }
        }
      };
      setTimeout(tryScroll, 100);
    }
  }, [state]);

  // 現在ページの設定
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (
            hasMore &&
            totalPages > currentPage &&
            state === undefined &&
            searchWord === ''
          ) {
            // 検索中ではないときのみページをカウントアップする
            setCurrentPage((p) => p + 1);
          } else if (
            hasMore &&
            totalPages > currentPage &&
            state &&
            state.stateSearchWord === '' &&
            searchWord === ''
          ) {
            // 検索中ではないときのみページをカウントアップする
            setCurrentPage((p) => p + 1);
          }
        }
      },
      { threshold: 1.0 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }
    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [hasMore, observerTarget, totalPages, searchWord]);

  const FilterFunctionIcon = () => {
    return (
      <S.ListLeftHeadCell
        onClick={() => setSearchModalActive(!searchModalActive)}
        isBlue={searchWord !== ''}
      >
        <span>
          {searchWord !== '' ? (
            <FilteredIcon />
          ) : !loading ? (
            <Filter isBlue={searchWord !== ''} />
          ) : (
            <></>
          )}
        </span>
      </S.ListLeftHeadCell>
    );
  };

  return (
    <>
      <S.Table>
        <thead>
          <S.Tr>
            <S.TCell className="shipping-code header">出荷先コード</S.TCell>
            <S.HeaderName
              isBlue={searchWord !== ''}
              onClick={() => {
                setSearchModalActive(!searchModalActive);
              }}
            >
              出荷先名
              <FilterFunctionIcon />
            </S.HeaderName>
            <S.TCell className="shipping-time header">
              出荷リードタイム（日数）
            </S.TCell>
          </S.Tr>
        </thead>
      </S.Table>
      <S.TableContents id="table-contents">
        <S.StyledTable>
          <tbody>
            {items &&
              items.map((s, i) => {
                return (
                  <S.TRow
                    key={i}
                    onClick={() => {
                      history.push(
                        `/pc/settings/shipping_destination_edit/${s.value}`,
                        {
                          ...state,
                          id: s.value,
                          name: s.label,
                          code: s.code,
                          transportLeadTime: s.transportLeadTime,
                          stateCurrentPage: currentPage,
                          stateTotalPages: totalPages,
                          stateScrollPosition: stateScrollPosition,
                          stateSearchWord: searchWord,
                          storedShippingDestinationData:
                            storedShippingDestinationData,
                        }
                      );
                    }}
                  >
                    <S.TCell className="shipping-code">{s.code}</S.TCell>
                    <S.TCell className="shipping-name">{s.label}</S.TCell>
                    <S.TCell className="shipping-time">
                      {s.transportLeadTime}
                    </S.TCell>
                  </S.TRow>
                );
              })}
          </tbody>
        </S.StyledTable>
        <div ref={observerTarget}>
          {hasMore && loading && !searchWord && (
            <S.CircularIconWrapperOuter>
              <S.CircularIconWrapper>
                <CircularProgress style={{ color: '#64b2f9' }} />
              </S.CircularIconWrapper>
            </S.CircularIconWrapperOuter>
          )}
        </div>
      </S.TableContents>
      {searchModalActive && (
        <SearchMenu
          title={'出荷先名/コード'}
          searchParam={searchWord}
          handleClose={() => setSearchModalActive(false)}
          handleChange={handleSetWordSearch}
          left={'14rem'}
          top={'184px'}
          deleteSearchParam={setSearchWord}
          handleClickDeleteIcon={() => handleClickSearchTextCloseButton()}
        />
      )}
      {searchModalActive && (
        <Overlay
          handleClick={() => {
            setSearchModalActive(false);
          }}
        />
      )}
    </>
  );
};

export default TableWithFilter;
