import { Heading } from '@components/elements/typographies/Heading';
import { Link, useLocation } from 'react-router-dom';
import * as S from './CustomerHeaderControl.styles';
import { ShippingGroupState } from '@lib/pc/settings/shipping_group_setting/type';

const CustomerHeaderControl = () => {
  const { state } = useLocation<ShippingGroupState>();

  return (
    <S.Wrapper>
      <S.HeadContainer className="head-container">
        <div>
          <Heading tag="span" text="得意先管理" fs="32" fw="lg" />
        </div>

        {/* 新規登録ボタン */}
        <S.ButtonWrapper>
          <S.ButtonContainer>
            <Link
              to={{
                pathname: `/pc/settings/customer_setting_create`,
                state: {
                  ...state,
                  stateSearchWord: '',
                  stateSelectedTags: [],
                  editData: null,
                },
              }}
            >
              ＋ 新規登録
            </Link>
          </S.ButtonContainer>
        </S.ButtonWrapper>
      </S.HeadContainer>
    </S.Wrapper>
  );
};

export default CustomerHeaderControl;
