import styled from 'styled-components';

export const FlexBetweenCenter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2.5rem;
`;

export const FlexBaseline = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Select = styled.select`
  margin-left: 32px;
  font-size: 18px;
  cursor: pointer;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
`;
