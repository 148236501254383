import { useEffect } from 'react';
import { WithHeader } from '@components/templates/WithHeader';
import * as S from './ShippingDestinationMasterRoot.styles';
import ShippingDestinationHeaderControl from '@components/modules/pc/settings/shipping_destination_setting/ShippingDestinationHeaderControl/ShippingDestinationHeaderControl';
import TableWithFilter from '@components/modules/pc/settings/shipping_destination_setting/TableWithFilter/TableWithFilter';
import { PCPath } from '@config/path';

export const ShippingDestinationMasterRoot = () => {
  useEffect(() => {
    localStorage.setItem(
      'last_view_page',
      `${PCPath.settingsShippingDestinationMaster}`
    );
  }, []);

  return (
    <WithHeader>
      {/* 出荷先管理 */}
      <S.Wrapper>
        <ShippingDestinationHeaderControl />
      </S.Wrapper>
      <TableWithFilter />
    </WithHeader>
  );
};
