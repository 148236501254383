import { useEffect } from 'react';
import * as S from './CustomerMasterRoot.styles';
import { WithHeader } from '@components/templates/WithHeader';
import CustomerHeaderControl from '@components/modules/pc/settings/customer_setting/CustomerHeaderControl/CustomerHeaderControl';
import TableWithFilter from '@components/modules/pc/settings/customer_setting/TableWithFilter/TableWithFilter';
import useCustomers from '@lib/pc/settings/customer_setting/useCustomers';
import { PCPath } from '@config/path';

export const CustomerMasterRoot = () => {
  const { customers: customers } = useCustomers();

  useEffect(() => {
    localStorage.setItem('last_view_page', `${PCPath.settingsCustomerMaster}`);
  }, []);

  return (
    <WithHeader>
      {/* 得意先管理 */}
      <S.Wrapper>
        <CustomerHeaderControl />
      </S.Wrapper>
      {customers && <TableWithFilter customers={customers} />}
    </WithHeader>
  );
};
