import { useEffect, useState } from 'react';
import { factoryLineColors } from '@lib/pc/common/type';
import {
  Case,
  SemiProductsProcess,
  Todo,
  ScannedProductInfo,
} from '@lib/common/type';
import * as S from './SemiProductProductionResultContents.styles';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import useCreateSemiProductProductionResultMutation from '@lib/pc/semi_product_production_result/useCreateSemiProductProductionResultMutation';
import useUpdateSemiProductProductionResultMutation from '@lib/pc/semi_product_production_result/useUpdateSemiProductProductionResultMutation';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SemiProductProductionResultMainTable from '@components/modules/pc/semi_product_production_result/SemiProductProductionResultMainTable/SemiProductProductionResultMainTable';
import SemiProductProductionResultEditModal from '@components/modules/pc/semi_product_production_result/SemiProductProductionResultEditModal/SemiProductProductionResultEditModal';
import useDeleteSemiProductProductionResultMutation from '@lib/pc/semi_product_production_result/useDeleteSemiProductProductionResultMutation';
import scanSound from '@assets/sounds/sound.mp3';
import BarcodeReader from '@components/modules/pc/BarcodeScanner/BarcodeReader';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';

type Props = {
  semiProductionResults: Todo;
  selectedFactoryId: number;
  setSelectedFactoryId: (selectedFactoryId: number) => void;
  selectedFactoryData: Todo;
  setSelectedFactoryData: (selectedFactoryData: Todo) => void;
  selectedLineId: number;
  setSelectedLineId: (selectedLineId: Todo) => void;
  selectedLineIndex: number;
  setSelectedLine: (selectedLineIndex: number) => void;
  date: Date;
  semiProducts: Todo;
  semiProductsProcesses: SemiProductsProcess[];
  cases: Case[];
  setPopUp: (popUp: boolean) => void;
  editProductName: string;
  setEditProductName: (editProductName: string) => void;
  refetch: () => void;
  setMessageKind: (messageKind: string) => void;
  casesPerCart: string;
  setCasesPerCart: (casesPerCart: string) => void;
  registeredSemiProductIds: number[];
  setRegisteredSemiProductIds: (registeredSemiProductIds: number[]) => void;
};

export type InputParams = {
  time: string;
};
const defaultEditData = {
  detailId: null,
  amount: null,
  cart: null,
  case: null,
  caseId: null,
  checkTime: null,
  comment: '',
  defectiveSemiProductAmount: null,
  employeeIds: [],
  expirationDate: null,
  piece: null,
  plannedAmount: null,
  plannedCase: null,
  plannedPiece: null,
  semiProductId: null,
  semiProductProductionResultId: null,
  storeroomId: null,
};
const defaultScanProduct = {
  processId: null,
  productId: null,
  productName: '',
};

const SemiProductProductionResultContents = ({
  semiProductionResults,
  selectedFactoryId,
  setSelectedFactoryId,
  selectedFactoryData,
  setSelectedFactoryData,
  selectedLineId,
  setSelectedLineId,
  selectedLineIndex,
  setSelectedLine,
  date,
  semiProducts,
  semiProductsProcesses,
  cases,
  setPopUp,
  setEditProductName,
  refetch,
  setMessageKind,
  casesPerCart,
  setCasesPerCart,
  registeredSemiProductIds,
  setRegisteredSemiProductIds,
}: Props) => {
  const [editData, setEditData] = useState<Todo>(null);
  const [semiProductId, setSemiProductId] = useState(0);
  const [useDuplicateFunc, setUseDuplicateFunc] = useState(false);
  const [scanning, setScanning] = useState(true);
  const [results, setResults] = useState<Todo>([]);
  const [inputText] = useState('');
  const [audio] = useState(new Audio(scanSound));
  const [errMsg, setErrMsg] = useState('');
  const [foundScannedProduct, setFoundScannedProduct] = useState(false);
  const [useScanning, setUseScanning] = useState(false);
  const [scannedSemiProduct, setScannedSemiProduct] =
    useState<ScannedProductInfo>(defaultScanProduct);
  const valuesOfFactoryLineColors = Object.values(factoryLineColors);

  useEffect(() => {
    setLatestFactoryData();
  }, [semiProductionResults?.semiProductionResults]);

  useEffect(() => {
    setSelectedFactoryData(selectedFactoryData);
  }, [selectedFactoryData]);

  useEffect(() => {
    const timer = setInterval(() => {
      const camera = localStorage.getItem('SemiProductProductionResultCamera');
      if (camera) {
        refetch();
      }
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (results.length !== 0) {
      const getData = semiProducts.semiProducts.filter((pro: Todo) => {
        const isMatchingProduct =
          pro.semiProductCode === results[0].codeResult.code &&
          (pro.factoryLineId === selectedLineId ||
            pro.factoryLineIds.includes(selectedLineId));
        return isMatchingProduct;
      });
      const extractedData = getData.map((semiProducts: Todo) => {
        const matchingProductProcess = semiProductsProcesses?.find(
          (semiProductProcess: SemiProductsProcess) =>
            semiProductProcess.semiProductId === semiProducts.id
        );
        return {
          id: matchingProductProcess?.id,
          productName: matchingProductProcess?.name,
          productId: matchingProductProcess?.semiProductId,
        };
      });
      const firstMatchingProduct = extractedData[0] || {};
      const scannedProductInfo = {
        processId: firstMatchingProduct.id || '',
        productId: firstMatchingProduct.productId || '',
        productName: firstMatchingProduct.productName || '',
      };
      setScannedSemiProduct(scannedProductInfo);

      if (!!getData && getData.length > 0) {
        setEditData({
          ...defaultEditData,
          time: null,
          lineName: selectedFactoryData.lines[selectedLineIndex].name,
          lineIndex: selectedLineIndex,
        });
        setPopUp(false);
        setUseDuplicateFunc(false);
        setFoundScannedProduct(true);
        setUseScanning(true);
      } else {
        setErrMsg('該当の商品が見つかりませんでした。');
        setFoundScannedProduct(false);
        setEditData(false);
      }
    }
  }, [results]);

  const handleDelete = () => {
    deleteSemiProductProductionResultMutation.mutate();
    setEditData(null);
  };

  const setLatestFactoryData: Todo = () => {
    if (selectedFactoryId === undefined) {
      setSelectedFactoryId(
        semiProductionResults?.semiProductionResults[0]?.factoryId
      );
      setSelectedFactoryData(semiProductionResults?.semiProductionResults[0]);
    } else {
      const selectedData = semiProductionResults?.semiProductionResults.filter(
        (pr: Todo) => pr.factoryId == selectedFactoryId
      );
      if (selectedData?.[0]) {
        setSelectedFactoryData(selectedData[0]);
      }
    }
  };

  // 最新データの取得
  const getLatestWrappingData = () => {
    refetch();
    setLatestFactoryData();
  };

  const handleDeleteSuccess = () => {
    setPopUp(true);
    setMessageKind('delete');
    getLatestWrappingData();
    setScanning(true);
    setResults([]);
    setScannedSemiProduct(defaultScanProduct);
  };

  const handleCreateSuccess = () => {
    setPopUp(true);
    setMessageKind('create');
    getLatestWrappingData();
    setEditData(null);
  };

  const handleDuplicateSuccess = () => {
    setPopUp(true);
    setMessageKind('duplicate');
    getLatestWrappingData();
    setEditData(null);
    setUseDuplicateFunc(false);
  };

  const handleUpdateSuccess = () => {
    setPopUp(true);
    setMessageKind('update');
    getLatestWrappingData();
    setEditData(null);
  };

  const handleClose = () => {
    setScanning(true);
    setScannedSemiProduct(defaultScanProduct);
    setEditData(null);
    setUseDuplicateFunc(false);
    setResults([]);
  };

  // ここで、newEditData.order(順)が空だったらcreateする。
  const handleEditSubmit = (newEditData: Todo) => {
    if (useDuplicateFunc) {
      duplicateSemiProductProductionResultMutation.mutate(newEditData);
      setScanning(true);
      setResults([]);
      setScannedSemiProduct(defaultScanProduct);
    } else if (newEditData.detailId === null) {
      createSemiProductProductionResultMutation.mutate(newEditData);
      setScanning(true);
      setResults([]);
      setScannedSemiProduct(defaultScanProduct);
    } else {
      updateSemiProductProductionResultMutation.mutate(newEditData);
      setScanning(true);
      setResults([]);
      setScannedSemiProduct(defaultScanProduct);
    }
  };

  // 半製品生産実績データ作成処理
  const createSemiProductProductionResultMutation =
    useCreateSemiProductProductionResultMutation(
      date,
      selectedLineId,
      semiProductId,
      handleCreateSuccess,
      setErrMsg
    );

  // 半製品生産実績データ複製処理
  const duplicateSemiProductProductionResultMutation =
    useCreateSemiProductProductionResultMutation(
      date,
      selectedLineId,
      semiProductId,
      handleDuplicateSuccess,
      setErrMsg
    );

  // 半製品生産実績データ更新処理
  const updateSemiProductProductionResultMutation =
    useUpdateSemiProductProductionResultMutation(
      editData?.detailId,
      date,
      selectedLineId,
      handleUpdateSuccess,
      setErrMsg
    );

  // 半製品生産実績データ削除
  const deleteSemiProductProductionResultMutation =
    useDeleteSemiProductProductionResultMutation(
      editData?.detailId,
      handleDeleteSuccess,
      setErrMsg
    );

  const onclickLineTab = (i: number, id: number) => {
    setPopUp(false);
    setSelectedLine(i);
    setSelectedLineId(id);
    const selectedLine = [selectedFactoryId, id];
    localStorage.setItem(
      'semi_product_production_selected_line',
      selectedLine?.join(',')
    );
  };

  return (
    <div>
      <S.TabWrapper>
        {selectedFactoryData &&
          selectedFactoryData.lines.map((d: Todo, i: number) => (
            <div key={d.name + d.id + i}>
              <S.TabArea>
                <S.Tab
                  key={d.name + d.id}
                  tabCount={selectedFactoryData.lines.length}
                  active={i == selectedLineIndex}
                  onClick={() => {
                    onclickLineTab(i, d.id);
                  }}
                  bgColor={valuesOfFactoryLineColors[i % 10]}
                >
                  {d.name}
                </S.Tab>
              </S.TabArea>
            </div>
          ))}
      </S.TabWrapper>
      <DndProvider backend={HTML5Backend}>
        <SemiProductProductionResultMainTable
          data={selectedFactoryData}
          setEditData={setEditData}
          selectedLineIndex={selectedLineIndex}
          setPopUp={setPopUp}
          setUseDuplicateFunc={setUseDuplicateFunc}
          setSemiProductId={setSemiProductId}
          setScanning={setScanning}
          setRegisteredSemiProductIds={setRegisteredSemiProductIds}
        />
      </DndProvider>
      {editData && (
        <SemiProductProductionResultEditModal
          scannedSemiProduct={scannedSemiProduct}
          data={editData}
          date={date}
          semiProducts={semiProducts}
          semiProductsProcesses={semiProductsProcesses}
          cases={cases}
          handleClose={() => handleClose()}
          handleDelete={handleDelete}
          handleSubmit={handleEditSubmit}
          setEditProductName={setEditProductName}
          casesPerCart={casesPerCart}
          setCasesPerCart={setCasesPerCart}
          selectedLineId={selectedLineId}
          setSemiProductId={setSemiProductId}
          setUseDuplicateFunc={setUseDuplicateFunc}
          useDuplicateFunc={useDuplicateFunc}
          useScanning={useScanning}
          registeredSemiProductIds={registeredSemiProductIds}
        />
      )}
      {editData && <Overlay dark />}
      {scanning && (
        <BarcodeReader
          onDetected={(result: Todo) => {
            setScanning(false);
            audio.play();
            const newResult = {
              codeResult: { code: result.codeResult.code },
              inputText,
            };
            setResults([...results, newResult]);
          }}
        />
      )}
      {!foundScannedProduct && (
        <ErrorMsgPopUp
          fromPc={true}
          errMsg={errMsg}
          zIndex={9998}
          handleClose={() => {
            setResults([]);
            setScanning(true);
            setErrMsg('');
          }}
        />
      )}
    </div>
  );
};

export default SemiProductProductionResultContents;
